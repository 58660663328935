//Me permite ver los detalles (panel) de la factura. 
//Por ejemplo: fecha con hora de elaboracion, cliente, correo, condicion, anulada.....
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//Variables
let valueDC=null //Aqui hago una copia de los valores del context

const MostrarPanelDeLaFactura=(props)=>{
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const mostrarDatos=()=>{    
  document.getElementById("txtClave").value=props.row.ClaveAcceso
  document.getElementById("txtDocumentoNumero").value=props.row.Factura  
  document.getElementById("txtFecha").value=props.row.FechaEmision
  document.getElementById("txtCliente").value=props.row.RazonSocial
  document.getElementById("txtCorreo").value=props.row.EmailCliente
  document.getElementById("txtCondicion").value=(props.row.Condicion=="CREDITO") ? "CREDITO (" + props.row.Plazo   + " dias)": "CONTADO"
  document.getElementById("txtCancelada").value=(props.row.Cancelado=="0") ? "No" : "Si"

  //cuando no viene el campo Condicion, entonces lo oculto
  if (!props.row.Condicion)
    document.getElementById("rowCondicion").style.display= 'none' //pero Los botones visibles se reacomodan
  //cuando no viene el campo Cancelado, entonces lo oculto
  if (!props.row.Cancelado)
    document.getElementById("rowCancelada").style.display= 'none' //pero Los botones visibles se reacomodan

  //al ser CONTADO, oculto el campo txtCancelada
  if (props.row.Condicion && props.row.Condicion=="CONTADO")
    document.getElementById("rowCancelada").style.display= 'none' //pero Los botones visibles se reacomodan
  //para la resupuesta del SRI
  if (props.row.AutorizacionSri=="1")
    document.getElementById("txtRespuestaSri").value="AUTORIZADO" + "\n" + "el: " + props.row.FechaAutor
  else
    document.getElementById("txtRespuestaSri").value=props.row.MensajeErrorAutorizacion
  
  //********************* pare el bloque 2 */
  document.getElementById("txtUsuCrea").value=props.row.OpCrea
  document.getElementById("txtVendedor").value=props.row.NombreVen
  document.getElementById("txtAnulada").value=(props.row.Anulado=="1") ? "Si" : "No"
  document.getElementById("txtAnuladaPor").value=props.row.OpAnula
  //al NO estar anulada, entonces oculto informacion innecesaria
  if (props.row.Anulado=="0"){
    document.getElementById("rowAnulada").style.display= 'none' //pero Los botones visibles se reacomodan
    document.getElementById("rowAnuladaPor").style.display= 'none' //pero Los botones visibles se reacomodan
  }
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  mostrarDatos()

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    // por ahora nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{       
return (               
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Titulo + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"PANEL DE FACTURA"}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ props.cerrarModal()  }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 

  {/* Empiezo a mostrar la informacion RELEVANTE, color purple */}
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink', }}>
    <Row style={{marginBottom:"4%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="12">
        <Input disabled name="txtClave" id="txtClave" style={{padding:'0',textAlign:'center',width:'100%',height:'30px',background:'silver',}}/>            
      </Col>    
    </Row>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Numero</Label>
      </Col>
      <Col xs="6">
        <Input disabled name="txtDocumentoNumero" id="txtDocumentoNumero" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
      </Col>    
    </Row>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Emisión</Label>
      </Col>
      <Col xs="6">
        <Input disabled name="txtFecha" id="txtFecha" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>
      </Col>    
    </Row>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Cliente</Label>
      </Col>
      <Col xs="8">
        <Input disabled name="txtCliente" id="txtCliente" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
      </Col>    
    </Row>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Correo</Label>
      </Col>
      <Col xs="8">
      <Input disabled name="txtCorreo" id="txtCorreo" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
      </Col>    
    </Row>  
    <Row id="rowCondicion" name="rowCondicion" style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Condición</Label>
      </Col>
      <Col xs="6">
        <Input disabled name="txtCondicion" id="txtCondicion" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black'}}/>            
      </Col>    
    </Row>  
    <Row id="rowCancelada" name="rowCancelada" style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Cancelada</Label>
      </Col>
      <Col xs="3">
        <Input disabled name="txtCancelada" id="txtCancelada" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
      </Col>    
    </Row>  
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Respuesta SRI</Label>
      </Col>
      <Col xs="8">
        <textarea disabled name="txtRespuestaSri" id="txtRespuestaSri" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'60px',background:'white',color:'black'}}/>            
      </Col>    
    </Row>  
    </div> {/* (divBloque1) */}

    {/* OTRO BLOQUECITO, para mostrar informacion USUCREA, USUANULA,VENDEDOR,... */}
    <div id="divBloque2" name="divBloque2" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'gray', color:'pink', }}>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Creada por</Label>
      </Col>
      <Col xs="5">
        <Input disabled name="txtUsuCrea" id="txtUsuCrea" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'dimgray' }}/>            
      </Col>    
    </Row>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Vendedor</Label>
      </Col>
      <Col xs="5">
        <Input disabled name="txtVendedor" id="txtVendedor" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'dimgray'  }}/>
      </Col>    
    </Row>
    <Row id="rowAnulada" name="rowAnulada"  style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Anulada</Label>
      </Col>
      <Col xs="2">
      <Input disabled name="txtAnulada" id="txtAnulada" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'dimgray'  }}/>            
      </Col>    
    </Row>  
    <Row id="rowAnuladaPor" name="rowAnuladaPor" style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col xs="4" style={{textAlign:'left'}}>
        <Label>Anulada por</Label>
      </Col>
      <Col xs="5">
        <Input disabled name="txtAnuladaPor" id="txtAnuladaPor" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'dimgray' }}/>            
      </Col>    
    </Row>      
    </div> {/* (divBloque2) */}

  </div> // (divMaster)
) //del return  
}


const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()


} //del  MostrarPanelDeLaFactura

export default MostrarPanelDeLaFactura