import React from 'react'
//import buscarPhpPath,{mostrarSwalEspera,apagarSwal,gestionarCatch,sleepPepe} from './lib_basica'

export const AppContext=React.createContext()

export class AppContextProvider extends React.Component{ 

//es obligatorio poner el value, caso contrario da UNDEFINED
render () {  
  return (
    <AppContext.Provider value={{
      estatus:this.props.estatus, //para saber si hubo exito al conectarse y se haya conseguido exitosamente al usuario (y su clave sea correcta)
      usuario:this.props.usuario, //datos del usario logeado. (pero sin la clave para que no se vea. ni siquera encriptada)
      usuarios:this.props.usuarios, //todos los usuarios, pero pocas columnas
      estable:this.props.estable,
      establecimientos:this.props.establecimientos,
      almacen:this.props.almacen,
      almacenes:this.props.almacenes,
      bancos:this.props.bancos,
      vendedores:this.props.vendedores,
      categorias:this.props.categorias,
      correoElectronico:this.props.correoElectronico,
      marcas:this.props.marcas,
      licencia:this.props.licencia,      
      sistema:this.props.sistema,
      iva:this.props.iva,
      unidades:this.props.unidades,
      //refrescarContextPrincipal:this.props.refrescarContextPrincipal
      }}>
      {this.props.children}
    </AppContext.Provider>
  )}
}

export const AppContextConsumer=AppContext.Consumer
