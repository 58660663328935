//alert(JSON.stringify(pepeJson,null,2))
//***************************** importante *****************/
//Es una pantallita que me muestra solo el encabezado de un PLAN de pagos
//Util para crear un plan, o para modificar el encabezado.
//También puede revisar si un plan está repetido (se busca por nombre del plan)

import React, {useState,useEffect} from 'react'
import {Form,FormGroup,Button,Input,Label} from 'reactstrap'
import { Row, Col} from 'reactstrap'
import buscarPhpPath,{ejecutarFetchGenericoConSwal,convertirUnTextBoxEnEntero,convertirUnTextBoxEnFloat,sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,verificarSiTextoEsPurosDigitos, aceptarSoloEnteros } from './lib_basica'
//variables normales
let numeroDeOcurrencias=null // Solo podría haber 0 ó 1 registro encontrado. el null sería cuando hubo algun error

const verificarSiExisteUnPlanPorDescripcion=async(miIDplan,numIntentos,milisegundosSleep)=>{
  //Busca en la BDD, si un determinado plan ya existe. (para evitar que el usuario pierda tiempo metiendo un plan repetido)
  //miIDplan, contiene el codigo ROJO que deseo excluir de la busqueda
  //cuando sea nuevo plan, la variable miDDplan es CERO. cuando es modificar entonces vale el codigo rojo del plan que estoy modificando (para que lo excluya de la busqueda. Igual que MsSqlServer buscar excepto el mismo)

  numeroDeOcurrencias=null  
  let data=new FormData()
  data.append('miSol','planPago_verificarSiExisteUnPlanPorDescripcion')
  data.append('_IDplan',miIDplan) //CodigoRojo a excluir de la busqueda
  data.append('_NombrePlan',document.getElementById("txtNombrePlan").value.trim() ) //Nombre del plan a buscar

  //miRecibido, podria recibir NULL,0 o un numero entero que indica el numero de ocurrencias (lo ideal sería cero)
  let miRecibido=await ejecutarFetchGenericoConSwal(numIntentos,milisegundosSleep,data)
  if (miRecibido != null){
    numeroDeOcurrencias=miRecibido    
  }    
}

export const validarPantallaPlanDePagos_Cabecera=async()=>{
  //Obviamente valído la pantalla, Pero NO valído si el plan está repetido (eso lo debe hacer el usuario manualmente)

  let miTxt=null //de uso común para ir guardando el contenido de los campos
  // ===== Nombre del plan =====
  miTxt=document.getElementById("txtNombrePlan").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","El nombre del Plan, no puede estar vacío")
    return false
  }
  // ===== Combo Período =====
  if (document.getElementById("comboPeriodo").selectedIndex<0){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un período")
    return false
  }
  
  return true
}

const alimentarComboPeriodos=(props)=>{ 
  //Cuando está activado StrictMode, entonces llamado dos veces al DIDmount. (eso pasa en tiempo de diseno solamente. Pero porsia)
  //Por lo tanto hay que limpiar el Combo

  let miSelectComboP= document.getElementById("comboPeriodo")

  //Paso 1=> Limpiar el combo Buaaaa
  for (let i = miSelectComboP.options.length; i >= 0; i--) {
    miSelectComboP.remove(i);
  }  

  //paso 2=> Alimentar el combo (dependiendo del llamador, los periodos que llegan pueden ser Solo Activos, Todos los periodos
  props.jsonPeriodos.forEach( (item) => {   
    let miOption=document.createElement("option")
    miOption.value= item.IDperiodo
    miOption.text=item.NombrePeriodo
    miSelectComboP.appendChild(miOption)        
  }
)}
      
const PantallaPlanDePagos_Cabecera=(props)=>{   
  //En las props, viene La lista de los periodos (pudiendo ser solamente los activos o todos)/ Depende del llamador    
  //tambie viene el numero de re-intentos y los milisengundo de espera para volver intenta PHP (aqui no recibo el context)

  const [txtNombrePlan,set_txtNombrePlan]=useState('')
  const [checkGrava,set_checkGrava]=useState(null)

  //=========== simulo el componentDidMount
  //el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
  useEffect(()=>{  
    //Esta pantalla me sirve tanto para NUEVO plan cabecera. O para modificar una [cabecera OJO con eso]

    alimentarComboPeriodos(props)
    document.getElementById("comboPeriodo").selectedIndex=-1
    if (props.opcionRegistro_imc=='i'){
      set_checkGrava(props.gravaIva) //la variable gravaIva viene segun las preferencias en ;los parametros del SISTEMA
    }
    if (props.opcionRegistro_imc=='m'){
      //al ser modificar la cabecera, recibo en las props el registro clon completo de la tabela (más fácil no puede ser)
      set_txtNombrePlan(props.rowRegistroClon.NombrePlan)
      set_checkGrava(props.rowRegistroClon.GravaIvaPlan=="1" ? true : false)       
      //busco en el combo, el periodo al que pertenece
      document.getElementById("comboPeriodo").value=props.rowRegistroClon.IDperiodo
    }    
  },[]
)

const ParteCabecera=()=>{   
  return (  
    <div id="divParteCabecera" name="divParteCabecera">
      {/* Nombre del Plan */}
      <Row style={{marginTop:'3%',marginBottom:'1%',marginLeft:'0',marginRight:'0',padding:'0'}}>
        <Col xs="4">
          <Label for="txtNombrePlan">Nombre del Plan</Label>
        </Col>
        <Col xs="8">        
          <Input maxLength="30" name="txtNombrePlan" id="txtNombrePlan" autoComplete='off' value={txtNombrePlan} 
                onChange={(e)=>set_txtNombrePlan(e.target.value)}               
          />  
        </Col>
      </Row>   
  
      {/* Boton/Link para verificar si el plan está repetido */}
      <Row style={{marginTop:'1%',marginBottom:'1%',marginLeft:'0',marginRight:'0',padding:'0'}}>
        <Col xs="12" style={{textAlign:'center'}}>    
          <a className="btn btn-link" style={{color:"blue",}}
            onClick={async()=>{    
              let miNombrePlan=document.getElementById("txtNombrePlan").value.trim() 
              if (miNombrePlan.length==0){
                await mostrarSwalBotonAceptar("error","ATENCION","El nombre del Plan, no puede estar vacío")
                return false
              }
              //Rumbo ideal (me tiene que devolver 0,1 ó NULL.). El props.IDplan indica el codigo rojo que SE DEBE excluir de la busqueda
              //en numeroDeOcurrencias, podría venir NULL o el numero de courrencias
              await verificarSiExisteUnPlanPorDescripcion( (props.opcionRegistro_imc=='i' ? 0 : props.rowRegistroClon.IDplan),props.numIntentos,props.milisegundosSleep)   
              if (numeroDeOcurrencias==null) return
              if (numeroDeOcurrencias==0)
                await mostrarSwalBotonAceptar("info","TODO BIEN","El plan no está repetido")              
              if (numeroDeOcurrencias==1)
                await mostrarSwalBotonAceptar("error","ATENCION","El nombre del Plan está repetido")              
          }}
          >Revisar si el plan está repetido</a>             
        </Col>
      </Row>         
  
      {/* Grava iva */}
      <Row style={{margin:'0',}}>
        <Col xs="12" style={{textAlign:'center'}}>
          <Label for="checkGrava">
            <Input id="checkGrava" name="checkGrava" type="checkbox" checked={checkGrava}
              onChange={(e)=>set_checkGrava(e.target.checked)}            
            /> Grava Iva ?
          </Label>
        </Col>
      </Row>
     {/* Periodo */}
     <Row style={{margin:'0',}}>
      <Col xs="12" style={{textAlign:'center'}}>
        <FormGroup style={{display:'flex',marginTop:'2%',marginLeft:'2%', }}>
          <Label for="comboPeriodo" style={{width:'20%'}}>Período</Label>
          <Input type="select" name="comboPeriodo" id="comboPeriodo" style={{width:'70%',marginRight:'1%',}}/>
        </FormGroup>
      </Col>
      </Row>
    </div> //divParteCabecera
  )}    

const PantallaDevolver=()=>{
  return(    
    <div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
      {ParteCabecera()}
    </div> // divMaster
  ) //Return
}

return PantallaDevolver()
} //del const PantallaPlanDePagos_Cabecera

export default PantallaPlanDePagos_Cabecera
