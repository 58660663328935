//alert(JSON.stringify(jsonNegra,null,2)) 
// **** Formulario para pedir el numero de cuotas, monto $, Fecha de primer pago, texto de la descripcion *******
// Hace la petición a PHP para crear la serie de pagos del plan correspondiente
// Si todo sale bien, recibo desde PHP un JSON con la serie creada + las cuotas viejas del plan
// Esto es con la idea de que el usuario no tenga que recargar los datos cuando regrese al llamador
// al haber un error en PHP/Mysql entonces recibo aquí un array vacio [] (o un null). Nunca un numero negativo ya que no puedo hacer eso
// Al haber un error en este form. No se sale, se queda aquí mismo para que el usuario no pierda los datos de la pantalla
// Cuando de mi API viene todo bien, entonces éste form se cierra automáticamente

import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import {ejecutarFetchGenericoConSwal,aceptarSoloEnteros2023,aceptarDecimales2023,convierteObjetoFechaDeJSenTexto } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio

//Variables globales de este archivo
let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let serieGeneradaGlobal=null //Aqui guardo un JSON con la serie generada por mi API + las cuotas viejas del plan. Y ésto es lo que devuelvo al llamador

const validarPantallaSerie=async()=>{
  //Obviamente valído la pantalla,

  let miTxt=null //de uso común para ir guardando el contenido de los campos
  // ===== Numero de cuotas =====
  miTxt=document.getElementById("txtNumeroCuotas").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","El número de cuotas, no puede estar vacío")
    return false
  }
  let miEntero=parseInt(document.getElementById("txtNumeroCuotas").value)
  if (miEntero<=0){
    mostrarSwalBotonAceptar("error","ATENCION","El número de cuotas, debe ser positivo")
    return false
  }
  // ===== Monto =====
  miTxt=document.getElementById("txtMontoCuota").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","El monto de la cuota, no puede estar vacío")
    return false
  }
  let miFloat=parseFloat(document.getElementById("txtMontoCuota").value)
  if (miFloat<=0){
    mostrarSwalBotonAceptar("error","ATENCION","El monto de la cuota, debe ser positivo")
    return false
  }  
  // ===== Fecha =====
  miTxt=document.getElementById("txtFechaPrimeraCuota").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","La fecha del primer pago, no puede estar vacío")
    return false
  }  
  // ===== descripcion repetitiva =====
  miTxt=document.getElementById("txtDescripcionCuota").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","la descripción repetitiva, no puede estar vacía")
    return false
  } 
  return true
}

const generarSerie=async(codPlan,txtFechaPrimeraCuota)=>{  
  //Genera la serie y recibe la serie generada+las cuotas viejas de la mismo plan
  //recibo la fecha del estado. y luego la convierto en 3 partes ya que es un problema lo de la fecha

  let data=new FormData( document.getElementById("miFormularioSerie") )
  data.append('miSol','planPago_crearSerieDeCuotasEnPlan')
  data.append('codPlan',codPlan)
  data.append('dia',txtFechaPrimeraCuota.getDate())
  data.append('mes',txtFechaPrimeraCuota.getMonth()+1) //ojo: siempre así
  data.append('ano',txtFechaPrimeraCuota.getFullYear())
 
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  serieGeneradaGlobal=miRecibido
}

const GenerarSerieDePagos=(props)=>{    
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  const [tabActivoState,set_tabActivoState]=useState('1') // Solo hay buna pestana de todos modos
  const [txtNumeroCuotas,set_txtNumeroCuotas]=useState('')  
  const [txtMontoCuota,set_txtMontoCuota]=useState('') 
  const [txtFechaPrimeraCuota,set_txtFechaPrimeraCuota]=useState(null) //arranca sin fecha para obligarlo a que la ponga
  const [txtDescripcionCuota,set_txtDescripcionCuota]=useState('')  

//Solo hay una pestana
//La descripon de la cuota + el nombre del mes => Maximo 30 letras 
//en conclusion. la descripcion maximo 20 letras

const TabSerie=()=>{   
  return (
    <div id="divTabSerie" name="divTabSerie">
      {/* para Numero de cuotas */}
      {/* aqui hago una fila de dos columnas, para el Label y para el text box */}
      <Row style={{margin:'0'}}>
        <Col>
          <div style={{display:'flex',width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
            <Label for="txtNumeroCuotas" style={{width:'45%'}}>Número de Cuotas</Label>
            <Input maxLength="3" name="txtNumeroCuotas" id="txtNumeroCuotas" autoComplete='off' style={{width:'15%'}} value={txtNumeroCuotas} 
              onChange={(e)=>set_txtNumeroCuotas(e.target.value)}               
              onKeyDown={(e)=>aceptarSoloEnteros2023(e)} 
            />
          </div>   
        </Col>
      </Row>
      {/* para Monto */}
      <Row style={{margin:'0'}}> 
        <Col>
          <div style={{display:'flex',width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
            <Label for="txtMontoCuota" style={{width:'30%'}}>Monto $</Label>
            <Input name="txtMontoCuota" id="txtMontoCuota" autoComplete='off' style={{width:'25%'}} value={txtMontoCuota} 
              onChange={(e)=>set_txtMontoCuota(e.target.value)}
              onKeyDown={(e)=>aceptarDecimales2023(e)} 
            /> 
          </div>   
        </Col>
      </Row>
      {/* para fecha  */}
      <Row style={{margin:'0'}}> 
        <Col>
          <div style={{display:'flex',width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>           
              <Label for="txtFechaPrimeraCuota" style={{marginRight:'2%'}}>Fecha del primer pago</Label>
              <DatePicker className="anchoDatePickerGenerico" id="txtFechaPrimeraCuota" name="txtFechaPrimeraCuota" locale='es' dateFormat="dd/MM/yyyy"
                selected={ txtFechaPrimeraCuota }          
                onChange= {(datePar)=>set_txtFechaPrimeraCuota(datePar) }
              /> 
          </div>
        </Col>
      </Row>
      {/* descripción sugerida Maximo: 20 */}
      <Row style={{margin:'0'}}> 
        <Col> 
          <div style={{display:'flex',width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
            <Label for="txtDescripcionCuota" style={{width:'40%'}}>Descripción repetitiva</Label>
            <Input maxLength="20" name="txtDescripcionCuota" id="txtDescripcionCuota" autoComplete='off' style={{width:'45%',}} value={txtDescripcionCuota} 
              onChange={(e)=>set_txtDescripcionCuota(e.target.value)}               
            />
          </div>                   
        </Col>
      </Row>
    </div>
  )}
      
//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  set_txtDescripcionCuota(valueDC.sistema.textoSugeridoParaDescripcionRepetitivaEnCuota)  
 
  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    //por ahora vacío
  }
  },[]
)

const ComponentePrincipalBody=()=>{
return(    
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
  <Form id="miFormularioSerie">
    {/**** barra de titulo  */}
      <div id="divBarraTitulo" name="divBarraTitulo">
        <Row style={{ margin: '0' }}>
          <Col xs="10">
            <Label style={{ marginTop: '5px', fontWeight: 'bold', color: "coral" }}>{"Crear serie para el Plan: " + props.NombrePlan + " Cod: " + props.IDplan}</Label>
          </Col>
          <Col xs="2" style={{ textAlign: 'right', padding: '0' }}>
            <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
              onClick={() => {
                props.ocultarModal_Serie('close',null)
              } }>
              <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
            </Button>
          </Col>
        </Row>
      </div> {/* divBarraTitulo */}

    {/* aqui creo un tab de 1 pestanas */}   
    <ModalBody>
    <Nav tabs >
      <NavItem >
        <NavLink style={{ backgroundColor:'hotpink'}}
          active= {tabActivoState == '1'}
          onClick={() => { 
            if(tabActivoState=='1') return 
            set_tabActivoState('1')
            }}>
          SERIE DE CUOTAS
        </NavLink>
      </NavItem>
    </Nav>

    <TabContent activeTab={tabActivoState} style={{ backgroundColor:'pink', borderStyle:'solid', borderColor:'silver',borderWidth:'medium'  }}> 
      <TabPane tabId="1">        
        {TabSerie()}
      </TabPane>
    </TabContent>
    </ModalBody>

    {/* botones de GENERAR SERIE */}
      <div id="divMenuInf" name="divMenuInf" style={{backgroundColor:'silver',paddingTop:'2%', paddingBottom:"2%"}}>
        <Row style={{margin:'0',}}>
          <Col xs="12" style={{margin:'0',padding:'0',textAlign:'center'}}>
            <Button id="btnGenerar" name="btnGenerar" color="success" solid="true" style={{width:"90%", marginRight:'3%',color:'white'}} 
              onClick={async ()=>{
                if (await validarPantallaSerie()==false) return
                await generarSerie(props.IDplan,txtFechaPrimeraCuota) //recibo un JSON con la serie generada + las cuotas viejas del plan en cuestión
                //debo revisar que el JSON tenga datos
                if (serieGeneradaGlobal==null) return
                if (serieGeneradaGlobal.length==0) return
                //rumbo ideal. Ya recibí la serie y me salgo
                props.ocultarModal_Serie('save',serieGeneradaGlobal)                                              
              }}>GENERAR SERIE
            </Button>
          </Col>
        </Row>
      </div> {/* divMenuInf */}

  </Form> {/* miFormulario */}
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()
} //del const GenerarSerieDePagos

export default GenerarSerieDePagos