//import logo from './logo.svg';
//import './App.css';
//segun react 18.2 (funciona un poco diferente el arranque)
import LoginBonanza from './LoginBonanza'

const AppBonanza=()=> {
  return (
    <div>       
      <LoginBonanza/>   
    </div>
  );
}

export default AppBonanza
