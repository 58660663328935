//Formulario BASE/REFERENCIA para guiarme en otros (Mejora Noviembre2023). (ya no usamos clase, se ha ce pura funcion) y Nuevo Fetch reutilizable (ya no uno en cada archivo)
//Aqui funcion perfecto el NULL en los registros full y también el desmontar el componente
//alert(JSON.stringify(jsonNegra,null,2))
//Este form (al filtrar.buscar) funciona bien en todos lados. Diseno, produccion window, produccion ios
import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input, Form} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, { sleepPepe,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal } from './lib_basica'
import {ejecutarFetchGenericoConSwal} from './lib_basica'
import {mostrarTresOpcionesConSwal,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
import IncluirPlanDePagos_Cabecera from './IncluirPlanDePagos_Cabecera'
import ModificarPlanDePagos_Cabecera from './ModificarPlanDePagos_Cabecera'
import ModificarPlanDePagos_Detalle from './ModificarPlanDePagos_Detalle'
import PracticarModal from './PracticarModal'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLightbulb,faPencilAlt,faGlasses,faRegistered,faRunning,faCamera,faDoorOpen,faBroom,faBookReader,faBookOpen, faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed, faSearchDollar } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let jsonPeriodos=null //Aqui guardo una lista de los periodos (al crear NUEVO plan, necesito enviarle al FORMULARIO los periodos activos). Al editar un plan, le envio los periodos inactivos
let opcionRegistro_imc='' //para saber si deseo incluir nuevo Plan, modificar o consultar
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)let jsonPeriodos=null //Necesito los periodos para llenar un COMBO, al momento de crear un Plan o Modificar su cabecera. Cuando queda null, significa que realmente hubo errore de conexion o error mas fisico en BDD
let dataApi=null //Aqui recibo el campo DATA que me devuelve mi API

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'LISTA DE PLANES'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      //paddingLeft: '50px', //estaba '50px', no me funciona, override the cell padding for head cells
      //paddingRight: '8px', //estaba '8px'
      background: 'hotpink', //ok hotpink
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    }, 
  },  
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',       
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */      
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.IDplan != filaClonada?.IDplan ),
    style: row => ({ 
      backgroundColor:row.ActivoPlan=="1"  ? 'white' : valueDC.sistema.coloresItemInactivo,
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (row.IDplan == filaClonada?.IDplan),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

const TabelaPlanesDePago=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros (en el use effect si recibe NULL se devueove al MenuPrincipal)
  const [rowRegistroState,set_rowRegistroState]=useState(null) //(UN REGISTRO). mas que todo para color de la fila seleccionada y la tabela
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
  const [modalIncluirPlan,set_modalIncluirPlan]=useState(false) //para abrir el modal de incluir NUEVO plan
  const [modalModificarCabeceraPlan,set_modalModificarCabeceraPlan]=useState(false) //para abrir el modal de modificar el plan (pero solo la cabecera) 
  const [modalModificarDetallePlan,set_modalModificarDetallePlan]=useState(false) //para abrir el modal de modificar el plan (pero solo el detalle) 
  const [modalPractica,set_modalPractica]=useState(false)  

const miEstructura=[  
  {  
      name:'ID',
      selector:row => row.IDplan,      
      sortable:true,
      center:true,
      grow:0.2,
      compact:true, //padding 0
      //width:'50%',
      omit:true, //oculta (siempre) la celda
  },
  /*
  {
      name:'PRINCIPAL',
      selector:row => row.IdPrincipal,      
      sortable:true,
      compact:true,
      grow:1, //es una medida relativa (1 es mi referencia)
      //width:'13%',
      //style: {backgroundColor:'cyan'},
  },
  */
  {
      name:'NOMBRE DEL PLAN',
      selector:row => row.NombrePlan,
      backgroundColor:'red',   //solo es para probar (loego eliminar esta fila)    
      sortable:true,
      compact:true,
      grow:1.5,
  },       
  {
    //ACCIONES
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1, //originalmente 1.6    
    //width:'20%',

    //esto funciona fino
    //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.IDr}</div>{row.IdPrincipal}</div>,
    //esto funciona fino
    //cell: (row) => <div style={{ fontWeight: 900 }}>{row.IDr}</div>,
    //<div style={{ display:'flex' }}>            
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Editar y Eliminar */}
    <Button id="btnEditar" name="btnEditar" style={{ marginRight:'1px',}} raised = "false" primary='false' color="info" 
      onClick={ async (e) => {         
        opcionRegistro_imc='m' //'m'odificar registro (osea el plan)
        set_idMasState(0)
        set_rowRegistroState(row)        
        rowRegistroClon=row
        //debo determinar si desea modificar el encabezado del plan o el detalle.
        let miRespuesta=await mostrarTresOpcionesConSwal(`MODIFICAR ${rowRegistroClon.NombrePlan}`,"Desea modificar el encabezado del plan ? o el detalle ?","ENCABEZADO","DETALLE","SALIR")
        if (miRespuesta.isDismissed) return //salir
        if (miRespuesta.isConfirmed) { //quiere modificar el encabezado
            await buscarPeriodos('T') //busco  todos los periodos
            //al no haber conexion exitosa, pues no avanzo
            if (jsonPeriodos.length==0) {
              await mostrarSwalBotonAceptar("warning","NO HAY PERIODOS","Primero debe tener/crear periodos")
              return
            }
            else{
              //rumbo ideal para modificar el encabezado
              await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
              set_modalModificarCabeceraPlan(true)             
            }
        }
        if (miRespuesta.isDenied) { //quiere modificar el detalle
          //El detalle
          await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
          set_modalModificarDetallePlan(true)
        }
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faPencilAlt}/>
    </Button>
    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={ async (e) => {      
        if (await hayInternet()==false) return
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        const miRegistroEnviar="Seguro desea eliminar el plan: \n" + rowRegistroClon.NombrePlan
        const miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miRegistroEnviar)
        if (miResp.isDismissed) return //alomejor se arrepintio
        await eliminarPlanEnBDD(rowRegistroClon.IDplan)
        if (dataApi==null) return //no hago nada
        if (dataApi<0) {
          //al ser negativo es porque NO se pudo eliminar ya que ese plan fue usado en matriculas
          await mostrarSwalBotonAceptar("warning","NO SE PUEDE ELIMINAR","El plan ya fué asignado a algún alumno")
          return
        }
        if (dataApi>0) {
          //mando abajo a la derecha una notificacion de OK
          mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
        }
      }}>
      <FontAwesomeIcon size='lg' color="coral" icon={faTrashAlt} />
    </Button>
          
    {/* dropdown para el boton MAS... */}
    {/* al hacer clic por segunda vez consecutiva se debe apagar el desplegable */}
    {/* NOTA: la funcion obligarRender solo la usa este DROPDOWN direction:up', 'down', 'left', 'right' */}

    <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.IDplan  } direction="right"  
      toggle= { ()=> {
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.IDplan : 0)
        }}>    
      <DropdownToggle color="info">
        <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
      </DropdownToggle>

      <DropdownMenu style={{backgroundColor:'pink',}} >
        <DropdownItem onClick={ async() => {
          set_idMasState(0)
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faEye} /> Convertir en Inactivo</DropdownItem>              
        <DropdownItem onClick={ () => alert('disponible1 '  )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faCamera} />  Disponible1</DropdownItem>              
        <DropdownItem divider />
        <DropdownItem onClick={ () => {
          if (!hayFilaSeleccionada()) return
          /*
          if (rowRegistroClon.Email.trim().length==0){
            mostrarSwalBotonAceptar("warning","NO SE PUEDE ENVIAR","El cliente NO tiene dirección de correo")
            return
          }
          */
          set_idMasState(0)
 
          }}>          
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faEnvelope} /> Disponible2</DropdownItem>        

        <DropdownItem divider />
        <DropdownItem><FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>

      </Dropdown>
    </div>, 
  },   
  /* 
  {
    name:'COD_PERIODO',
    selector:row => row.IDaliasPeriodo,
    sortable:true,
    compact:true,
    grow:1,
    //width:'20%',
    hide:'sm',  
  }, 
  */
  {
    name:'PERIODO',
    selector:row => "(" + row.IDaliasPeriodo + ") "+ row.NombrePeriodo,
    sortable:true,
    compact:true,
    grow:1.5,
    //width:'20%',
    hide:'sm',  
  }, 
  {
    name:'GRAVA',
    selector:row => row.GravaIvaPlan,
    sortable:true,
    compact:true,
    center:true,    
    grow:0.8,
    omit:true,
  },      
  {
    name:'CREA',
    selector:row => row.OpCreaPlan,
    sortable:true,
    compact:true,
    grow:0.5,
    hide:'sm',
  },    
  {
    name:'MOD',
    selector:row => row.OpModPlan,
    sortable:true,
    compact:true,
    grow:1.5,
    hide:'sm',  
    omit:true, //el campo OpModPlan NUNCA se usa (solo OpCreaPlan)
  },    
]

const modificarJsonTabela=(miJson)=>{
  //Lo que hago es Modificar una fila en especial (segun los cambios que hizo el usario al plan cabecera)  
  //Los pisibles cambios serían (6 campos): IDperiodoPlan,NombrePlan,GravaIvaPlan,IDperiodo,IDaliasPeriodo,NombrePeriodo
  //Es posible que no quede actualizada los datos del director del posible cambio de periodo pero no importa
  //cuando es un NUEVO plan, entonces hay que leer de nuevo desde la BDD

  let filaEditada=null //guardo la fila editada, solo para poder simular un clic sobre ella
  let datosFullCopia=null
  datosFullCopia=registrosFullState.map( (item)=>{
    if (item.IDplan!=miJson.IDplan) return item
    if (item.IDplan==miJson.IDplan) {
      //modifico pocos campos del item (los otros campos quedan igual)
      item.IDperiodoPlan=miJson.IDperiodoPlan
      item.NombrePlan=miJson.NombrePlan
      item.GravaIvaPlan=miJson.GravaIvaPlan
      item.IDperiodo=miJson.IDperiodo
      item.IDaliasPeriodo=miJson.IDaliasPeriodo
      item.NombrePeriodo=miJson.NombrePeriodo
      filaEditada=item
      return item
    }
  })

  set_registrosFullState(datosFullCopia)    
  //quería simular un clic sobre la fila editada pero la pantalla se congela en edicion :( 
  //set_rowRegistroState(filaEditada)  
  //rowRegistroClon=filaEditada
}

const buscarPeriodos=async(selectAIT)=>{
  //Busca en la BDD, los periodos (los necesito cuando se va a crear un NUEVO plan. Para rellenar el combo)=> Aqui necesito solo los Activos
  //Tambien los necesito cuando voy a editar la cabecera de un Plan. Allí necesitaría activos+inactivos
  //Los datos que me devuelve mi API los guardo en la variable: jsonPeriodos. (al recibir NULL es que hubo algun error grave o de conexion)

  let data=new FormData()
  data.append('miSol','periodos_buscarPeriodos')
  data.append('orderBy','NombrePeriodo')
  data.append('forma','Asc')
  data.append('selectAIT',selectAIT) //varía según si dese NUEVO plan o Modificar cabecera de pla

  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  jsonPeriodos=miRecibido //No cambia el estado. Es solo una lista para mandarla a algun modal mas adelante
}
  
const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un Plan")          
    miDevolver=false
  }
  return miDevolver
}

const refrescarData2023=async()=>{
  let data=new FormData()
  data.append('miSol','planPago_buscarPlanesDePagoConPeriodos')
  data.append('orderBy','NombrePlan')
  data.append('forma','Asc')
  data.append('selectAIT','A') //Quiero solo los activos (tanto para Planes como Periodos)
  
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(miRecibido)   
}

// ==============================================================
// ========= simulo el componentDidMount y ciclo de vida ========
//===============================================================
useEffect(()=>{
  refrescarData2023()
  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    jsonPeriodos=null
    rowRegistroClon=null
    opcionRegistro_imc=''
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

// =====================================================
// ============== fin de ciclo de vida =================
//======================================================

const filtrarElementos=()=>{
  set_rowRegistroState(null)                 
  rowRegistroClon=null
  set_idMasState(0)

  let cadenaLoca=document.getElementById("inputBusqueda").value.trim().toLocaleLowerCase()  
  let filtradosTmp=registrosFullState.map(item=>{
    if (
      item.NombrePlan.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IDaliasPeriodo.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombrePeriodo.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item      
  })
  set_registrosFullState(filtradosTmp)
}  

const eliminarPlanEnBDD=async(codPlanEli)=>{
  //Elimina fisicamente el Plan y su detalle (afecta 2 tablas)
  //Luego de eliminar de la BDD, entonces elimino del JSON de la tabela también
  dataApi=null  

  let data=new FormData()
  data.append('miSol','planPago_eliminarUnPlanDePagos')
  data.append('codPlan',codPlanEli)
  
  //Puedo recibir null,-100,numero positivo (ideal)
  //-100 significa que ese plan ya fue usado en matriculas y no se pudo eliminar
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  if (dataApi===null) return
  if (dataApi<0) return 

  //Rumbo Ideal (Ahora elimino de la tabela)
  let datosFullCopia=registrosFullState.filter(item=>item.IDplan != codPlanEli)
  //actualizo el estado para la tabela, y quito la franja azul
  set_registrosFullState(datosFullCopia)
  set_rowRegistroState(null)
  rowRegistroClon=null
}

const ComponentePrincipalBody=()=>{  
return(      
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 
   <div id="divSubMaster" name="divSubMaster">
   {/******* Boton superior de OPCIONES (utilidades) ************/}
   <Row style={{margin:'0',paddingLeft:'0px'}} >     
   <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
    toggle= {()=>set_dropUtilidadesState(!dropUtilidadesState)}>
      <DropdownToggle caret color="primary">
        Opciones
      </DropdownToggle>
  
      <DropdownMenu style={{backgroundColor:'cyan',}} >
        <DropdownItem onClick={ () => alert('Listado' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faGlasses} /> Listado</DropdownItem>        
        <DropdownItem onClick={ () => alert(JSON.stringify(registrosFullState,null,2) )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de datos full</DropdownItem>                
        <DropdownItem onClick={ () => {
            alert(JSON.stringify(rowRegistroState,null,2))
            alert(JSON.stringify(rowRegistroClon,null,2))
          }}
          ><FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de la fila seleccionada
        </DropdownItem>            
        <DropdownItem divider />
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>
    </Dropdown>
    </Row>

    {/******* Menu superior, para telefono vertical: solo 4 botones ************  */}
    <div id="divMenuSuperior" name="divMenuSuperior" > 
      <Row style={{margin:'0px',}} >
        <Col>
          <ButtonGroup>
            <Button id="btnNuevo" name="btnNuevo" className="btnPpal btnBordebtnPpal" 
              onClick = { async () => { 
                opcionRegistro_imc='i' //'i'ncluir nuevo registro (obviamente Incluir.)
                set_rowRegistroState(null)
                rowRegistroClon=null
                set_idMasState(0)
                document.getElementById('inputBusqueda').value=''
                await buscarPeriodos('A')
                //Al no haber conexion exitosa entonces no hago nada (El supuesto mensaje de advertencia ya lo dá en la funcion buscarPeriodos)
                if (jsonPeriodos==null) return
                //Al no haber periodos activos, lanzo un mensaje indicando que no se puede avanzar (en ese caso el Json viene Vacio)
                if (jsonPeriodos.length==0)
                {
                  await mostrarSwalBotonAceptar("warning","NO HAY PERIODOS ACTIVOS","Primero debe tener/crear periodos activos")
                  return
                }
                //Rumbo ideal (activo el modal para crear).
                await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
                set_modalIncluirPlan(true)
              }}>
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faLightbulb} /> 
              </span>
              <span><br/>Nuevo</span>
            </Button>
            <Button id="btnRecord" name="btnRecord" className="btnPpal btnBordebtnPpal"  >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faRegistered} />
              </span>
              <span><br/>Record</span>
            </Button>
            {/*
            <Button id="btnEnviarEmail" name="btnEnviarEmail" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
              onClick = { () => {               
                set_idMasState(0)
                set_modalPractica (true)
                }} 
                >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faEnvelope} />
              </span>
              <span><br/>Spinning</span>
            </Button>
              */}
            <Button id="btnSaldoInicial" name="btnSaldoInicial" className="btnPpal btnBordebtnPpal btnOcultoPeq" 
              onClick = { () => { alert('previa') } }>  
              <span style ={{ fontSize:"35px"}}>
                  <FontAwesomeIcon color="gainsboro" icon={faCommentDollar}/> 
              </span>
              <span><br/>Previa</span>
            </Button>      
            <Button id="btnBloc" name="btnBloc" className="btnPpal btnBordebtnPpal btnOcultoPeq"   
              onClick = { () => { alert('Bloc') } } >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faBookOpen} />
              </span>
              <span><br/>Bloc</span>
            </Button>        
            <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
              onClick={async()=>{       
                if (await hayInternet()==false) return
                set_rowRegistroState(null)
                rowRegistroClon=null
                set_idMasState(0)
                document.getElementById('inputBusqueda').value=''
                await refrescarData2023()
              }}>  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /> 
              </span>
              <span><br/>Refresh</span>          
            </Button>
            <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"   
              onClick = { () => { 
                props.activarMenu()
              }} >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /> 
              </span>
              <span><br/>Salir</span>          
            </Button>
          </ButtonGroup>
        </Col>    
      </Row>
    </div> {/* divMenuSuperior */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input
          className = "textoBusquedaClientes"
          type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda" autoComplete='off'        
          onChange={ ()=>filtrarElementos() }
          />
        <Button  
          id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
          onClick={()=>{
            document.getElementById('inputBusqueda').value=''
            filtrarElementos()
          }}> 
          <FontAwesomeIcon color="paleGreen" icon={faBroom}/>
        </Button>        
    </div> { /* divBuscador1 */ }

   </div> { /* divSubMaster */ }

    { /* inicio del DIV para la tabela */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title='Lista de Planes Activos'
    columns={miEstructura}
    data={  registrosFullState==null ? [] : registrosFullState.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
    keyField ='IDplan' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
    onRowClicked={ (row) => {       
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)      
    }}
    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //    <h1><span>Nada por aquí</span></h1>
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //'60vh'  
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //falta: rvesiar esta parte, me parece interesante ya que debe arrancar ordenando por DESCRIPCION
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    //paginationPerPage={10} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationPerPage={ valueDC.sistema.paginadoParaPlanes }  
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

    {/************** MODAL PARA INCLUIR NUEVO PLAN (INCLUYE LA CABECERA y si todo sale bien, entonces  desde allá mismo se activa otro modal más. Osea el que crea la serie (el modal es ModificarPlanDePagos_Detalle)) ****************/}   
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalIncluirPlan }>
      <IncluirPlanDePagos_Cabecera 
          ocultarModal_Plan={async(accion,jsonRegistro)=>{            
            set_modalIncluirPlan(false)
            //accion puede ser: close/save/notfound
            if (accion=='close') return
            //al regresar del modal con save, es obligatorio REFRESCAR fisicamente. (no lo quise hacer manualmente haciendo PUSH al JSON ya que son muchas columnas que vienen de dos tablas con inner join)
            if (accion=='save') {   
              set_rowRegistroState(null)
              rowRegistroClon=null
              set_idMasState(0)
              await mostrarSwalBotonAceptar("info","LISTO",`Plan [${jsonRegistro.NombrePlan}] creado correctamente`)
              document.getElementById('inputBusqueda').value=''
              //Vuelvo a leer de la BDD fiscamente
              await refrescarData2023()             
            }
              
          }}
          opcionRegistro_imc={'i'}
          jsonPeriodos={jsonPeriodos}
      />
    </Modal>

    {/************** MODAL PARA MODIFICAR SOLAMENTE LA CABECERA ****************/}   
    {/* si todo sale bien, modifico el JSON de la tabela para no tener que leer fisicamente la BDD */}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalModificarCabeceraPlan } >
      <ModificarPlanDePagos_Cabecera 
          ocultarModal_Plan={async(accion,jsonRegistro)=>{            
            set_modalModificarCabeceraPlan(false)
            //accion puede ser: close/save
            if (accion=='close') return
            //al regresar del modal con save, NO leo nuevamente desde la BDD (lo que hago en modificar el JSON de la tabela )
            if (accion=='save') {   
              //document.getElementById('inputBusqueda').value=''
              set_rowRegistroState(null)
              rowRegistroClon=null
              set_idMasState(0)
              //aqui modifico el JSON para se vea la tabela actualizada
              modificarJsonTabela(jsonRegistro)
            }              
          }}
          opcionRegistro_imc={'m'}
          jsonPeriodos= {jsonPeriodos} //Aqui le mando los Periodos Activos e Inactivos
          rowRegistroClon={rowRegistroClon} //le mando la fila completa de la tabela
      />
    </Modal>

    {/************** MODAL PARA EDITAR EL DETALLE DE UN PLAN (osea las cuotas) ****************/}   
    <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalModificarDetallePlan }  >
      <ModificarPlanDePagos_Detalle 
          ocultarModal_Plan={async(accion)=>{            
            set_modalModificarDetallePlan(false)
            //accion puede ser: close/save
            if (accion=='close') return
            //al regresar del modal con save. No hace falta hacer/refrescar. Pero lo tengo separado alomejor en el futuro hay que hacer algo
            if (accion=='save') {
              //alert(JSON.stringify(jsonRegistro,null,2))
              return    
            }            
          }}           
        opcionRegistro_imc = {'m'} //Al Modal se va con 'm' puesto que se sobre-entiende que el plan ya existía (el modal prodri recibir 'i' cuando el modal es llamdo desde nuevo plan)           
        IDplan= { (rowRegistroClon==null) ? 0: rowRegistroClon.IDplan }
        NombrePlan={ (rowRegistroClon==null) ? 0: rowRegistroClon.NombrePlan }  
        GravaIvaPlan={ (rowRegistroClon==null) ? 0: rowRegistroClon.GravaIvaPlan }  
      />
    </Modal>    

    {/************** PRACTICAR MODAL **************** scrollable={false} */}   
    <Modal style={{ backgroundColor:'gainsboro',}} size={'sm'}  isOpen={ modalPractica }  
    centered={true}    
    >
      <PracticarModal 
          ocultarModal={async(accion)=>{            
            set_modalPractica(false)
 
          }}           

        //opcionRegistro_imc = {'m'} //Al Modal se va con 'm' puesto que se sobre-entiende que el plan ya existía (el modal prodri recibir 'i' cuando el modal es llamdo desde nuevo plan)           
        //IDplan= { (rowRegistroClon==null) ? 0: rowRegistroClon.IDplan }
        //NombrePlan={ (rowRegistroClon==null) ? 0: rowRegistroClon.NombrePlan }  
        //GravaIvaPlan={ (rowRegistroClon==null) ? 0: rowRegistroClon.GravaIvaPlan }  
      />
    </Modal>    
</div>  //Del divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} //const TabelaPlanesPago

export default TabelaPlanesDePago
