import React from 'react';
//Bonanza
import ReactDOM from 'react-dom/client';
//DE FABICA ESTABA: import './index.css';
import './estilos/index.css';
//de fabrica estaba: import App from './App';
import AppBonanza from './componentes/AppBonanza';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <React.StrictMode>
    <AppBonanza />
 </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
