//modal para seleccionar un MES y un ANO (por ejemplom para ver la tabela de Facturas)
import React, {useState,useEffect} from 'react'
import {Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//Context
import {AppContextConsumer} from './ContextBase'
//otros
import { aceptarSoloEnteros2023 } from './lib_basica'
//variables normales
let valueDC=null //Aqui hago una copia de los valores del context
 
const PedirMesYano_OK=(props)=> {
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const regresarSelect=(mesNuevo)=>{
  //regreso al llamador, con la accion 'select',y los dos datos deleccionados mes y ano
  let anoNuevo=document.getElementById('txtAno').value.trim() * 1 //lo convierto en numero
  props.cerrarModal('select',mesNuevo,anoNuevo)
}

const mostrarDatos=()=>{    
  //pongo el ano que se reciba desde el llamador
  document.getElementById("txtAno").value= (props.anoActual) * 1  
  let miBoton=null

  //Pongo en amarillo el mes que viene del llamador
  switch (props.mesActual*1) {
      case 1:
        miBoton=document.getElementById("btnEne")  
        break    
      case 2:
        miBoton=document.getElementById("btnFeb")  
        break    
      case 3:
        miBoton=document.getElementById("btnMar")  
        break    
      case 4:
        miBoton=document.getElementById("btnAbr")  
        break    
      case 5:
        miBoton=document.getElementById("btnMay")  
        break    
      case 6:
        miBoton=document.getElementById("btnJun")  
        break            
      case 7:
        miBoton=document.getElementById("btnJul")  
        break    
      case 8:
        miBoton=document.getElementById("btnAgo")  
        break    
      case 9:
        miBoton=document.getElementById("btnSep")  
        break            
      case 10:
        miBoton=document.getElementById("btnOct")  
        break            
      case 11:
        miBoton=document.getElementById("btnNov")  
        break            
      case 12:
        miBoton=document.getElementById("btnDic")  
        break                    
    }   
    
  miBoton.style.color='black'
  miBoton.style.backgroundColor='gold' 
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  mostrarDatos()

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //por ahora nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{
return (     
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

  {/* Titulo + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="9" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>Indíque el Mes y Año</Label>
    </Col>
    <Col xs="3" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ props.cerrarModal('close')  }} >
    <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
    </Col>
  </Row> 

  {/* Fila para seleccionar Mes y Ano */}
  <div style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink'}}>
    <Row style={{margin:'0',}}>
      <center>
      <Col xs="6">
          <Input type="number" min="2020" style={{marginTop:"4%",marginBottom:'8%',width:'80%',fontSize:'18px', textAlign:'center' }} id="txtAno" name="txtAno" 
            onKeyDown = {(e) => { aceptarSoloEnteros2023(e) } } 
            onPaste = {(e) => e.preventDefault() }  
          >
          </Input>
      </Col>
      </center>
    </Row>
    <Row style={{margin:'0',}}>
      <Col xs="6">
        <Button id="btnEne" name="btnEne" className="botonMeses"  
          onClick={()=>{regresarSelect(1)}}>Enero</Button>
        <Button id="btnFeb" name="btnFeb" className="botonMeses"  
          onClick={()=>{regresarSelect(2)}}>Febrero</Button>
        <Button size="md" id="btnMar" name="btnMar" className="botonMeses"  
          onClick={()=>{regresarSelect(3)}}>Marzo</Button>
        <Button size="md" id="btnAbr" name="btnAbr" className="botonMeses"  
          onClick={()=>{regresarSelect(4)}}>Abril</Button>
        <Button size="md" id="btnMay" name="btnMay" className="botonMeses"  
          onClick={()=>{regresarSelect(5)}}>Mayo</Button>                
        <Button size="md" id="btnJun" name="btnJun" className="botonMeses"  
          onClick={()=>{regresarSelect(6)}}>Junio</Button>        
      </Col>

      <Col xs="6">
        <Button size="md" id="btnJul" name="btnJul" className="botonMeses"  
          onClick={()=>{regresarSelect(7)}}>Julio</Button>        
        <Button size="md" id="btnAgo" name="btnAgo" className="botonMeses"  
          onClick={()=>{regresarSelect(8)}}>Agosto</Button>        
        <Button size="md" id="btnSep" name="btnSep" className="botonMeses"  
          onClick={()=>{regresarSelect(9)}}>Septiembre</Button>        
        <Button size="md" id="btnOct" name="btnOct" className="botonMeses"  
          onClick={()=>{regresarSelect(10)}}>Octubre</Button>        
        <Button size="md" id="btnNov" name="btnNov" className="botonMeses"  
          onClick={()=>{regresarSelect(11)}}>Noviembre</Button>        
        <Button size="md" id="btnDic" name="btnDic" className="botonMeses"  
          onClick={()=>{regresarSelect(12)}}>Diciembre</Button>        
      </Col>    
      </Row>  

    </div> {/* //div interno  */}
  </div> // div externo (master)
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del PedirMesYano_OK

export default PedirMesYano_OK