//Este form, me muestra los registros de la tabla interes_maestro
//Permite obviamente crear una nueva corrida de interes
//alert(JSON.stringify(jsonNegra,null,2))
import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input, Form, Label} from 'reactstrap'
import buscarPhpPath, { sleepPepe,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal } from './lib_basica'
import {ejecutarFetchGenericoConSwal} from './lib_basica'
import {mostrarTresOpcionesConSwal,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
import CargarNuevoInteres from './CargarNuevoInteres'

//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus,faMinus,faHandHoldingMedical,faLightbulb,faPencilAlt,faGlasses,faRegistered,faRunning,faCamera,faDoorOpen,faBroom,faBookReader,faBookOpen, faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed, faSearchDollar } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
//let jsonInteres=null //Aqui guardo una lista de los registros de la tabla interes_maestro
let opcionRegistro_imc='' //para saber si deseo incluir nuevo Plan, modificar o consultar
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)  
let anoBusqueda=null //para saber que ano desea el unstable_renderSubtreeIntoContainer. Arrancamos con el ano actual

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '47vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'LISTA DE PLANES'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      //paddingLeft: '50px', //estaba '50px', no me funciona, override the cell padding for head cells
      //paddingRight: '8px', //estaba '8px'
      background: 'hotpink', //ok hotpink
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    }, 
  },  
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',       
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */      
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.IDinteres != filaClonada?.IDinteres ),
    style: row => ({ 
      backgroundColor:'white',
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (row.IDinteres == filaClonada?.IDinteres),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

const TabelaInteres=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [anoLabelState,set_anoLabelState]=useState('AÑO ') //Para ver al ano de los interese
  const [registrosFullState,set_registrosFullState]=useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros (en el use effect si recibe NULL se devueove al MenuPrincipal)
  const [rowRegistroState,set_rowRegistroState]=useState(null) //(UN REGISTRO). mas que todo para color de la fila seleccionada y la tabela
  const [modalCargarNuevoInteresState,set_modalCargarNuevoInteresState]=useState(false) //para abrir el modal de CargarNuevoInteres

const miEstructura=[  
  {  
    name:'ID',
    selector:row => row.IDinteres,      
    sortable:true,
    center:true,
    grow:0.2,
    compact:true, //padding 0
    //width:'50%',
    omit:true, //oculta (siempre) la celda
  },  
  {
    name:'VENCE',
    selector:row => row.FechaVence,
    sortable:true,
    center:true,
    compact:true,
    grow:1.5,
  },       
  {
    name:'CORRIDA',
    selector:row => row.FechaCorrida,
    sortable:true,
    center:true,
    compact:true,
    grow:1.5,
    omit:true, //oculta (siempre) la celda
  },    
  {
    name:'TASA %',
    selector:row => row.InteresAplicado,
    sortable:true,
    center:true,
    compact:true,
    grow:1.5,
    //width:'20%',
    //hide:'sm',  
  }, 
  {
    name:'AFECTADOS',
    selector:row => row.CuotasAfectadas,
    sortable:true,
    compact:true,
    center:true,    
    grow:0.8,
    //omit:true,
  },      
  {
    name:'CREA',
    selector:row => row.OpCreaInt,
    sortable:true,
    compact:true,
    grow:0.5,
    hide:'sm',
  },  
]
 
const refrescarData=async()=>{
  //Busca en la BDD, los registros de la tabla interes_maestro (pero solo de un ano en especial)
  //Puede llegar null,vacio o con datos. Al llegar vacií es porquenhubo error en MysQl
  
  let data=new FormData()
  data.append('miSol','interes_buscarInteres')
  data.append('miAnoBusqueda',anoBusqueda)

  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(miRecibido)   
}

// ==============================================================
// ========= simulo el componentDidMount y ciclo de vida ========
//===============================================================
useEffect(()=>{
  let fechaActual=new Date()
  anoBusqueda=fechaActual.getFullYear()
  set_anoLabelState('AÑO ' + anoBusqueda)

  refrescarData() 
  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    rowRegistroClon=null
    opcionRegistro_imc=''
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.cerrarModal() 
  }
  },[registrosFullState]
)

// =====================================================
// ============== fin de ciclo de vida =================
//======================================================

const ComponentePrincipalBody=()=>{  
return(      
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'92vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 
   <div id="divSubMaster" name="divSubMaster">
    {/******* Menu superior, para telefono vertical: solo 4 botones ************  */}
    <div id="divMenuSuperior" name="divMenuSuperior" > 
      <Row style={{margin:'0px',}} >
        <Col>
          <ButtonGroup>
            <Button id="btnNuevo" name="btnNuevo" className="btnPpal btnBordebtnPpal" 
              onClick = { async () => { 
                opcionRegistro_imc='i' //'i'ncluir nuevo registro (obviamente Incluir.)
                set_rowRegistroState(null)
                rowRegistroClon=null
                set_modalCargarNuevoInteresState(true)
              }}>
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faHandHoldingMedical} /> 
              </span>
              <span><br/>Nuevo</span>
            </Button>     
            <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
              onClick={async()=>{       
                set_rowRegistroState(null)
                rowRegistroClon=null
                let fechaActual=new Date()
                anoBusqueda=fechaActual.getFullYear()
                set_anoLabelState('AÑO ' + anoBusqueda)     
                await refrescarData() 
              }}
              >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /> 
              </span>
              <span><br/>Refresh</span>          
            </Button>
            <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"   
              onClick = { () => { 
                props.cerrarModal()
              }} >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /> 
              </span>
              <span><br/>Salir</span>          
            </Button>
          </ButtonGroup>
        </Col>    
      </Row>
    </div> {/* divMenuSuperior */}
    {/* ****** para mostrar la frase - ANO 2024 + ************ */}
    {/* y así el usuario, podrá cambiarse de ano */}
    <div id="divCambiarAno" name="divCambiarAno" style = { {display:'flex',paddingLeft:'1%', marginBottom:'10px'}} > 
      <Button  
        id="btnMenos" name="btnMenos" color="secondary" style = {{marginLeft:'0px' }}
          onClick={async()=>{
            set_rowRegistroState(null)
            rowRegistroClon=null
            anoBusqueda--
            set_anoLabelState('AÑO ' + anoBusqueda)     
            await refrescarData() 
          }}> 
        <FontAwesomeIcon color="paleGreen" icon={faMinus} />
      </Button>          
      <Label style ={{marginLeft:'1%',marginRight:'1%',color:'white',marginTop:"5px",fontSize:'18px', fontWeight:'bold' }} >{anoLabelState}</Label>      
      <Button  
        id="btnMas" name="btnMas" color="secondary" style = {{marginLeft:'0px' }}
          onClick={async()=>{
            set_rowRegistroState(null)
            rowRegistroClon=null
            anoBusqueda++
            set_anoLabelState('AÑO ' + anoBusqueda)     
            await refrescarData() 
          }}> 
          <FontAwesomeIcon color="paleGreen" icon={faPlus} />
      </Button>   
    </div> { /* divCambiarAno */ }
   </div> { /* divSubMaster */ }

    { /* inicio del DIV para la tabela */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'3px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title='Lista de Intereses Cargados'
    columns={miEstructura}
    data={  registrosFullState ?? [] }
    conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro 
    keyField ='IDinteres' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
    onRowClicked={ (row) => {       
      set_rowRegistroState(row)
      rowRegistroClon=row
    }}
    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //    <h1><span>Nada por aquí</span></h1>
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //'60vh'  
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //falta: rvesiar esta parte, me parece interesante ya que debe arrancar ordenando por DESCRIPCION
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    //paginationPerPage={10} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationPerPage={ 10 }  
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

    {/************** MODAL PARA CARGAR INTERES  ****************/}   
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalCargarNuevoInteresState }>
      <CargarNuevoInteres 
        ocultarModal_Interes={async(accion,jsonInteresDevolver,anoReferencia)=>{            
          set_modalCargarNuevoInteresState(false)
          //accion puede ser: close/save/
          //en anoReferencia, me trae el ano al cual pertence la fecha de vencimiento para volver a mostrar en el label de que ano estamos hablando
          if (accion=='close') return
          //al regresar del modal con save, muestro el recordset
          anoBusqueda=anoReferencia
          set_anoLabelState('AÑO ' + anoBusqueda)
          set_registrosFullState(jsonInteresDevolver)
          await mostrarSwalBotonAceptar("info","LISTO",`Se afectaron ${jsonInteresDevolver[0].AfectadosUltimaCorrida} Alumnos`)
          }}
      />
    </Modal>
</div>  //Del divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} //de TabelaInteres

export default TabelaInteres
