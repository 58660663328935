//alert(JSON.stringify(rowRegistroState,null,2))
// ==== ******************
//permite facturar, las cuotas con saldo deudor de UN cliente
//Se podría dar descuento (pero  directamente en $. No en %)
//En la parte superior se muestra el MAN y su carrera (es posible que el man no tenga carrera)
//Los datos del man estan ligados al estado con la variable=> clienteState
//Debajo de los datos del man se muestra una tabela con sus cxc. Ligado a la variable de estado=>registrosFullStateFac
//La factura SIEMPRE sale a nombre del mismo estudiante/cliente
//La parte inferior descuento,base0,base12,ivad,total está ligado al estado
//al momento de pagar y registrar en la BDD la venta, si recibo NULL desde miApi entonces el FORM se cierra automaticamente (previamente doy una alerta que debe esperar unos minutos o perdor un edo de cuenta)
// ============ NOTAS SOBRE EL PAGO
//Se le debe preguntar al usuario si desea Abonar o hacer pago Total.
//Ejemplo de Abono=>Por ejemplo si marco DOS filas de 100$ c/u y recibo el pago de 180$, entonces el sistema CANCELA primero la mas vieja
//luego los 80$ se los abona a la segunda. 
//TRUCO, para que algo no cambie al sobreponer un MODAL, entonces se debe poner en un LABEL. ya que al ser de tipo TEXT se blanquea automaticamente al llamar a un modal

import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import DataTable from 'react-data-table-component'
import buscarPhpPath, {mostrarTresOpcionesConSwal,generarRandom43,ordenarArrayPorCampoFecha,ejecutarFetchGenericoConSwal,generarPresupuestoPDFconSwal,generarNotaEntregaPDFconSwal,generarFacturaDeVentaSimple_ConSwal,determinarFormatoImpresoraGrande,hayInternet,buscarProformaDetalleParaClonarModificar,hacerRenumDeUnJson } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalBotonAceptarBucle,mostrarSwalPos,mostrarSwalUnSegundo,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,devolverFechaHoyTextoYYYYMMDD,textoDelCombo,formatearFechaHoraDevuelveDDMMYY,sleepPepe } from './lib_basica'
//del context
import {AppContextConsumer} from './ContextBase'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faDownload, faWindowClose,faClipboard ,faFlag,faParking,faFileAlt,faDoorClosed,faDoorOpen, faBroom,faBarcode, faArrowDown,faArrowCircleDown,faArrowAltCircleDown,faEye,faPager,faCaretDown,faChevronCircleDown,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faMoneyBillAlt, faCashRegister,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faEllipsisV,faSyncAlt, faArrowAltCircleLeft, faGrinTongueSquint } from '@fortawesome/free-solid-svg-icons' 

//Componentes para Modales
import PedirDescuentoEnDolaresBonanza from './PedirDescuentoEnDolaresBonanza'
import GestionarPlanesDeUnAlumno from './GestionarPlanesDeUnAlumno'
import PedirFormaDePago from './PedirFormaDePago'
import VerPdfOk from './VerPdfOk'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. Osea una fila de la tabela que representa la cxc (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let dataApi=null //aqui guardo la respuesta de mi Api (la uso 2 veces. Al arrancar para leer los datos inciales ["cliente"] y ["Cxc"]) y también la uso para guardar la venta en la BDD. Al arrancar si todo sale bien paso a dos variables de Estado (Cliente y Cxc)
let miRandom43=null //cada vez que le dé al boton PAGAR se genera un nuevo uuid (para evitar duplicado al llamar a mi API)
let miAbonoTotalAT=null //opciones A,T para saber si se está haciendo en pago Total o es solo Abono (al momento de llamar al modal de forma de pago, ahí pregunto si dese pagar Todo o es Abono )
// las variables estabDef y puntoDef guardan el Estab Y Punto defintivo para poder hacer el documento en cuestion
let estabDef=null
let puntoDef=null
let numFacturaDef=null //numero de factura generado por el SP (Negativo: error). Aqui en JS la inicio con -3. (mysql devuelve -100 en adelante, al haber error en la class me devuelve -2)
//otras variables
let datosFormaPago=null //Para saber como ha pagado. (el modal de la forma de pago me develve un JSON)
//esta variable tipo Objeto/Json, me sirve para mandar a PHP como Cabecera y Pie
//los datos posibles son: AlContado,Plazo,Cancelado,CodClienteRojo,TipoPrecio,IdVendedor,IdFacturador,Observaciones,Pedido,TextoComplementario,GuiaRemPunto,GuiaRemNum,AfectaInventarioSN,AfectaCajaSN,
let caPiePhp={}
//aqui van los sutotales de la factura (se envia a PHP). La funcion que llena esta JSON es caalllcularTotal()
let subtotalFacturaPhp={}

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '25vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'ITEMS SELECCIONADOS'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      background: 'pink', //ok hotpink
      color: 'gray',  //ok lavender
      fontSize:'16px',   //ok
      fontWeight: 'bold'
    },
  },
  rows: {    
    style: {
      minHeight: '40px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',
    }
  },  
  cells: {
    style: {
      fontSize:'16px',
      cursor: 'pointer',
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',                 
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL      
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  {
    //segun el SELECTOR
    when: (row) => (row.Seleccionada_cxc=="1" ),
    style: ({ 
      //backgroundColor: valueDC?.sistema.coloresFilaSeleccionadaSecundaria,
      backgroundColor: 'orchid',
      color: 'white',
    }),
  },
]

const ParteCliente=(cliente)=> {   
  // Cuadros de texto referente al cliente: cedula, nombre, carrera, correo.....
  return (    
  <div id="divParteCliente" name="divParteCliente" style={{background:'white', marginLeft:'2%', marginRight:'2%',marginTop:'1%',marginBottom:'2%',paddingLeft:'10px',paddingRight:'10px',paddingBottom:'10px',borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderWidth:'2px',borderColor:'dimgray'}}>         
    {/* fila 1: cedula/ruc y nombre */}
    <Row style={{margin:'0'}}>
      <Col xs="3" style={{padding:'0'}}>
        <label style={{color:'dimgray'}}>Cédula/Ruc</label>
        <Input style={{paddingTop:'0',paddingBottom:'0' }} disabled name="txtCedulaCliente" id="txtCedulaCliente" value={cliente?.IdPrincipal}
        />            
      </Col>
      <Col xs="8" style={{padding:'0',marginLeft:'2%'}} >            
        <label style={{color:'dimgray'}}>Cliente</label>
        <Input style={{paddingTop:'0',paddingBottom:'0'}} disabled name="txtNombreCliente" id="txtNombreCliente" value={cliente?.RazonSocial} />
      </Col>  
    </Row>
    {/* fila 2: carrera y correo */}
    <Row style={{margin:'0'}}>
      <Col xs="3" style={{padding:'0'}}>
        <label style={{color:'dimgray'}}>Carrera</label>
        <Input style={{paddingTop:'0',paddingBottom:'0' }} disabled name="txtCarrera" id="txtCarrera"  value={cliente?.NombreCarrera}
        />            
      </Col>
      <Col xs="8" style={{padding:'0',marginLeft:'2%'}} >            
        <label style={{color:'dimgray'}}>Correo</label>
        <Input style={{paddingTop:'0',paddingBottom:'0'}} disabled name="txtCorreo" id="txtCorreo" value={cliente?.Email} />
      </Col>  
    </Row>    
  </div>
  )
}    
     
export default function FacturacionBonanza(props){      
  const [rowRegistroState, set_rowRegistroState] = useState(null) //mas que todo para color de la fila seleccionada y la tabela (en ésta variable estan las cxc)
  const [clienteState, set_clienteState] = useState([]) //aqui guardo los datos del cliente con su carrera. Pero si desde la api llega null, significa que el cliente ya no existe (alguien lo ha eliminado)
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState] = useState('lista') //Arranco viendo obviamente la lista de cuotas x pagar.  
  const [registrosFullStateFac, set_registrosFullStateFac] = useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros de cxc (en el use effect si recibe NULL se devuelve al componente llamador)
  const [descuentoState, set_descuentoState] = useState(0.00)  
  const [base0State, set_base0State] = useState(0.00)  
  const [base12State, set_base12State] = useState(0.00)  
  const [ivaState, set_ivaState] = useState(0.00)  
  const [totalState, set_totalState] = useState(0.00)  
  const [obsState, set_obsState] = useState('')  //para las observaciones

  //modales
  const [modalPedirDescuentoEnDolares,set_modalPedirDescuentoEnDolares]=useState(false) //para abrir el modal pedir descuento en $  
  const [modalPlanesState,set_modalPlanesState]=useState(false) //para abrir el modal de ver los planes del man
  const [modalPedirFormaDePagoState,set_modalPedirFormaDePagoState]=useState(false) //para abrir el modal de pagar
  const [modalTicket,set_modalTicket] =useState(false) //para ver el ticket en PDF  

const miEstructura=[  
  {  
    name:'IDcuota_cxc',
    selector:row => row.IDcuota_cxc,
    sortable:false,
    center:true,
    //grow:0.2,
    omit:true, //debe estar oculta.
  },
  {
    name:'Seleccionada_cxc',
    selector:row => row.Seleccionada_cxc, //(puede ser 0 o 1)
    sortable:false,
    center:true,
    //grow:0.2,
    omit:true, //debe estar oculta.
  },
  {  
    name:'IDplan_cxc',
    selector:row => row.IDplan_cxc,
    sortable:false,
    center:true,
    omit:true, //debe estar oculta.
  },   
  {  
    name:'PLAN',
    selector:row => row.NombrePlan,
    sortable:false,
    center:true,
    omit:true, //debe estar oculta.
  },     
  {
    name:'DESCRIPCION DE LA CUOTA',
    selector:row => row.Descripcion_cxc,
    sortable:false,
    left:true,
    compact:true,
    grow:2, //es una medida relativa (1 es mi referencia)
  }, 
  {
    name:'VENCE',
    selector:row => row.FechaVence_cxc,
    sortable:false,
    left:true,
    compact:true,
    grow:0.5,  
  },      
  {  
    name:'SALDO',
    selector:row => row.Saldo_cxc, //El saldo YA incluye el posible interes
    sortable:false,
    center:true,
    grow:0.5,
    compact:true, //padding 0
  },      
//===== ACCIONES
  {
  name:'',
  sortable:false,
  center:true,
  compact:true,
  grow:0.5, //originalmente 1.6    
  cell: (row) => 
  <div style={{ display:'flex' }}>            
  {/* boton Descontar $ */}
  <Button id="btnDescontar" name="btnDescontar" style={{ marginRight:'1px',}} raised = "false" primary='false' color="link" 
    onClick={ async () => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_modalPedirDescuentoEnDolares(true)
    }}>
    Dcto $
  </Button>

  </div>, 
},  
//======== fin de acciones
  {  
    name:'DCTO $',  
    selector:row => row.Descuento_cxc, //en $ por favor (nada de %)
    sortable:false,
    center:true,
    grow:0.5,
    compact:true, //padding 0
  },  
  {  
    name:'GRAVA',  
    selector:row => row.GravaIvaPlan, //'0' '1'
    sortable:false,
    center:true,
    //grow:0.3,
    compact:true, //padding 0
    omit: true, //esta columna siempre oculta
  },
]

const ParteTituloMasCerrar=()=> {   
return (
// parte para los datos del cliente
<div id="divTitulo" name="divTitulo" style={{height:'60px',borderStyle:'solid', borderWidth:'3px',borderColor:'dimgray'}}>        
  <Row style={{margin:'0'}}>
      <Col xs="10" style={{paddingTop:'5px',}}>
        <Label id="labelTipoDoc" name="labelTipoDoc" style={{fontSize:'30px',fontWeight: 'bold'}} ></Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'7px'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ 
            props.ocultarModal_Facturar("close",null)
            }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>  
  </Row>
</div>
)}

const validarUltima=async()=>{
  //Hay que chequear que haya por lo menos UNA fila seleccionada en la tabela
  let filasSeleccionadas=0
  registrosFullStateFac.forEach( fila=> {if (fila.Seleccionada_cxc=="1") filasSeleccionadas++ } )
  if (filasSeleccionadas==0){    
    await mostrarSwalBotonAceptar("error","ATENCION","No hay ninguna cuota seleccionada")
    return false  
  }
  if (subtotalFacturaPhp.total<=0){    
    await mostrarSwalBotonAceptar("error","ATENCION","El monto total debe ser mayor que cero")
    return false  
  }
  return true
}
 
const ParteBotoneraSuperior=()=>{   
return (
<div id="divParteBotoneraSuperior" name="divParteBotoneraSuperior" style={{background:'dimgray', marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%', borderStyle:'solid',borderColor:'black',borderWidth:'1px'}}>
  {/* Botones superiores En bonanza solo habrá DOS boton [VER PLANES DEL ALUMNO ] y [PAGAR] */}
  {/* [VER PLANES DEL ALUMNO ]=> El cual activa el mismo modal de <GestionarPlanesDeUnAlumno>. pero por las props le debo indicar que no quiero pone el boton de ELIMINAR ni ASIGNAR PLAN. solo me sirve como consula, tipo estado de cuenta   */}
  {/* en medio de los dos botones, voy a pponer un LABER GRANDE para mostrar el total a pagar  */}  

  <Row style={{margin:'0',paddingTop:'6px',paddingBottom:'6px',paddingLeft:'6px',paddingRight:'6px'}}>
    <Col xs="4" >
        <Button style={{width:"140px",height:"45px", fontSize:'20px',}} id="btnVerPlanes" name="btnVerPlanes" color="primary" 
          onClick={ (e) => { 
            //en ese form puedo ver los planes activos del man y a su vez se podría abrir otro modal para ver cada cuota del plan elegido (tipo estado de cuenta)
            set_modalPlanesState(true)            
          }}>
          VER PLANES
        </Button>
    </Col>   
    <Col xs="4" >
      <Label disabled id="txtTotalSup" name="txtTotalSup" style={{width:'98%',textAlign:'center', fontSize:'25px', fontWeight: 'bold',paddingTop:'0',paddingBottom:'0',background:'dimgray',}} >.</Label>
    </Col> 
    <Col xs="4" style={{textAlign:'right'}}>   
      <Button style={{width:"140px",height:"45px", fontSize:'20px',}} id="btnPagar" name="btnPagar" color="primary" 
        onClick = { async () => { 
          //validar que se haya seleccionado por lo menos UNA cuota y que haya algun monto  (obviamente NO se puede hacer una factura en cero)
          if (await validarUltima()==false) return    
          //Variable para asegurarme que realmente se puede pasar al modal de PedirFormaDePago. Ya que al Tratar de Abonar y ha seleccionado Cuotas grava con no grava mezcladas, entonces No se puede avanzar
          let sePuedeAbrirModal=true
          //Ahora pregunto, si desea pagar Todo o es Abono ? 
          let miRespuesta=await mostrarTresOpcionesConSwal(`FORMA DE PAGO`,`Desea Pagar ${subtotalFacturaPhp.total}$ o es Abono ?`,`${subtotalFacturaPhp.total} $`,"ABONO","SALIR")
          if (miRespuesta.isDismissed) return //salir
          if (miRespuesta.isConfirmed) { //Todo
              miAbonoTotalAT='T'
            }
          if (miRespuesta.isDenied) { //quiere Abonar
              miAbonoTotalAT='A'
              //Tengo que revisar que todas las cuotas sean del mismo tipo (todas grava o todas No grava. No se puede mezclar)
              if (subtotalFacturaPhp.sub0>0 && subtotalFacturaPhp.sub12>0){
                await mostrarSwalBotonAceptar("error","ATENCION","Las cuotas no son del mismo tipo")
                sePuedeAbrirModal=false
              }
              //Ahora tengo que revisar que si desea ABONAR, no hay descuento. ya que el descuento es solo cuando se va a pagar completo
              if (subtotalFacturaPhp.dcto>0){
                await mostrarSwalBotonAceptar("error","ATENCION","No se puede ofrecer descuento cuando se desea abonar")
                sePuedeAbrirModal=false                
              }
          }                    
          if (sePuedeAbrirModal) set_modalPedirFormaDePagoState(true)
        }}>PAGAR
      </Button>          
    </Col>
</Row>   {/* fin de Botones Superiores */}

</div> //divParteBotoneraSuperior
)
} 

const modificarJsonConNuevoDescuento=( codCuota,nuevoDcto ) => {   
  //codCuota viene como texto, pero así se queda
  //el parametro nuevoDcto viene como float y lo convierto en texto "3.14" para que coincida con los hermanos
  nuevoDcto=nuevoDcto.toFixed(2) 
  let registrosFullCopia=registrosFullStateFac.map ( ele =>{
    if (ele.IDcuota_cxc==codCuota) ele.Descuento_cxc=nuevoDcto
    return ele
  })

  set_registrosFullStateFac(registrosFullCopia)
}

const ParteGrid=()=>{
return(
<div id="divParteGrid" name="divParteGrid" style={{marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBotto:'0',width:'96%', borderStyle:'solid',borderColor:'black',borderWidth:'1px'}}>
  <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    //title='Cuotas x cobrar'
    columns={miEstructura}
    data={registrosFullStateFac ?? [] }  
    conditionalRowStyles={ filaCondicionalExterna(rowRegistroState) }      
    keyField ='IDcuota_cxc'  
    //highlightOnHover  
    onRowClicked={(row) => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
      let tabelaCopia=registrosFullStateFac.map( item =>{
        if (item.IDcuota_cxc==row.IDcuota_cxc)
          if (item.Seleccionada_cxc=="0") 
            item.Seleccionada_cxc="1"
          else
            item.Seleccionada_cxc="0"
        return item
      })
      //reemplazo la tabela
      set_registrosFullStateFac(tabelaCopia)
    }}

    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //    <h1><span>Nada por aquí</span></h1>
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //'60vh'  
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //falta: rvesiar esta parte, me parece interesante ya que debe arrancar ordenando por DESCRIPCION
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    //pagination
    //paginationPerPage={15} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    //paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
  />    { /* del componente DataTable */ }
  </div>
  )  
}

const ParteSubTotales=()=> {  
let miLabelLoca = "Subtotal " + valueDC.iva.Porcentaje + "%" 
return (
<div id="divParteSubTotales" name="divParteSubTotales" style={{padding:'9px',background:'dimgray', marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%', borderBottomStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderColor:'black',borderWidth:'1px'}}>    
  <Row style={{background:'dimgray',margin:'0'}}>
    <Col xs="7" md="9" style={{padding:"0"}}>
      <Input disabled id="labelDcto" name="labelDcto" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="Descuento"/>  
      <Input disabled id="label0" name="label0" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="SubTotal 0%"/>     
      <Input disabled id="label12" name ="label12" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue={miLabelLoca}  />  
      <Input disabled id="labelIva" name="labelIva" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="Iva"/>  
      <Input disabled id="labelTotal" name="labelTotal" style={{textAlign:'right', fontWeight: 'bold',paddingTop:'0',paddingBottom:'0'}} defaultValue="TOTAL"/>  
    </Col>
    <Col xs="5" md="3" style={{padding:"0"}}>
      <Input disabled id="txtDcto" name="txtDcto"  style={{paddingTop:'0',paddingBottom:'0' }} value={descuentoState} />  
      <Input disabled id="txt0" name="txt0" style={{paddingTop:'0',paddingBottom:'0' }} value={base0State} />  
      <Input disabled id="txt12" name="txt12" style={{paddingTop:'0',paddingBottom:'0' }} value={base12State} />  
      <Input disabled id="txtIva" name="txtIva" style={{paddingTop:'0',paddingBottom:'0' }} value={ivaState}/>  
      <Input disabled id="txtTotal" name="txtTotal" style={{textAlign:'left', fontWeight: 'bold',paddingTop:'0',paddingBottom:'0'}} value={totalState} />  
    </Col>
  </Row>
</div>
)
} 

const ParteBotonMasOpciones=()=>{
  // partecita para el boton de mas Opciones y observaciones
  return(  
    <div id="divMasOpciones" name="divMasOpciones" style={{marginTop:'6px',marginBottom:'10px',marginLeft:'2%',marginRight:'2%',}}>
      <Row style={{margin:'0'}}> 
        <Col style={{padding:'0',}}> 
          <Input style={{background:'white',}} autoComplete='off' placeholder="Observaciones" maxLength="100" name="txtObservaciones" id="txtObservaciones" value={obsState} 
          onChange={(e)=>{
            set_obsState(e.target.value)
          }}/>    
        </Col>
      </Row>
    </div>    
  )
}
   
const calcularTotal=() =>{
//Esta rutina, me recaallcula los valores que van en la arte inferior (DESCUENTO,BASE0,BAS12,IVA$,TOTAL)
//el total general, lo escribe 2 veces: en la parte inferior y en la parte superior (entre los botones VER PLANES y PAGAR)

subtotalFacturaPhp.propina=0 //No se usa
subtotalFacturaPhp.dcto=0 
subtotalFacturaPhp.sub0=0
subtotalFacturaPhp.sub12=0
subtotalFacturaPhp.ivaD=0 //iva Dolares
subtotalFacturaPhp.total=0

//variables propias de esa rutina (j: de java)
let j_cant=1 //en bonanza siempre 1
let j_ivaP=0
let j_pvp=0 //precio antes del descuento
let j_descP=0 //NO
let j_dcto=0 //en $
let j_renglon=0
let j_qty=0 //NO, esta era para indicar cuantas fila/unidades se han seleccionado en total

//let tabelaCopia=[...registrosFullStateFac]
//Recorro el json de la tabela (pero solo las filas TILDADAS) OJO CON ESO
registrosFullStateFac.forEach( fila =>{
    if (fila.Seleccionada_cxc=="1")  {
      //Obviamente, solo se consideran las seleccionadas
      j_ivaP=(fila.GravaIvaPlan=='0') ? 0 : parseFloat(valueDC.iva.Porcentaje)
      j_pvp=parseFloat(fila.Saldo_cxc)
      j_dcto=parseFloat(fila.Descuento_cxc) //descuento $ dije
      j_renglon=(j_cant * j_pvp) - j_dcto
    
      subtotalFacturaPhp.dcto += j_dcto //voy guardando el descuento, para mostrarlo abajo
      if (j_ivaP==0){
        subtotalFacturaPhp.sub0 += j_renglon
      }
      else{
        subtotalFacturaPhp.sub12 += j_renglon
      }
    }
  })

let numTexto=null
//*******hago los redondeos necesarios
//base 0
numTexto=subtotalFacturaPhp.sub0.toFixed(2) 
subtotalFacturaPhp.sub0=parseFloat(numTexto)
//base 12
numTexto=subtotalFacturaPhp.sub12.toFixed(2) 
subtotalFacturaPhp.sub12=parseFloat(numTexto)
//base Iva Dolares
let porcentajeIvaTMP=parseFloat(valueDC.iva.Porcentaje)
let ivaD_sr=(subtotalFacturaPhp.sub12 * porcentajeIvaTMP/100) //iva dolares sin redondear
numTexto=ivaD_sr.toFixed(2)
subtotalFacturaPhp.ivaD=parseFloat(numTexto)

//total a pagar
numTexto=subtotalFacturaPhp.propina + subtotalFacturaPhp.sub0 + subtotalFacturaPhp.sub12 + subtotalFacturaPhp.ivaD
numTexto=numTexto.toFixed(2)
subtotalFacturaPhp.total = parseFloat(numTexto)

//Usando el estado, actualizo el pie (descuento,base0,bas12,iva,total) 
set_descuentoState(subtotalFacturaPhp.dcto.toFixed(2))
set_base0State(subtotalFacturaPhp.sub0.toFixed(2))
set_base12State(subtotalFacturaPhp.sub12.toFixed(2))
set_ivaState(subtotalFacturaPhp.ivaD.toFixed(2))
set_totalState(subtotalFacturaPhp.total.toFixed(2))

//Escribo el TOTAL en la parte superior (en el medio de los botones VER PLANES y PAGAR). Es un label
//es un label y no esta ligado al state
document.getElementById('txtTotalSup').innerText=subtotalFacturaPhp.total.toFixed(2) + ' ' + "$"
}

const determinarEstab_y_PuntoDefinitivo=(tipoDoc)=>{
  //segun el documento que deseo hacer, debe haber un estab y punto definitivo
  switch (tipoDoc) {
    case "FA":
    case "PR":
    case "NECONIVA":
    case "NESINIVA":      
      estabDef=valueDC.usuario.EstabUsu
      puntoDef=valueDC.usuario.PuntoUsu
      break  
  }
}

const ponerTituloDeDocumento=(tipoDoc)=>{
  switch (tipoDoc) {
    case "FA":
      document.getElementById("divTitulo").style.background='deeppink' //purple
      document.getElementById("divTitulo").style.color='white'
      document.getElementById("labelTipoDoc").innerText= 'NUEVA FACTURA'  
      //#d5b5d4 //morado medio (este es)
      //#eadae9 //morado bien claro
      document.getElementById("miFormularioFacturacion").style.background='#d5b5d4'      
      break
    }
}

const buscarDatosIniciales=async()=>{
  //Busco los datos inciales osea ClienteMasCarreraMasCxc
  let data=new FormData()
  data.append('miSol','cli_buscarClienteMasCarreraMasCxc') //Osea las cuotas de un determinado plan
  data.append('IDr',props.IDr)
  dataApi=null
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  //Primero evalúo dataApi, sin entrar en ningun casillero
  //en caso de recibir NULL, el formulario se cierra automaticamente
  if (dataApi==null){
    set_registrosFullStateFac(null) 
    return
  }
  //en caso de recibir exitosamente, pero si el cliente viene NULL entonces cierro automaticamente
  if (dataApi.Cliente[0]==null){
    //pongo una alerta de que el cliente ya no existe
    await mostrarSwalBotonAceptar("error","ATENCION","El cliente ya no existe")    
    set_clienteState(null) 
    return
  }
  //aqui reviso, si vienen las cxc (alomejor hubo un error solo en esa parte)
  if (dataApi.CxC==null){
    //me salgo automaticamente
    set_registrosFullStateFac(null)  
    return
  }  

  //por acá todo cool. Cargo las dos variables necesarias (ya que dataApi la libero para poder usarla despues al guardar la factura)
  set_clienteState(dataApi.Cliente[0]) 
  set_registrosFullStateFac(dataApi.CxC)  
  dataApi=null //libero memoria
}  

// ==============================================================
// ========= simulo el componentDidMount y ciclo de vida ========
//===============================================================
useEffect(()=>{
  //==== Llamada principal
  //muestro en el titulo, el tipo de documento que deseo hacer, y pongo los colores respectivos
  ponerTituloDeDocumento("FA")
  //Creo y limpio el objeto caPiePhp
  limpiar_caPiePhp()
  //esa hay que dajarla SIEMPRE, para que me cree el JSON de totales y escriba puros 0 en la parte inferior
  calcularTotal()
  determinarEstab_y_PuntoDefinitivo("FA")
  buscarDatosIniciales() //Cargo al cliente con sus cxc

  //cuando sea NULL la respuesta de mi API. O cuando sea NULL el cliente buscado, entonces me salgo automaticamente
  //puede ser que dataApi venga exitoso, pero ["Cliente"] venga null. eso signifca que desde otro PC borraron el cliente
  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    set_registrosFullStateFac([]) //OBLIGATORIO VACIARLO con [] para que no haga colisión con el segundo useEffect (esta variable hace referencia a las CxC)    
    set_rowRegistroState(null)
    set_clienteState([])
  }
  },[]
)
  // *** cuando recibo NULL de mi API, entonces me devuelvo al componente llamador
  // pero si todo está bien, entonces re-calculo los totales
useEffect(()=>{  
  calcularTotal()
  if (registrosFullStateFac==null || clienteState==null ) props.ocultarModal_Facturar('close',null)
  },[registrosFullStateFac,clienteState]  
)
// =====================================================
// ============== fin de ciclo de vida =================
//======================================================

const limpiar_caPiePhp=()=>{
  //Creo y limpio este objeto (al crear la factura/presupuesto/nota/plan se va a PHP)
  //en Bonanza, hay variables que no se usan
  caPiePhp.AlContado=null //1,0
  caPiePhp.Plazo=null
  caPiePhp.Cancelado=null //1,0
  caPiePhp.CodClienteRojo=null //luego se actualiza desde el estado
  caPiePhp.TipoPrecio=null
  caPiePhp.IdVendedor=null
  caPiePhp.IdFacturador=null
  caPiePhp.Observaciones=null
  caPiePhp.Pedido=null
  caPiePhp.TextoComplementario=null
  caPiePhp.GuiaRemPunto=null
  caPiePhp.GuiaRemNum=null
  caPiePhp.AfectaInventarioSN=null //luego se actualiza desde el estado
  caPiePhp.AfectaCajaSN=null //luego se actualiza desde el estado
}

const prepararCaPiePhpCosasComunes=()=>{
  //aqui preparo solo algunas variables del Json caPiePhp
  caPiePhp.CodClienteRojo=clienteState.IDr 
  caPiePhp.TipoPrecio=0 //0,1,2,3,4 (0:precio comun) (aunque en Bonanza no importa)
  caPiePhp.IdVendedor=1 //Vendedor Unico
  caPiePhp.IdFacturador=1 //siempre 1
  caPiePhp.Observaciones=document.getElementById("txtObservaciones").value.trim()
  //En Bonanza nunca se va usar texto complementario
  caPiePhp.Observaciones=(caPiePhp.Observaciones.length==0) ? null : caPiePhp.Observaciones
  caPiePhp.Pedido=null
  if (caPiePhp.TextoComplementario!=null)
    caPiePhp.TextoComplementario=(caPiePhp.TextoComplementario.length==0) ? null : caPiePhp.TextoComplementario //me interesa que se vaya con null o con texto
  caPiePhp.GuiaRemPunto=null
  caPiePhp.GuiaRemNum=null
  caPiePhp.AfectaInventarioSN="N" //En Bonanza No
  caPiePhp.AfectaCajaSN="S" //En Bonanza Si
  caPiePhp.CobraIvaSN="S" //En Bonanza Si, (solo sería No cuando se desea vender no nota de netraga y No desglosar IVA)
}

const gestionar_FA_6pasos=()=>{
  //busco el formato del usuario logeado(valueDC.estable.Estab), generalmente: A4v
  let miFormato=determinarFormatoImpresoraGrande(valueDC,valueDC.estable.Estab,'FormatoFacturaSri')
  if (miFormato==''){
    console.log("No se consigue el formato, desde el modulo de facturacion para formato de factura sri")          
    return
  }  
  //llamo a una funcion en PHP que hace los 6 pasos. Pero NUNCA espero la respuesta
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','gestionar_DocumentoSri_6pasos')
  data.append('prefijoDoc',"FA") 
  data.append('miFormato',miFormato)  //generalmente A4v
  data.append('miEstab',estabDef) 
  data.append('miPunto',puntoDef) 
  data.append('numDoc',numFacturaDef) //numero de factura que se acaba de generar 
  data.append('miContext',JSON.stringify(valueDC)) //le mando el context completo
  data.append('destinoNombre',clienteState.RazonSocial)
  data.append('destinoEmail',clienteState.Email)  
  data.append('resolverSN', "N") // no se desea resolver, ya que eso se usa en la tabela, para documentos ROJOS
  fetch(miPhpFile, { method: 'POST',body:data }) //No espero la respuesta 
}   
  
const procesarFacturaEnBDD=async()=> {
  prepararCaPiePhpCosasComunes() //pone el IDr de cliente,tipo de precio, observaciones, texto complementario, vendedor, facturador
  let vectorDV=registrosFullStateFac.filter( fila=>fila.Seleccionada_cxc=="1")
  // NO HIZO FALTA ORDENAR ordenarArrayPorCampoFecha(vectorLoco,"FechaVence_cxc") 

  //genero UUID de 43 caracteres YO LE AGREGO _ddmmyy para GARANTIZAR que nunca se repita
  miRandom43=generarRandom43()
  //voy a guardar la factura en la BDD y recibo de vuelta el numero de la factura/negativo/null [lo ideal es un numero positivo]
  let data=new FormData()
  data.append('miSol','procesarFacturaEnBDD') 
  data.append('miContext',JSON.stringify(valueDC)) //le mando el context completo
  data.append('caPiePhp',JSON.stringify(caPiePhp)) //Cabecera y Pie de la factura    
  data.append('subtotalFacturaPhp',JSON.stringify(subtotalFacturaPhp)) //Objeto con Subtotales del documento. .propina .dcto  .sub0 .sub12 .ivaD .total
  data.append('miJsonDV',JSON.stringify(vectorDV)) //Aqui se va el detalle de la Venta (el grid, pero solo de las filas tildadas)
  data.append('miJsonDP',JSON.stringify(datosFormaPago)) //Aqui se va el detalle de la Forma de Pago        
  data.append('miRandom43',miRandom43) //Le mando mi UUID
  data.append('miAbonoTotalAT',miAbonoTotalAT) //Abono,Total (solo una letra en mayuscula)
  dataApi=null
  numFacturaDef=null
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  numFacturaDef=dataApi //en Data, viene el numero de la Factura en texto (o negativo si hubo un error en MySql, o Null al no haber conexion)
  dataApi=null //libero memoria
}
 
const gestionarFacturaComun=async()=>{
  await procesarFacturaEnBDD()
  if (numFacturaDef==null) return
  if (numFacturaDef<=0) return
   
  gestionar_FA_6pasos() //aqui NO espero la respuesta
}
 
const gestionarFacturaConFormaDePago=async()=>{
 //alimento una parte de caPiePhp
 caPiePhp.AlContado="1"
 caPiePhp.Plazo="0"
 caPiePhp.Cancelado="1"

 //llamo a la funcion que es comun a todas las formas de pago
 await gestionarFacturaComun()
} 

const ComponentePrincipalBody=()=>{
return (
<div id="miFormularioFacturacion" name="miFormularioFacturacion" style={{margin:'0',width:"100%",paddingBottom:'0%'}}>    
  {ParteTituloMasCerrar()}
  {ParteCliente(clienteState)}
  {ParteBotoneraSuperior()}
  {ParteGrid()}
  {ParteSubTotales()}
  {ParteBotonMasOpciones()} {/* boton MAS OPCIONES y observaciones, en Bonanza solo OBSERVACIONES */}

{/*********************** MODAL PARA DESCUENTO EN $ ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ modalPedirDescuentoEnDolares } >
  <PedirDescuentoEnDolaresBonanza      
    cerrarModal_Descuento={async(accion,descuentoFloat)=>{
      set_modalPedirDescuentoEnDolares(false)
      //las acciones puede ser "save" o "close" y recibo el nuevo descuento (tipo number) con separador . (ejemplo 3.14)
      if (accion=='close') return
      //al ser 'save' es lo ideal
      modificarJsonConNuevoDescuento(rowRegistroClon.IDcuota_cxc,descuentoFloat)
    }}
  titulo={`DCTO ($) PARA: ${rowRegistroClon?.Descripcion_cxc}` } //Sería el nombre de la cuota
  subTitulo={'Máximo 2 decimales'}
  maximo={rowRegistroClon?.Saldo_cxc} //se va como texto '3.14'
  actual={rowRegistroClon?.Descuento_cxc} //se va como texto '3.14'
  />
</Modal> {/* fin de modal de Descuento en $ */}

{/* ***************** MODAL PARA VER LOS PLANES DE UN ALUMNO *************** */}    
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalPlanesState } >
  <GestionarPlanesDeUnAlumno 
    ocultarModal_Gestionar={async(accion,jsRecibido)=>{
      set_modalPlanesState(false)
      //accion puede ser: close/save (pero acá solo close)
        if (accion=='close') return
        if (accion=='save') return 
      }}
    //Datos del alumno
    IDr={ clienteState?.IDr }   
    RazonSocial={ clienteState?.RazonSocial }   
    IdPrincipal={ clienteState?.IdPrincipal }           
    //Parametros del form
    botonAsignarPlanVisible={false} //el form GestionarPlanesDeUnAlumno, a veces es llamad desde otro sitio y no se necesitaría el boton ASIGNAR ni otros
    botonRefreshPlanesVisible={false} //la misma historia
    botonEliminarPlanVisible={false} //la misma historia
  />
</Modal>  

{/* ************************* MODAL PARA LA FORMA DE PAGO *************** */}
<Modal style={{ background:'blue',}} size={'lg'} isOpen={ modalPedirFormaDePagoState } >
  <PedirFormaDePago        
    totalFull= {subtotalFacturaPhp.total} //le mando en float. total a cobrar realmente (2 decimales)        
    mostrarRetSN= {'N'} //Mostrar retencion en la forma de pago        
    todoAbonoTA={miAbonoTotalAT} //Para saber si debe pagar Todo, o puede hacer Abono
    recibirPago = { async(miFaltanteFloat,miJsonTmp,seleccionOk) => { 
      //la variable miFaltanteFloat ya viene en float y con 2 decimales
      set_modalPedirFormaDePagoState(false)
      if (!seleccionOk) return
      //rumbo ideal       
      datosFormaPago=miJsonTmp  //guardo en mi variable local, los numeros de transferencia,cheque...monto de los instrumentos de pago....
      //cuando el pago es TOTAL, no hay que hacer nada adicional. pero si el pago fue Abono, pueden pasar dos cosas.
      //caso a) del abono=> alomejor metiendose en abono hizo el pago completo (entonces lo convierto directamente en pago Total)
      //caso b) de abono=> Acá está el problema. Tengo que recalcular nuevamente la base0, base12, ivaD, total para que coincida con lo que se vá al SRI 
      //FALTA TERMINAR
      // caso a)
      if (miAbonoTotalAT=="A" && miFaltanteFloat==0){
        //el usuario, entró a Abonar pero realmente hizo el pago completo
        miAbonoTotalAT="T"
      }
      // caso b)
      if (miAbonoTotalAT=="A" ){
        //Al entrar aquí hay que re-calcular todos los subototales de la factura. Esto depende si las cuotas gravan o no gravan.
        let gravaSN=null //necesito saber que tipo de cuotas se habían seleccionado
        let montoAbonado=subtotalFacturaPhp.total-miFaltanteFloat //Este es el nuevo monto global que hay que enviar al SRI
        gravaSN=subtotalFacturaPhp.sub12>0 ? "S" : "N"
        subtotalFacturaPhp.sub0=0
        subtotalFacturaPhp.sub12=0
        subtotalFacturaPhp.ivaD=0
        subtotalFacturaPhp.total=0
        //Re-calculo cuando NO grava
        if (gravaSN=="N"){
          subtotalFacturaPhp.sub0=montoAbonado
          subtotalFacturaPhp.total=montoAbonado
        }
        //Re-calculo cuando SI grava
        if (gravaSN=="S"){
          let porcentajeIvaTMP=parseFloat(valueDC.iva.Porcentaje) // por ejemplo 12
          let miFactor=1+(porcentajeIvaTMP/100) //ejemplo 1.12
          //nueva base12
          let nuevaBase12=montoAbonado/miFactor
          nuevaBase12=nuevaBase12.toFixed(2) //le pongo solo 2 decimales
          subtotalFacturaPhp.sub12=parseFloat(nuevaBase12)
          //nuevo Iva Dolares
          let nuevoIva=(subtotalFacturaPhp.sub12 * porcentajeIvaTMP/100) //iva dolares sin redondear
          nuevoIva=nuevoIva.toFixed(2)
          subtotalFacturaPhp.ivaD=parseFloat(nuevoIva)
          //nuevo total para el SRI
          subtotalFacturaPhp.total = subtotalFacturaPhp.sub12 + subtotalFacturaPhp.ivaD
          //NOTA FINAL: El monto del Abono debería ser exactamente igual a subtotalFacturaPhp.total, pero a veces se pierde algun decimal (al desglosar el iva)
        }
      }

      //guardo en la BDD y lo ideal es recibir un numero positivo (osea el numero de la Factura generada)
      await gestionarFacturaConFormaDePago() //allá debo llamar a los 6 pasos en segundo plano
      if (numFacturaDef==null) {
        set_clienteState(null) //se cierra automaticamente el form
        return
      }
      if (numFacturaDef<=0) {
        await mostrarSwalBotonAceptar("error","ATENCION","No se pudo registrar la factura. Código de error # " + numFacturaDef) 
        return
      }   

      //Genero el pdf del ticket y luego lo muestro en un modal. 
      let data=new FormData()
      data.append('miSol','ticketBonanza_generarPDF')
      data.append('miEstab',valueDC.usuario.EstabUsu)
      data.append('miPunto',valueDC.usuario.PuntoUsu)
      data.append('miNumFac',numFacturaDef)
            
      //en dataApi, puede venir null o el mismo numero de Factura enviada. Nunca viene negativo
      let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)     
      //al no poder generar el PDF, me regreso a la tabela de facturas
      if (dataApi==null) {
        props.ocultarModal_Facturar("save",numFacturaDef)
      }
      else{
        //Al generarse correctamente el ticket, entonces lo muestro en un modal. y desde el modal del ticket, al cerrar el ticket, también cierro la facturacion
        set_modalTicket(true)   
      }   
  }}
  />
</Modal> {/* fin de modal FORMA DE PAGO */}

{/************ MODAL PARA VER EL TICKET DE LA FACTURA *************/}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalTicket } >
  <VerPdfOk
    documentoTitulo={"FACTURA"}
    documentoArchivo={"TI_" + valueDC.usuario.EstabUsu + "-" + valueDC.usuario.PuntoUsu + "-" + numFacturaDef }
    corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
    tipoSriSN={"N"} //es un documento del sri?
    estiloPantallaPG={"P"} //Para modal le mando P
    activarMenu={ () => {
      set_modalTicket(false) 
      props.ocultarModal_Facturar("save",numFacturaDef)
    }} 
  />
</Modal>  

</div> // div principal (miFormularioFacturacion)
) //del return interno
}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}
  //*******************************************************************
  // ***************** Programa principal *****************************
  //*******************************************************************
  if (nombreComponenteParaVerState == 'lista') return ComponentePrincipal()
  
} //del const FacturacionBonanza