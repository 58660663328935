//alert(JSON.stringify(jsonNegra,null,2))
//***************************** importante *****************/
//SOLO LA PANTALLA, NO LLEVA PROGRAMACION DE NADA (solo recibe por pros. el titulo y el texto para el label que presenta al COMBO)
//Sirve de base para crear un componente que funcione y lleve lógica y programación
//Sirve para que quién la use, pueda seleccionar un Plan y guardar y bla bla bla...

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input, Form, FormGroup, Label} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 

const PantallaSeleccionarUnPlan_SinProgramacion=(props)=>{   
return(      
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
  {/* <Form id="miFormMatricular">  */}
{/* DIV para el encabezado: y boton SALIR */}
<div id="divSalir" name="divSalir" style={{backgroundColor:'silver',paddingTop:'0', paddingBottom:"2%"}}>
  <Row style={{margin:'0',}}>
    <Col xs="6" style={{paddingTop:'1%',}}>
         <h3 id="tituloH1">{props.titulo}</h3>
    </Col>   
    <Col xs="6" style={{padding:'0',textAlign:'right'}}> 
      <Button style={{ width: '40px', height: '40px', }} id="btnCerrarPantallaSeleccionarPlan" name="btnCerrarPantallaSeleccionarPlan" color="danger">
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
      </Button>            
    </Col>
  </Row>             
</div> {/* divSalir */}

{/* DIV para el cuerpo=> un label y un combo para planes */}
<div>
  <Row style={{margin:'0',}}>
    <Col xs="12" style={{textAlign:'center'}}>
      <FormGroup style={{display:'flex',marginTop:'2%',marginLeft:'0%', }}>
        <Label for="comboPlanes" style={{width:'30%'}}>{props.label1Texto}</Label>
        <Input type="select" name="comboPlanes" id="comboPlanes" style={{width:'65%',marginRight:'1%',}}/>
      </FormGroup>
    </Col>
  </Row>
</div>

<div id="divGuardar" name="divGuardar" style={{backgroundColor:'silver',paddingTop:'2%', paddingBottom:"2%"}}>
  <Row style={{margin:'0',}}>
    <Col xs="12" style={{margin:'0',paddingLeft:'1%',paddingRight:'1%',textAlign:'center'}}>
      <Button id="btnGuardarPantallaSeleccionarPlan" name="btnGuardarPantallaSeleccionarPlan" color="primary" solid="true" style={{width:"40%",color:'white'}} 
      >{props.boton1Texto}</Button>                   
    </Col>
  </Row>             
</div>
{/* </Form> */}

</div> // divMaster
) //del unico return
} //del const PantallaSeleccionarUnPlan_SinProgramacion

export default PantallaSeleccionarUnPlan_SinProgramacion
