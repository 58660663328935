//alert(JSON.stringify(jsonNegra,null,2)) 
//Este form me permite cargar/correr un nuevo INTERES
//Se puede ejecutar varias veces en un mismo mes 
//El SP nunca carga interes sobre interes. Por seguridad solo lo hace UNA vez
//Una vez cargado, el SP me devuelve un conjunto de filas del interes_maestro (pero de un solo ano en especial. Del mismo ano de la _FechaVence)
//Al haber error el MySql, recibo un select vacio.
//Entonces al llamador, le devuelvo el recordset para evitar una nueva lectura de la BDD
import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import {ejecutarFetchGenericoConSwal,aceptarSoloEnteros2023,aceptarDecimales2023,convierteObjetoFechaDeJSenTexto,devolverObjetoFechaApartirDeUnTexto,reemplazarPuntoPorComa } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio

//Variables globales de este archivo
let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let jsonInteresDevolver=null //Aqui guardo un JSON con el recordset que voy a devolver al llamador

const validarPantallaInteres=async()=>{
  //Obviamente valído la pantalla,

  let miTxt=null //de uso común para ir guardando el contenido de los campos

  // ===== tasa =====
  miTxt=document.getElementById("txtTasa").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","Debe indicar la tasa de interés")
    return false
  }
  let miFloat=parseFloat(document.getElementById("txtTasa").value)

  if (miFloat<=0){
    mostrarSwalBotonAceptar("error","ATENCION","La tasa, debe ser positiva")
    return false
  }  
  // ===== Fecha =====
  miTxt=document.getElementById("txtFechaVence").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","La fecha no puede estar vacía")
    return false
  }  
  return true
}

const calcularInteres=async(txtTasa,txtFechaVence)=>{  
  //Cargo los intereses

  jsonInteresDevolver=null
  let data=new FormData( document.getElementById("miFormularioSerie") )
  data.append('miSol','interes_calcularInteres')
  data.append('miInteresAplicado',txtTasa)
  data.append('dia',txtFechaVence.getDate())
  data.append('mes',txtFechaVence.getMonth()+1) //ojo: siempre así
  data.append('ano',txtFechaVence.getFullYear())
  data.append('miContext',JSON.stringify(valueDC)) //le mando el context completo
  jsonInteresDevolver=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
}

const CargarNuevoInteres=(props)=>{    
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  const [tabActivoState,set_tabActivoState]=useState('1') // Solo hay buna pestana de todos modos
  const [txtFechaVence,set_txtFechaVence]=useState(null) //arranca sin fecha para obligarlo a que la ponga
  const [txtTasa,set_txtTasa]=useState('') 
  
  //Solo hay una pestana

const TabUnica=()=>{   
  return (
    <div id="divTabUnica" name="divTabUnica">
      {/* para fecha  */}
      <Row style={{margin:'0'}}> 
        <Col>
          <div style={{display:'flex',width:'96%',marginTop:'4%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>           
            <Label for="txtFechaVence" style={{marginRight:'4%'}}>Fecha Vencimiento</Label>
            <DatePicker autoComplete='off' className="anchoDatePickerGenerico" id="txtFechaVence" name="txtFechaVence" locale='es' dateFormat="dd/MM/yyyy"
              selected={ txtFechaVence }          
              onChange= {(datePar)=>set_txtFechaVence(datePar) }
            /> 
          </div>
        </Col>
      </Row>      
      {/* para interes (tasa) */}
      <Row style={{margin:'0'}}> 
        <Col>
          <div style={{display:'flex',width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
            <Label for="txtTasa" style={{marginRight:'4%'}}>Tasa %</Label>
            <Input name="txtTasa" id="txtTasa" autoComplete='off' autoFocus style={{width:'25%'}} value={txtTasa} 
              onChange={(e)=>set_txtTasa(e.target.value)}
              onKeyDown={(e)=>aceptarDecimales2023(e)} 
            />
          </div>   
        </Col>
      </Row>
    </div>
  )}
      
//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  //alimento los valores de arranque
  set_txtTasa(reemplazarPuntoPorComa( valueDC.sistema.porcentajeInteresAtraso) )
  // Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    //por ahora vacío
  }
  },[]
)

const ComponentePrincipalBody=()=>{
return(    
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
  <Form id="miFormularioSerie">
    {/**** barra de titulo  */}
      <div id="divBarraTitulo" name="divBarraTitulo">
        <Row style={{ margin: '0' }}>
          <Col xs="10">
            <Label style={{ marginTop: '5px', fontWeight: 'bold', color: "coral" }}>{"CARGAR INTERES" }</Label>
          </Col>
          <Col xs="2" style={{ textAlign: 'right', padding: '0' }}>
            <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
              onClick={() => {
                props.ocultarModal_Interes('close',null,null)
              } }>
              <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
            </Button>
          </Col>
        </Row>
      </div> {/* divBarraTitulo */}

    {/* aqui creo un tab de 1 pestanas */}   
    <ModalBody>
    <Nav tabs >
      <NavItem >
        <NavLink style={{ backgroundColor:'hotpink'}}
          active= {tabActivoState == '1'}
          onClick={() => { 
            if(tabActivoState=='1') return 
            set_tabActivoState('1')
            }}>
          PARAMETROS
        </NavLink>
      </NavItem>
    </Nav>

    <TabContent activeTab={tabActivoState} style={{ backgroundColor:'pink', borderStyle:'solid', borderColor:'silver',borderWidth:'medium'  }}> 
      <TabPane tabId="1">        
        {TabUnica()}
      </TabPane>
    </TabContent>
    </ModalBody>

    {/* boton de GUARDAR */}
      <div id="divMenuInf" name="divMenuInf" style={{backgroundColor:'silver',paddingTop:'2%', paddingBottom:"2%"}}>
        <Row style={{margin:'0',}}>
          <Col xs="12" style={{margin:'0',padding:'0',textAlign:'center'}}>
            <Button id="btnGenerar" name="btnGenerar" color="success" solid="true" style={{width:"90%", marginRight:'3%',color:'white'}} 
              onClick={async ()=>{
                if (await validarPantallaInteres()==false) return
                await calcularInteres(txtTasa,txtFechaVence) //en la variable jsonInteresDevolver, guardo un JSON con todos los registros de interes_maestro
                //debo revisar que el JSON tenga datos
                //Cuando viene null, es porque evidentemente hubo error fisico
                if (jsonInteresDevolver==null) {
                  props.ocultarModal_Interes('close',null,null)
                  return 
                }
                //al venir vacio es porque hubo error en MySql pero el error fue atrapado en MySql
                if (jsonInteresDevolver.length==0) {
                  await mostrarSwalBotonAceptar("error","ATENCION","No se pudo calcular los intereses")
                  props.ocultarModal_Interes('close',null,null)
                  return                  
                }           
                //rumbo ideal.  
                props.ocultarModal_Interes('save',jsonInteresDevolver,txtFechaVence.getFullYear())                                              
              }}>CALCULAR INTERES
            </Button>
          </Col>
        </Row>
      </div> {/* divMenuInf */}

  </Form> {/* miFormulario */}
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del const CargarNuevoInteres

export default CargarNuevoInteres