import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,ModalBody, Button,ButtonGroup, Input, Form, ModalHeader, ModalFooter, ListGroupItem, Spinner} from 'reactstrap'
import buscarPhpPath,{ejecutarFetchGenericoConSwal,convertirUnTextBoxEnEntero,convertirUnTextBoxEnFloat,sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,verificarSiTextoEsPurosDigitos, aceptarSoloEnteros } from './lib_basica'
import PantallaEncabezadoPlanDePagos, {validarPantallaPlanDePagos_Cabecera} from './PantallaPlanDePagos_Cabecera'
import ModificarPlanDePagos_Detalle from './ModificarPlanDePagos_Detalle'
//del context
import {AppContextConsumer} from './ContextBase'
let codigoRojoDevueltoPorSql=null //lo ideal es recibir el mismo numero enviado a mi API
let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let jsonDevolver=null //este objeto JSON lo devuelvo al llamador. Por ahora por comodidad solo le devuelvo un JSON con pocas columnas


const PracticarModal=(props)=>{   
//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
 
//aun nada
  },[]
)

return ( 
<div id="divMaster" name="divMaster" style={{backgroundColor:'gainsboro'}}>
    
      <div style={{textAlign:"center"}}>
        <Spinner
         color="success"
         size="lg"
         type="grow"
        /> 

        <Spinner
          color="primary"
          style={{
            height: '7rem',
            width: '7rem'
          }}

        /> 
      </div>

    <Button id="btnSalir" name="btnSalir" color="secondary" solid="true"  style={{width:"46%",color:'white',}} 
            onClick= { () => {           
              props.ocultarModal("close",null)
            }}      
          >SALIR</Button>              
</div>
)
} 

export default PracticarModal
