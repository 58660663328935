//alert(JSON.stringify(jsonNegra,null,2))
//***************************** importante *****************/
//me permite modificar la CABECERA de un plan de pagos  (nunca el detalle)
//El mismo usuario, debe revisar previamente de forma manual si está repetido el Nombre de Plan
//Pero en caso que a la fuerza, esté metiendo un nombre repetido, el SQL me devuelve -100

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input, Form} from 'reactstrap'
import buscarPhpPath,{ejecutarFetchGenericoConSwal,convertirUnTextBoxEnEntero,convertirUnTextBoxEnFloat,sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,verificarSiTextoEsPurosDigitos, aceptarSoloEnteros } from './lib_basica'
import PantallaEncabezadoPlanDePagos, {validarPantallaPlanDePagos_Cabecera} from './PantallaPlanDePagos_Cabecera'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
let dataApi=null //lo que recibo de mi Api (Null,Negativo, Num Positivo)
let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let jsonDevolver=null //este objeto JSON lo devuelvo al llamador. Por ahora por comodidad solo le devuelvo un JSON con pocas columnas

const prepararJsonParaDevolver=(jsonPeriodos)=>{
  //Por comodidad, devuelvo pocos valores.  
  //En la tabela, uso este JSOn para modificar nada mas la fila del registro editado (y ahorrarme una lectura a la BDD)
  //la variable jsonPeriodos, lo uso para sacar el alias 
  jsonDevolver={}
  // Información del plan
  jsonDevolver.IDplan=dataApi //Obviamente es el mismo enviado desde la tabela
  jsonDevolver.IDperiodoPlan=document.getElementById("comboPeriodo").value //me da el codigo del periodo
  jsonDevolver.NombrePlan=document.getElementById("txtNombrePlan").value.trim() 
  jsonDevolver.GravaIvaPlan=(document.getElementById("checkGrava").checked) ? "1": "0" 
  // datos del periodo 
  jsonDevolver.IDperiodo=document.getElementById("comboPeriodo").value //me da el codigo del periodo
  jsonDevolver.IDaliasPeriodo=(jsonPeriodos.find( (peri) => (peri.IDperiodo==jsonDevolver.IDperiodo))).IDaliasPeriodo
  let comboLoco = document.getElementById("comboPeriodo")
  jsonDevolver.NombrePeriodo=comboLoco.options[comboLoco.selectedIndex].text    
}

const guardarRegistro=async(props)=>{  
  jsonDevolver=null //con los nuevos datos (maximo 3 campos modificados/cambiados, codPeriodo,nombreplan, grava)
  dataApi=null
  
  if (await validarPantallaPlanDePagos_Cabecera()==false ) return  
  let data=new FormData(document.getElementById("miFormularioRegistro"))
  data.append('miSol','planPago_modificarPlanDePagos_Cabecera')
  data.append('codPlan',props.rowRegistroClon.IDplan)
   
  //Desde mi API, puede venir null, o el mismo numero enviado, o negativo  (lo ideal es justamente recibir de vuelta el mismo numero enviado)
  //En caso que se esté metiendo un nombre repetido, mi API me devuelve -100
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data) 
  if (dataApi>0) prepararJsonParaDevolver(props.jsonPeriodos)        
}

const ModificarPlanDePagos_Cabecera=(props)=>{   
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  
//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
 
  return () => {
    dataApi=null  
  }
  },[]
)

const ComponentePrincipalBody=()=>{
return ( 
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
<Form id="miFormularioRegistro">
{/* DIV para el encabezado: botones de GUARDAR Y SALIR*/}
<div id="divSalir" name="divSalir" style={{backgroundColor:'silver',paddingTop:'0', paddingBottom:"2%"}}>
  <Row style={{margin:'0',}}>
    <Col xs="6" style={{paddingTop:'1%',}}>
         <h3 id="tituloH1">EDITAR PLAN</h3>
    </Col>   
    <Col xs="6" style={{padding:'0',textAlign:'right'}}> 
      <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
        onClick={() => {
          props.ocultarModal_Plan('close',null)                        
        }}>
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
      </Button>            
    </Col>
  </Row>             
</div> {/* divSalir */}
<PantallaEncabezadoPlanDePagos 
  opcionRegistro_imc={'m'}
  rowRegistroClon={props.rowRegistroClon}
  numIntentos={valueDC.sistema.numeroDeReintentosPhp}
  milisegundosSleep={valueDC.sistema.milisegundosParaNuevoReintentoPhp}
  jsonPeriodos={props.jsonPeriodos}  
  />

<div id="divGuardar" name="divGuardar" style={{backgroundColor:'silver',paddingTop:'2%', paddingBottom:"2%"}}>
  <Row style={{margin:'0',}}>
    <Col xs="12" style={{margin:'0',paddingLeft:'1%',paddingRight:'1%',textAlign:'center'}}>
      <Button id="btnGuardar" name="btnGuardar" color="primary" solid="true" style={{width:"95%",color:'white'}} 
        onClick={async()=>{    
         await guardarRegistro(props) 
        //si  dataApi es null entonces me quedo en éste mismo form hasta que se guarde bien, o hasta quer se salga por el boton SALIR
        //en la variable dataApi, recibo desde mi API el mismo numero enviado. La ideal es obviamente recibir un numero de vuelta 
        if (dataApi==null) return       
        //Al tratar de guardar con nombre repetido, me quedo en éste mismo formulario (para que el usuario no pierda los datos de la pantalla)
        if (dataApi<0){
          //cuando está repe. No entra aqui ya que el catch del PHP devuelve NULL (si la hubiese hecho en precedimiento almacenado, ahí sí devilvería -100 pero no importa ya queda así)
          await mostrarSwalBotonAceptar("error","ATENCION","Parece que está tratando de usar un nombre de plan repetido")
          return
        }  
        props.ocultarModal_Plan("save",jsonDevolver)  
        }}>GUARDAR</Button>                   
    </Col>
  </Row>             
</div>

</Form> {/* miFormulario */}
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del const ModificarPlanDePagos_Cabecera

export default ModificarPlanDePagos_Cabecera
