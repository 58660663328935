//alert(JSON.stringify(dataApi,null,2))
// ==================== info =====================================
//este formulario, me permite agregar/eliminar planes a UN estudiante
//también puedo ver el detalle del plan (tipo estado de cuenta)
//al tratar de eliminar un plan, el sistema NO lo permite si ya tiene alguna cuota abonada o pagada
//Este form es llamado desde la tabela de clientes (osea alumnos) y asi poder asignarle o quitarle un PLAN. Tambien puede ser llamada desde facturacion

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,Input,Label} from 'reactstrap'
import DataTable  from 'react-data-table-component'
import {hayInternet,ejecutarFetchGenericoConSwal,mostrarSwalConfirmacionEliminarAnular,mostrarSwalBotonAceptar,mostrarSwalPos } from './lib_basica'
import MatricularAunAlumnoEnUnPlan from './MatricularAunAlumnoEnUnPlan'
import VerCxCdeUnPlanDeUnAlumno from './VerCxCdeUnPlanDeUnAlumno'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let conQueAccionDeboRegresar='close' //puede ser 'close' o 'save'
let dataApi=null //lo que recibo de mi Api (Null,Negativo, Num Positivo)

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE xxxxxxxx'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	},   
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
        minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },    
    cells: {
      style: {
        fontSize:'16px', //16px
        //el borde solo lo quiero ABAJO
        // top | right | bottom | left 
        //border-style: none solid dotted dashed;      
        borderStyle:"none none solid none", 
        borderColor:'silver',
        borderWidth:'thin',    
        //marginLeft:'20px', //funciona
        //paddingLeft: '80px', // no me funciona override the cell padding for data cells
        //paddingRight: '8px',
        //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
        //backgroundColor:'yellow', //
        //fontWeight:'bold',
        //height:'10px', /* bacan */
        //paddingTop:'0', /* no funciona */
        //paddingBottom:'0', /* no funciona */
        //margin:0, /* no me funciona */
      },
    },
}

//por fin. Aquí no es necesario recibir como parametro la variable row (NO SE POR QUE ? hay que averiguar )\
//la variable row parece que viene IMPLICITA
const filaCondicionalExterna=(rowRegistroState)=>[
{
    when: (row) => (rowRegistroState!=null && row.IDplan==rowRegistroState.IDplan),
    style: (row) =>({ 
      backgroundColor:valueDC?.sistema?.coloresFilaSeleccionadaSecundaria,
      color: 'white',
     }),    
},  
]

const GestionarPlanesDeUnAlumno=(props)=>{  
  const [rowRegistroState, set_rowRegistroState] = useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState] = useState('lista') //Arranco viendo obviamente la lista de cuotas. Luego el usuario puedee llamar a: crear SERIE, o eliminar o modificar una cuota
  const [registrosFullStateGPDUA, set_registrosFullStateGPDUA] = useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros (en el use effect si recibe NULL se devueove al componente llamador)
  const [modalAgregarPlan,set_modalAgregarPlan]=useState(false) //para abrir el modal de asignar nuevo plan
  const [modalVerCxC,set_modalVerCxC]=useState(false) //para abrir el modal de ver cxc del ALumno en UN plan. Tipo estado de cuenta
 
const refrescarData=async()=>{
  dataApi=null
  let data=new FormData()
  data.append('miSol','matriculapp_buscarPlanesDePagoConPeriodoDeUnAlumno') //me trae los nombres(matriculas) de los planes de UN alumno en especial (también me trae el periodo al que pertenece cada plan [trae 5 campos] y OJO: deben ser exactamente los mismos que deberían recibir al INSERTAR/MATRICULAR un nuevo plan al alumno para evitar refrescar )
  data.append('codAlu',props.IDr) //rojo del alumno
  data.append('orderBy','NombrePlan') 
  data.append('forma','Asc')   
  data.append('selectAIT','A') //Solo me interesan los Planes y Periodos activos
   
  //al recibir NULL, entonces éste form se cierra automaticamente
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullStateGPDUA(dataApi) 
}  

// ==============================================================
// ========= simulo el componentDidMount y ciclo de vida ========
//===============================================================
useEffect(()=>{
  //Es posible que el BOTON ASIGNAR UN PLAN deba estar VISIBLE/INVISIBLE
  if (props.botonAsignarPlanVisible==false) document.getElementById("btnAsignarPlan").style.visibility= 'hidden'
  if (props.botonRefreshPlanesVisible==false) document.getElementById("btnRefreshPlanes").style.visibility= 'hidden'  

  //Al arrancar muestro los planes activos del man
  refrescarData()

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    set_registrosFullStateGPDUA([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null)
  }
  },[]
)
  // *** cuando recibo NULL de mi API, entonces me devuelvo al componente llamador. puede ser al entrar por primera vez, o al darle refresh
  useEffect(()=>{  
  if (registrosFullStateGPDUA==null){
    props.ocultarModal_Gestionar(conQueAccionDeboRegresar,null) 
  }  
  },[registrosFullStateGPDUA]  
)
// =====================================================
// ============== fin de ciclo de vida =================
//======================================================

const miEstructura=[
{
  name: 'IDplan',  
  selector: row => row.IDplan,
  sortable: true,
  center: true,
  grow: 0.2,
  compact: true,
  omit: true, //oculta (siempre) la celda
},
{
  name: 'PLAN',
  selector: row => row.NombrePlan,
  sortable: false,
  compact: true,
  grow: 2, //es una medida relativa (1 es mi referencia)
},
{
  name: 'OpCrea_cxc',
  selector: row => row.OpCrea_cxc,
  sortable: false,
  compact: true,
  grow: 2, //es una medida relativa (1 es mi referencia)
  omit:true,
},
// =======================================================
// ============= ACCIONES ================================
// =======================================================
{
  name:'',
  sortable:false,
  center:true,
  compact:true,
  grow:1, //originalmente 1.6    
  //width:'20%',

  //esto funciona fino
  //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.IDr}</div>{row.IdPrincipal}</div>,
  //esto funciona fino
  //cell: (row) => <div style={{ fontWeight: 900 }}>{row.IDr}</div>,
  //<div style={{ display:'flex' }}>            
  cell: (row) => 
  <div style={{ display:'flex' }}>            
    {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
    {/* tambien da error al poner raised. se debe poner raised="true"  */}
    {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton VerCxC y Eliminar Plan */}
    <Button id="btnVerCxc" name="btnVerCxc" style={{ marginRight:'1px',}} raised = "false" primary='false' color="info" 
      onClick={ async (e) => {       
        conQueAccionDeboRegresar='save' 
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_modalVerCxC(true)        
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faEye}/>
    </Button>
    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      hidden={!props.botonEliminarPlanVisible}
      onClick={ async (e) => {            
        set_rowRegistroState(row)
        rowRegistroClon=row
        //Hay que validar si realmente está seguro de eliminar
        const miRegistroEnviar="Seguro desea eliminar el plan: \n" + rowRegistroClon.NombrePlan
        const miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miRegistroEnviar)
        if (miResp.isDismissed) return //alomejor se arrepintio
        conQueAccionDeboRegresar='save' //para regresar al llamador (osea la tabela de planes)
        await eliminarUnaMatricula(props.IDr,rowRegistroClon.IDplan) //allá elimino de la BDD y tambien de la tabela
        //si mi dataApi es negativo, significa que NO se pudo eliminar por tener abonos
        if (dataApi<0){
          mostrarSwalBotonAceptar("warning","NO SE PUEDE ELIMINAR","El plan del alumno tiene abonos")                
        }        
      }}>
      <FontAwesomeIcon size='lg' color="coral" icon={faTrashAlt} />
    </Button>        
  </div>, 
},   
// ================== FIN DE ACCIONES
{
  name:'PERIODO',
  selector:row => "(" + row.IDaliasPeriodo + ") "+ row.NombrePeriodo,
  sortable:true,
  compact:true,
  grow:2,
  //width:'20%',
  hide:'sm',  
}, 
]

const eliminarUnaMatricula=async(codAlu,codPlan)=>{
  //Se elimina de la tabla cxc_alumnos, las cuotas de UN alumno de UN plan especifico. 
  //Siempre y cuando ninguna de las cuotas del plan tenga abonos
  //si todo sale bien, recibo desde MySql el mismo numero de cuota enviado
  //Al no poder eliminar, por tener abonos entonces recibo -100 
  //Finalmente, elimino desde la tabela la cuota eliminada

  let data=new FormData()
  data.append('miSol','matriculapp_eliminarMatriculaDeAlumnoEnPlan')
  data.append('codAlu',codAlu)
  data.append('idPlan',codPlan)
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  if (dataApi != codPlan) return

  //Ahora elimino del JSON del estado y refresco la tabela
  let datosFullCopia=registrosFullStateGPDUA.filter(item=>item.IDplan != codPlan)
  //actualizo el estado para la tabela, y quito la franja azul
  set_registrosFullStateGPDUA(datosFullCopia)
  set_rowRegistroState(null)
  rowRegistroClon=null  

  //Paso 3)
  //Pongo una notificacion que se eliminó correctamente
  mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
}

const ComponentePrincipalBody=()=>{
return (
<div id="divMaster" name="divMaster" style={{ width: '100%', minHeight: '60vh', background: 'gray' }}>
  {/**** barra de titulo  */}
  <div id="divBarraTitulo" name="divBarraTitulo">
    <Row style={{ margin: '0' }}>
      <Col xs="10">
        <Label style={{ marginTop: '5px', fontWeight: 'bold', color: "pink" }}>{ "Planes de un Alumno"}</Label>
      </Col>
      <Col xs="2" style={{ textAlign: 'right', padding: '0' }}>
        <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
          onClick={() => {
            props.ocultarModal_Gestionar(conQueAccionDeboRegresar,null)                        
          }}>
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
        </Button>
      </Col>
    </Row>
  </div> {/* fin de divBarraTitulo */}
  {/* ***** TABELA ******/}
  <div id="divTabela" name="divTabela" style={{ marginTop: '2%', marginLeft: '1%', marginRight: '1%', borderStyle: "solid", borderColor: 'black', borderWidth: '2px', background: 'purple', width: '98%', marginBottom: '15px', }} >
    <DataTable
      //************ DATA TABLA PROPERTIES (basic) ***********/
      title={ `${props.RazonSocial} / ${props.IdPrincipal}` }
      columns={miEstructura}
      data={ registrosFullStateGPDUA ?? [] } 
      //conditionalRowStyles={filaCondicionalInterna} //Asi también funciona pefecto
      conditionalRowStyles={filaCondicionalExterna(rowRegistroState)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
      keyField='IDplan'  
      //este onRowClicked, me sirve solamente para que al tocar/clickear un fila, se ponga de fondo morado u otro color                  
      onRowClicked={(row) => {
        set_rowRegistroState(row)
        rowRegistroClon = row
      }}                  
      //striped  //NO PONER, Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
      highlightOnHover //SI, poner
      noDataComponent=""
      //************ DATA TABLA PROPERTIES (pagination) ***********/
      pagination
      //paginationServer //change de default pagination to work with server pagination
      paginationPerPage={15} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
      paginationComponentOptions={paginacionOpciones}
      //************ DATA TABLA PROPERTIES (header) ***********/
      //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
      //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
      customStyles={miEstilacho} /* redefino algun estilo */ 
    />{/* fin de DataTable */}                   
  </div> {/* fin de divTabela */}

  {/* botones de ASIGNAR y REFRESH */}
  <div id="divMenuInf" name="divMenuInf" style={{backgroundColor:'silver',paddingTop:'1%', paddingBottom:"1%"}}>
    <Row style={{margin:'0',}}>
      <Col xs="6" style={{margin:'0',padding:'0',textAlign:'center'}}>
        <Button id="btnAsignarPlan" name="btnAsignarPlan" color="primary" solid="true" style={{width:"80%", marginRight:'3%',color:'white'}} 
          onClick={async()=>{    
            set_rowRegistroState(null)
            rowRegistroClon=null                    
            conQueAccionDeboRegresar='save' 
            set_modalAgregarPlan(true)
          }}>ASIGNAR UN PLAN
        </Button>
      </Col>
      <Col xs="6" style={{margin:'0',padding:'0',textAlign:'center'}}>  
        <Button id="btnRefreshPlanes" name="btnRefreshPlanes" color="primary" solid="true"  style={{width:"80%",color:'white'}} 
          onClick= { async() => { 
            set_rowRegistroState(null)
            rowRegistroClon=null                    
            await refrescarData()                             
            }}>REFRESH
        </Button>
      </Col>            
    </Row>             
  </div> {/* fin divMenuInf */}

  {/* *********** MODAL PARA ASIGNAR UN NUEVO PLAN (osea MATRICULAR EN PLAN) **** */}
  <Modal style={{ backgroundColor: 'blue', }} size={'md'} isOpen={modalAgregarPlan}>
    <MatricularAunAlumnoEnUnPlan
      ocultarModal_Matricular={(accion, jsMatriculasAll) => {
        set_modalAgregarPlan(false)
        //accion puede ser: close/save
        if (accion=='close') return
        //save
        if (accion=='save') {
          //muestro todos los planes del man. osea las viejas + la nueva. Asi me ahorro tener que leer de nuevo la BDD
          set_registrosFullStateGPDUA(jsMatriculasAll)                      
        }
      }}
      matriculasUsadas={registrosFullStateGPDUA}
      IDr={props.IDr} //Le mando el codigo Rojo del Alumno
    />
  </Modal>

  {/* *********** MODAL PARA VER LAS CxC de UN plan de UN alumno  **** */}
  <Modal style={{ backgroundColor: 'blue', }} size={'lg'} isOpen={modalVerCxC}>
    <VerCxCdeUnPlanDeUnAlumno
      ocultarModal_VerCxC={(accion) => {
        set_modalVerCxC(false)
        //accion solo es close
        if (accion=='close') return
      }}
      IDr_alu={props.IDr} //codigo rojo del man
      RazonSocial_alu={props.RazonSocial}
      IdPrincipal_alu={props.IdPrincipal}
      IDplan={rowRegistroClon?.IDplan}  
      NombrePlan={rowRegistroClon?.NombrePlan}
    />
  </Modal>              
</div> //divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value
        return ComponentePrincipalBody()
      }}
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState == 'lista') return ComponentePrincipal()

} // del const GestionarPlanesDeUnAlumno

export default GestionarPlanesDeUnAlumno