//alert(JSON.stringify(rowRegistroState,null,2))
//Este Form. me muestra los morosos a una frecha especifica
//y se muestra un otón,par enviar un AVISO DE COBRO masivo
import React, {useState,useEffect} from 'react'
//import XLSX from 'xlsx'
import {Row,Col,Modal,Button,ButtonGroup, Input,} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, {ordenarArrayPorCampoTexto,ejecutarFetchGenericoConSwal,sleepPepe,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
import FormularioCorreo from './FormularioCorreo'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLightbulb,faPencilAlt,faGlasses,faRegistered,faRunning,faCamera,faDoorOpen,faBroom,faBookReader,faBookOpen, faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed, faSearchDollar } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let correoEnviadoCorrectamenteSN=null //para saber si se pudo enviar el correo
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let fechaCorte=null //Aqui va la fecha de corte para ver los morosos

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'LISTA DE CLIENTES'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      //paddingLeft: '50px', //estaba '50px', no me funciona, override the cell padding for head cells
      //paddingRight: '8px', //estaba '8px'
      background: 'hotpink', //ok hotpink
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    }, 
  },  
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',       
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */      
    },
  },
}

//por fin. Aquí no es necesario recibir como parametro la varibale row (NO SE POR QUE ? hay que averiguar )
const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.IDr != filaClonada?.IDr ),
    style: row => ({ 
      backgroundColor:row.Activo=="1"  ? 'white' : valueDC.sistema.coloresItemInactivo,
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (row.IDr == filaClonada?.IDr),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

const TabelaClientesMorosos=(props)=>{ 
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela  
  const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar  
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
  const [modalIncModRegistroState,set_modalIncModRegistroState]=useState(false) //para abrir el modal de incluir/modificar un registro        
  const [modalCorreoState,set_modalCorreoState]=useState(false) //para abrir el modal de enviar correo
  const [modalPlanesState,set_modalPlanesState]=useState(false) //para abrir el modal de Gestionar planes para UN man
  const [modalFacturar,set_modalFacturar]=useState(false) //para abrir el modal de Facturar a un man

const miEstructura=[  
  {  
      name:'id',
      selector:row => row.IDr,      
      sortable:true,
      center:true,
      grow:0.2,
      compact:true, //padding 0
      //width:'50%',
      omit:true, //oculta (siempre) la celda
  },
  {
      name:'PRINCIPAL',
      selector:row => row.IdPrincipal,      
      sortable:true,
      compact:true,
      grow:1, //es una medida relativa (1 es mi referencia)
      //width:'13%',
      //style: {backgroundColor:'cyan'},
  },
  {
      name:'NOMBRE',
      selector:row => row.RazonSocial,
      sortable:true,
      compact:true,
      grow:2,
  },     
  {
    name:'SALDO',
    //selector:row => (parseFloat(row.SaldoConIva_cxc)).toFixed(2),
    selector:row => row.SaldoConIva_cxc,
    sortable:true,
    compact:true,
    center:true,
    grow:1,
    //width:'20%',
  },   
  {
    name:'COMERCIAL',
    selector:row => row.NombreComercial,
    sortable:true,
    compact:true,
    center:true,    
    grow:1,
  },      
  {
    name:'CLASE',
    selector:row => row.Clase,
    sortable:true,
    compact:true,
    grow:1,
    //width:'20%',
  },         
  {
    name:'TELF',
    selector:row => row.TlfCel,
    sortable:true,
    compact:true,
    grow:1,
    hide:'sm',
  },    
  {
    name:'CORREO',
    selector:row => row.Email,
    sortable:true,
    compact:true,
    grow:1.5,
    hide:'sm',  
}, 
]

const refrescarData=async()=>{
  let data=new FormData()
  data.append('miSol','morosos_buscarMorososAl')
  data.append('dia',fechaCorte.getDate()) //txtFechaPrimeraCuota.getDate()
  data.append('mes',fechaCorte.getMonth()+1) //ojo: siempre así txtFechaPrimeraCuota.getMonth()+1
  data.append('ano',fechaCorte.getFullYear()) //txtFechaPrimeraCuota.getFullYear()
  data.append('selectAIT','A') //Quiero solo los activos
  data.append('orderBy','RazonSocial')
  data.append('forma','Asc')
  
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(dataApi)   
}

const gestionarEnviarAvisoDeCobro=async()=>{
  //paso 1=> Debo seleccionar solo los visibles y que al mismo tiempo tengan correo
  let copiaDelEstado=[...registrosFullState]
  let soloVisibles=copiaDelEstado.filter(item=>{
    if (item.VisibleSN=='S' && item.Email.trim().length>0 )  
      return item      
    })
  await mostrarSwalBotonAceptar("info","MOROSOS",`Se tomará como base, los ${soloVisibles.length} alumnos visibles`) 
  if (soloVisibles.length==0) return

  let data=new FormData()
  data.append('miSol','correoelectronico_enviarAvisoDeCobroMorosos')
  data.append('miContext', JSON.stringify(valueDC) ) 
  data.append('miLista',JSON.stringify(soloVisibles)) //Le mando el JSON de todos los morosos segun el filtro del usuario (visibleSN='S')
  data.append('miFechaCorteTexto',props.dia + "/" + props.mes + "/" + props.ano ) //Le mando por ejemplo 15/1/2024

  //en dataApi, recibo un numero entero, el cuál indica a cuantos alumnos se le mandó el correo
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  await mostrarSwalBotonAceptar("info","LISTO",`Se enviaron ${dataApi} correos`)          
}

const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un Cliente")          
    miDevolver=false
  }
  return miDevolver
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  fechaCorte=new Date(props.ano+"/"+props.mes+"/"+props.dia)
  rowRegistroClon=null   
  refrescarData()
  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    rowRegistroClon=null 
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }  
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

const filtrarElementos=(cadenaLoca)=>{
  //      item.BlockDeNotas.toString().toLocaleLowerCase().includes(cadenaLoca)
  //ojo: obligatorio convertirla en minuscula para hacer la busqueda
  cadenaLoca=cadenaLoca.toLocaleLowerCase()  
  let filtradosTmp=registrosFullState.map(item=>{
    if (
      item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Clase.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Ciudad.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Email.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item      
  })
  set_registrosFullState(filtradosTmp)
}  

const ComponentePrincipalBody=()=>{  
return(
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 
   <div id="divSubMaster" name="divSubMaster">
   {/******* Boton superior de OPCIONES (utilidades) ************/}
   <Row style={{margin:'0',paddingLeft:'0px'}} >     
    <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
      toggle= {()=>set_dropUtilidadesState(!dropUtilidadesState)}>
        <DropdownToggle caret color="primary">
          Opciones
        </DropdownToggle>   
        <DropdownMenu style={{backgroundColor:'cyan',}} >   
          <DropdownItem onClick={ () => alert(JSON.stringify(registrosFullState,null,2) )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de datos full</DropdownItem>                
          <DropdownItem onClick={ () => {
              alert(JSON.stringify(rowRegistroClon,null,2))
            }}
            ><FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de la fila seleccionada
          </DropdownItem>            
          <DropdownItem divider />
          <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
        </DropdownMenu>
      </Dropdown>
    </Row>

    {/******* Menu superior, para telefono vertical: solo 4 botones ************  */}
    <div id="divMenuSuperior" name="divMenuSuperior" > 
      <Row style={{margin:'0px',}} >
        <Col>
          <ButtonGroup>
            <Button id="btnNotificacion" name="btnNotificacion" className="btnPpal btnBordebtnPpal"  
              onClick = { async() => { 
                set_idMasState(0)
                gestionarEnviarAvisoDeCobro()
              }}> 
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faEnvelope} />
              </span>
              <span><br/>AvisoCobro</span>
            </Button>
            <Button id="btnEnviarEmail" name="btnEnviarEmail" className="btnPpal btnBordebtnPpal"  
              onClick = { () => {               
                if (!hayFilaSeleccionada()) return
                set_idMasState(0)
                if (rowRegistroClon.Email.trim().length==0){
                  mostrarSwalBotonAceptar("warning","NO SE PUEDE ENVIAR","El cliente NO tiene dirección de correo")
                  return
                }
                set_modalCorreoState(true)
                }} 
                >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faEnvelope} />
              </span>
              <span><br/>Enviar</span>
            </Button> 
            <Button id="btnEdoCta" name="btnEdoCta" className="btnPpal btnBordebtnPpal btnOcultoPeq"   
              onClick = { () => { 
                if (!hayFilaSeleccionada()) return
                set_idMasState(0)
                //set_modalPlanesState(true)
                }} >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faBookOpen} />
              </span>
              <span><br/>Edo Cta</span>
            </Button>        
            <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
              onClick={async()=>{ 
                set_textoBuscarState('')
                //pongo a 0 los codigos del estado para que se quite la franja AZUL
                set_rowRegistroState(null)
                rowRegistroClon=null
                set_idMasState(0)
                refrescarData()
              }}>  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /> 
              </span>
              <span><br/>Refresh</span>          
            </Button>
            <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"   
              onClick = { () => { 
                props.activarMenu()
              }} >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /> 
              </span>
              <span><br/>Salir</span>          
            </Button>
          </ButtonGroup>
        </Col>    
      </Row>
    </div> {/* divMenuSuperior */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input
          className = "textoBusquedaClientes"
          type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda"
          value={textoBuscarState}
          onChange={()=>{
            set_textoBuscarState( document.getElementById("inputBusqueda").value )
            filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
          }}/>
        <Button  
          id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
          onClick={()=>{
            set_textoBuscarState('')
            set_rowRegistroState(null)
            rowRegistroClon=null
            set_idMasState(0)          
            filtrarElementos('') 
          }}> 
          <FontAwesomeIcon color="paleGreen" icon={faBroom}/>
        </Button>        
    </div> { /* divBuscador1 */ }

   </div> { /* divSubMaster */ }

    { /* inicio del DIV para la tabela */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title={'Lista de Morosos al '+ props.dia + "/" + props.mes + "/" + props.ano }
    columns={miEstructura}
    data={registrosFullState?.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
    keyField ='IDr' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
    onRowClicked={(row) => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)      
    }}
    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //    <h1><span>Nada por aquí</span></h1>
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //'60vh'  
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //falta: rvesiar esta parte, me parece interesante ya que debe arrancar ordenando por DESCRIPCION
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    //paginationPerPage={10} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationPerPage={ valueDC.sistema.paginadoParaClientes }  
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

    {/* ************************* MODAL PARA ENVIAR UN CORREO INDIVIDUAL *************** */}    
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalCorreoState } >
      <FormularioCorreo 
        cerrarModalCorreo={async(accion,miAsunto,miMensaje)=>{
          set_modalCorreoState(false)
          //accion puede ser: close/send
          if (accion=='close') return
          if (accion=='send') {
            correoEnviadoCorrectamenteSN=await enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal('',rowRegistroClon.Email,'N','',valueDC,miAsunto,miMensaje)
          }
        }}
        destinoNombre={rowRegistroClon==null ? "" : rowRegistroClon.RazonSocial}
        destinoEmail={rowRegistroClon==null ? "" : rowRegistroClon.Email}
        asuntoDefault={""}
        mensajeDefault={""}
      />
    </Modal>

</div> //Div principal DivMaster
) //del return interno
}

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} // de TabelaClientesMorosos

export default TabelaClientesMorosos