//***************************** importante *****************/
//me permite crear un plan de pagos (En este form se crea la cebercera y si todo sale bien se llama a otro MODAL para hacer la serie/detalle de pagos)
//El mismo usuario, debe revisar previamente de forma manual si está repetido el Nombre de Plan
//Pero en caso que a la fuerza, esté metiendo un nombre repetido, el SQL me devuelve -100

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input, Form} from 'reactstrap'
import buscarPhpPath,{ejecutarFetchGenericoConSwal,convertirUnTextBoxEnEntero,convertirUnTextBoxEnFloat,sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,verificarSiTextoEsPurosDigitos, aceptarSoloEnteros } from './lib_basica'
import {v4 as uuid} from 'uuid'
import PantallaEncabezadoPlanDePagos, {validarPantallaPlanDePagos_Cabecera} from './PantallaPlanDePagos_Cabecera'
import ModificarPlanDePagos_Detalle from './ModificarPlanDePagos_Detalle'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let codigoRojoDevueltoPorSql=null //el sql me devuelve un autonumerico.  
let jsonDevolver=null //este objeto JSON lo devuelvo al llamador. Por ahora por comodidad solo le devuelvo un JSON con pocas columnas
let miTextoLoco="SIGUIENTE >>"
let miRandom=null //ID universal pra mandarlo a MYSQL

const prepararJsonParaDevolver=()=>{
  //Por comodidad, devuelvo pocos valores. Aunque realmente en la tabela no se hace mucho con este JSON ya que allá es obligatorio refrescar Fisicamente desde la BDD
  //En la tabela estan dos tablas relacionadas y son muchas columnas que se tendría que ir comparando
  //De éste jsonDevolver, en la tabela solo se aprovecha el campo NombrePlan para mostrar una ventanita diciendo:  EL PLAN XXXXX SE CREÓ CORRECTAMENTE
  jsonDevolver={}
  jsonDevolver.IDplan=codigoRojoDevueltoPorSql
  jsonDevolver.RandomPlan= miRandom
  jsonDevolver.IDperiodoPlan=document.getElementById("comboPeriodo").value //me da el codigo del periodo
  let comboLoco = document.getElementById("comboPeriodo")
  jsonDevolver.NombrePeriodo=comboLoco.options[comboLoco.selectedIndex].text 
  jsonDevolver.NombrePlan=document.getElementById("txtNombrePlan").value.trim() 
  jsonDevolver.GravaIvaPlan=(document.getElementById("checkGrava").checked) ? "1": "0" 
  jsonDevolver.TipoPlan='A' //Academico, valor por defecto
  jsonDevolver.ActivoPlan='1' //True
  jsonDevolver.OpCreaPlan=valueDC.usuario.LoginUsu
}

const guardarRegistro=async()=>{  
  codigoRojoDevueltoPorSql=null
  jsonDevolver=null
  miRandom=uuid() //ID universal  
  
  if (await validarPantallaPlanDePagos_Cabecera()==false ) return  

  let data=new FormData(document.getElementById("miFormularioRegistro"))
  data.append('miSol','planPago_nuevoPlanDePago_Cabecera')
  data.append('miRandom',miRandom)  
  data.append('usuario',valueDC.usuario.LoginUsu) //solo tiene utilidad/sentido al momento de NUEVO registro
 
  // Desde mi API, puede venir null, o el numero rojo Autogenerado pr MysQl, o negativo (pero me interesa solamente un numero positivo)
  //En caso que se esté metiendo un nombre repetido, el SQL me devuelve -100
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  if (miRecibido != null)
  {
    codigoRojoDevueltoPorSql=miRecibido    
    if (codigoRojoDevueltoPorSql>0) prepararJsonParaDevolver()    
  }  
}

const IncluirPlanDePagos_Cabecera=(props)=>{   
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  const [modalModificarDetallePlan,set_modalModificarDetallePlan]=useState(false) //para abrir el modal de modificar el plan (pero solo el detalle, osea crear la serie de cuotas del nuevo plan)              

//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  //Grava o no grava segun las preferencias del sistema
  //document.getElementById("checkGrava").checked=(valueDC.sistema.planesDePagoNuevoGravaIva=="Si") ? true : false  
  //pongo el foco
  //document.getElementById("txtNombrePlan").setAttribute("autofocus","autofocus") 
  //document.getElementById("txtNombrePlan").focus() //no funciona=> revisar

    //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    codigoRojoDevueltoPorSql=null  
  }
  },[]
)

const ComponentePrincipalBody=()=>{
return (  
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>

<Form id="miFormularioRegistro">
{/* DIV para el encabezado: botones de GUARDAR Y SALIR*/}
<div id="divSalir" name="divSalir" style={{backgroundColor:'silver',paddingTop:'0', paddingBottom:"2%"}}>
  <Row style={{margin:'0',}}>
    <Col xs="6" style={{paddingTop:'1%',}}>
         <h3 id="tituloH1">NUEVO PLAN</h3>
    </Col>   
    <Col xs="6" style={{padding:'0',textAlign:'right'}}> 
      <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
        onClick={() => {
          props.ocultarModal_Plan('close',null)                        
        }}>
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
      </Button>
    </Col>
  </Row>             
</div> {/* divSalir */}

<PantallaEncabezadoPlanDePagos 
  opcionRegistro_imc={'i'}
  gravaIva={valueDC.sistema.planesDePagoNuevoGravaIva=="Si" ? true : false }  //Grava inicial
  numIntentos={valueDC.sistema.numeroDeReintentosPhp}
  milisegundosSleep={valueDC.sistema.milisegundosParaNuevoReintentoPhp}
  jsonPeriodos={props.jsonPeriodos}  
  />

<div id="divGuardar" name="divGuardar" style={{backgroundColor:'silver',paddingTop:'2%', paddingBottom:"2%"}}>
  <Row style={{margin:'0',}}>
    <Col xs="12" style={{margin:'0',paddingLeft:'1%',paddingRight:'1%',textAlign:'center'}}>
      <Button id="btnGuardar" name="btnGuardar" color="primary" solid="true" style={{width:"95%",color:'white'}} 
        onClick={async()=>{    
         await guardarRegistro() 
        //si  codigoRojoDevueltoPorSql es null entonces me quedo en éste mismo form
        if (codigoRojoDevueltoPorSql==null) return       
        //Al tratar de meter un repetido, me quedo en éste mismo formulario (pa que el usuario no pierda los datos de la pantalla)
        if (codigoRojoDevueltoPorSql<0){
          await mostrarSwalBotonAceptar("error","ATENCION","Parece que está tratando de meter un plan repetido")
          return
        }  
        //rumbo normal (ahora debo llamar a un MODAL para crear la serie.....)
        set_modalModificarDetallePlan(true)
        }}>{miTextoLoco}</Button>                   
    </Col>
  </Row>             
</div>

{/************** MODAL PARA EDITAR EL DETALLE DE UN PLAN (osea las cuotas) ****************/}   
  <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalModificarDetallePlan } >
    <ModificarPlanDePagos_Detalle 
      ocultarModal_Plan={async(accion)=>{            
        //accion puede ser: close/save
        set_modalModificarDetallePlan(false)      
        //Sin imporar como venga, igalmente me salgo de éste form y regreso a la tabela
        props.ocultarModal_Plan('save',jsonDevolver) //con esto me regreso a la tabela
        /*
        if (accion=='close') return
        //al regresar del modal con save. No hace falta nada (no me importa si hizo algo en ModificarPlan)
        if (accion=='save') return
        */
      }}           
      opcionRegistro_imc = {'i'} //Al Modal de detalle me voy con 'i' para que allá no haga una busqueda de las cuotas que tiene el plan (obviamente no es necesario, ya que es NUEVO). Se usaría 'm' cuando el modal sea llamado desde Modificar desde la tabela.
      IDplan= { jsonDevolver?.IDplan }
      NombrePlan={ jsonDevolver?.NombrePlan }  
      GravaIvaPlan={ jsonDevolver?.GravaIvaPlan }  
      />
  </Modal>  
</Form> 

</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del const IncluirPlanDePagos_Cabecera

export default IncluirPlanDePagos_Cabecera
