//alert(JSON.stringify(jsonNegra,null,2)) 
//en las props de este form, recibo codigo de la cuota, descripcion de la cuota, fechacuota, montocuota, (normalmente 4 parametros)
// **** Formulario para MODIFICAR UNA cuota especifica. Para ello se ejecuta en un SP de MySql
// y me devuelve TODAS las cuotas del plan al que pertence la cuota.
// Esto es con la idea de que el usuario no tenga que recargar los datos cuando regrese al llamador
// Si todo sale bien, recibo desde PHP un JSON con la cuota modificada + las cuotas companeras del mismo plan
// Es decir desde mi API recibo el mismo recordSet como si estubiese haciendo una serie (y debe ser así para mentener la compatibilididad en el llamador)
// al haber un error en PHP/Mysql entonces recibo aquí un array vacio [] (o un null). Nunca un numero negativo ya que no puedo hacer eso
// Al haber un error en este form. No se sale, se queda aquí mismo para que el usuario no pierda los datos de la pantalla
// Cuando de mi API viene todo bien, entonces éste form se cierra automáticamente
// es posible (está permitido que un mimso plan tenga VARIAS CUOTAS EXACTAMENTE IGUALES. eso no importa)

import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import {ejecutarFetchGenericoConSwal,aceptarSoloEnteros2023,aceptarDecimales2023,convierteObjetoFechaDeJSenTexto,devolverObjetoFechaApartirDeUnTexto,reemplazarPuntoPorComa } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio

//Variables globales de este archivo
let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let serieGeneradaGlobal=null //Aqui guardo un JSON con la cuota editada por mi API + las cuotas hermana. En definitiva, esto es lo que devuelvo al llamador

const validarPantallaEdicion=async()=>{
  //Obviamente valído la pantalla,

  let miTxt=null //de uso común para ir guardando el contenido de los campos

  // ===== Monto =====
  miTxt=document.getElementById("txtMontoCuota").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","El monto de la cuota, no puede estar vacío")
    return false
  }
  let miFloat=parseFloat(document.getElementById("txtMontoCuota").value)

  if (miFloat<=0){
    mostrarSwalBotonAceptar("error","ATENCION","El monto de la cuota, debe ser positivo")
    return false
  }  
  // ===== Fecha =====
  miTxt=document.getElementById("txtFechaPrimeraCuota").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","La fecha no puede estar vacía")
    return false
  }  
  // ===== descripcion  =====
  miTxt=document.getElementById("txtDescripcionCuota").value.trim() 
  if (miTxt.length==0){
    mostrarSwalBotonAceptar("error","ATENCION","la descripción no puede estar vacía")
    return false
  } 
  return true
}

const modificarLaCuota=async(codCuota,txtFechaPrimeraCuota)=>{  
  //Modifica la cuota y recibe la cuota editada + las hermanas del mismo plan
  //recibo la fecha del estado. y luego la convierto en 3 partes ya que es un problema lo de la fecha

  let data=new FormData( document.getElementById("miFormularioSerie") )
  data.append('miSol','planPago_modificarUnaCuotaDeUnPlan')
  data.append('codCuota',codCuota)
  data.append('dia',txtFechaPrimeraCuota.getDate())
  data.append('mes',txtFechaPrimeraCuota.getMonth()+1) //ojo: siempre así
  data.append('ano',txtFechaPrimeraCuota.getFullYear())
 
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  serieGeneradaGlobal=miRecibido
}

const ModificarUnaCuotaDeUnPlan=(props)=>{    
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  const [tabActivoState,set_tabActivoState]=useState('1') // Solo hay buna pestana de todos modos
  const [txtMontoCuota,set_txtMontoCuota]=useState('') 
  const [txtFechaPrimeraCuota,set_txtFechaPrimeraCuota]=useState(null) //arranca sin fecha para obligarlo a que la ponga
  const [txtDescripcionCuota,set_txtDescripcionCuota]=useState('')  

//Solo hay una pestana
//La descripon de la cuota Maximo 30 letras 

const TabSerie=()=>{   
  return (
    <div id="divTabSerie" name="divTabSerie">
      {/* para Monto */}
      <Row style={{margin:'0'}}> 
        <Col>
          <div style={{display:'flex',width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
            <Label for="txtMontoCuota" style={{marginRight:'4%'}}>Monto $</Label>
            <Input name="txtMontoCuota" id="txtMontoCuota" autoComplete='off' autoFocus style={{width:'25%'}} value={txtMontoCuota} 
              onChange={(e)=>set_txtMontoCuota(e.target.value)}
              onKeyDown={(e)=>aceptarDecimales2023(e)} 
            /> 
          </div>   
        </Col>
      </Row>
      {/* para fecha  */}
      <Row style={{margin:'0'}}> 
        <Col>
          <div style={{display:'flex',width:'96%',marginTop:'4%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>           
              <Label for="txtFechaPrimeraCuota" style={{marginRight:'4%'}}>Fecha</Label>
              <DatePicker autoComplete='off' className="anchoDatePickerGenerico" id="txtFechaPrimeraCuota" name="txtFechaPrimeraCuota" locale='es' dateFormat="dd/MM/yyyy"
                selected={ txtFechaPrimeraCuota }          
                onChange= {(datePar)=>set_txtFechaPrimeraCuota(datePar) }
              /> 
          </div>
        </Col>
      </Row>
      {/* descripción sugerida Maximo: 20 */}
      <Row style={{margin:'0'}}> 
        <Col> 
          <div style={{display:'flex',width:'96%',marginTop:'4%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
            <Label for="txtDescripcionCuota" style={{marginRight:'2%'}}>Descripción</Label>
            <Input maxLength="30" name="txtDescripcionCuota" id="txtDescripcionCuota" autoComplete='off' style={{width:'75%',}} value={txtDescripcionCuota} 
              onChange={(e)=>set_txtDescripcionCuota(e.target.value)}               
            />
          </div>                   
        </Col>
      </Row>
    </div>
  )}
      
//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  //alimento los valores originales
  set_txtMontoCuota(reemplazarPuntoPorComa(props.monto))
  //desde las props viene la fecha yyyy/mm/dd
  set_txtFechaPrimeraCuota(devolverObjetoFechaApartirDeUnTexto(props.fecha)) 
  set_txtDescripcionCuota(props.descripcion) 
  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    //por ahora vacío
  }
  },[]
)

const ComponentePrincipalBody=()=>{
return(    
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
  <Form id="miFormularioSerie">
    {/**** barra de titulo  */}
      <div id="divBarraTitulo" name="divBarraTitulo">
        <Row style={{ margin: '0' }}>
          <Col xs="10">
            <Label style={{ marginTop: '5px', fontWeight: 'bold', color: "coral" }}>{"CUOTA=> " + props.descripcion }</Label>
          </Col>
          <Col xs="2" style={{ textAlign: 'right', padding: '0' }}>
            <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
              onClick={() => {
                props.ocultarModal_Modificar('close',null)
              } }>
              <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
            </Button>
          </Col>
        </Row>
      </div> {/* divBarraTitulo */}

    {/* aqui creo un tab de 1 pestanas */}   
    <ModalBody>
    <Nav tabs >
      <NavItem >
        <NavLink style={{ backgroundColor:'hotpink'}}
          active= {tabActivoState == '1'}
          onClick={() => { 
            if(tabActivoState=='1') return 
            set_tabActivoState('1')
            }}>
          MODIFICAR CUOTA
        </NavLink>
      </NavItem>
    </Nav>

    <TabContent activeTab={tabActivoState} style={{ backgroundColor:'pink', borderStyle:'solid', borderColor:'silver',borderWidth:'medium'  }}> 
      <TabPane tabId="1">        
        {TabSerie()}
      </TabPane>
    </TabContent>
    </ModalBody>

    {/* boton de GUARDAR */}
      <div id="divMenuInf" name="divMenuInf" style={{backgroundColor:'silver',paddingTop:'2%', paddingBottom:"2%"}}>
        <Row style={{margin:'0',}}>
          <Col xs="12" style={{margin:'0',padding:'0',textAlign:'center'}}>
            <Button id="btnGenerar" name="btnGenerar" color="success" solid="true" style={{width:"90%", marginRight:'3%',color:'white'}} 
              onClick={async ()=>{
                if (await validarPantallaEdicion()==false) return
                await modificarLaCuota(props.codCuota,txtFechaPrimeraCuota) //recibo un JSON con la cuota editada+las hermanas 
                //debo revisar que el JSON tenga datos
                //Cuando viene null, es porque evidentemente hubo error fisico
                if (serieGeneradaGlobal==null) return 
                //al venir vacio es porque hubo error en MySql pero el error fue atrapado en MySql
                if (serieGeneradaGlobal.length==0) {
                  mostrarSwalBotonAceptar("error","ATENCION","No se pudo modificar la cuota")
                  return                  
                }                 
                //rumbo ideal. Ya recibí la serie y me salgo
                props.ocultarModal_Modificar('save',serieGeneradaGlobal)                                              
              }}>GUARDAR CAMBIOS
            </Button>
          </Col>
        </Row>
      </div> {/* divMenuInf */}

  </Form> {/* miFormulario */}
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del const ModificarUnaCuotaDeUnPlan

export default ModificarUnaCuotaDeUnPlan