//***************************** importante *****************/
//Este form, me sirve para Incluir/Modificar/Consultar UN cliente (solo cuando es NUEVO aparece el combo de plan de pagos)
//Para nuevo/modificar=> se busca una lista actualizada de TODOS de los clientes para el censo (IDr e IdPrincipal) 
//el censo viene en Data.Censo (solo cuando sea Nuevo/Modificar)
//Cuando es Modificar/consultar, entonces en Data.RegistroBuscado vienen los datos del Cliente en cuestión
//Cuando es Modificar/consultar si Data.RegistroBuscado es NULL, significa que NO se encontró el Registro (y entonces doy una alerta y me regreso al llamador)
//Cuando es Nuevo, entonces en Data.RegistroBuscado viene null
//El censo me sirve solamente para dar una ALERTA cuando estoy escribiendo uan cedula repetida
//cuando es cliente de credito, se le pone siempre 30 dias de plazo y en limite se va a guardar 0.
//en la base de datos la columna [TipoVenta], puede ser [0:Solo Contado] [1:Contado y Credito]

import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import buscarPhpPath,{limpiarCombo,ejecutarFetchGenericoConSwal,sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,verificarSiTextoEsPurosDigitos, aceptarSoloEnteros } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio

let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let censo=null //Aqui tengo un array/censo de registros, para validar repeticiones. Solo requiero: {IDr, IdPrincipal, }
let registroBuscado=null //aqui voy a tener al registro que deseo modificar o consultar
let carreras=null //todas las carreras
let planes=null //todos los planes
let codigoRojoDevueltoPorSql=null //cuando es nuevo el sql me devuelve un autonumerico. [Cuando es editar, me devuelve el mismo rojo enviado]
let repetido=null //[true,false], para saber si se esta tratando de meter un ruc repetido
let jsonDevolver={} //este objeto lo devuelvo al llamador (bien sea para nuevo o modificar)

const IncModCliente=(props)=>{           
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('cliente') //por default: 'cliente'. Alomejor luego un componente para ver un listado de las posibles ciudades/provincias . etc
  const [tabActivoState,set_tabActivoState]=useState('1') //La pestana de datos basicos
  const [lecturaBDDsuperado,set_lecturaBDDsuperado]=useState(true) //Al arrancar el FORM, asumo que todo saldrá bien (al arrancar el FORM hay que leer en la BDD. ALomejor podría regrasar con null)
  //para la parte fija
  const [comboTipoId,set_comboTipoId]=useState('0405')
  const [txtCedulaState,set_txtCedulaState]=useState('')  
  const [txtNombreState,set_txtNombreState]=useState('')  
  //para la pestana 1 (datos basicos)
  const [txtNombreComState,set_txtNombreComState]=useState('')  
  const [txtClaseState,set_txtClaseState]=useState('')  
  const [txtEmailState,set_txtEmailState]=useState('')  
  const [txtDireccionState,set_txtDireccionState]=useState('')  
  const [txtCiudadState,set_txtCiudadState]=useState('')  
  const [txtTlfFijoState,set_txtTlfFijoState]=useState('')  
  const [txtTlfCelState,set_txtTlfCelState]=useState('')  
  const [txtTlfWhatState,set_txtTlfWhatState]=useState('')  
  //para la pestana 2 (datos extra)
  const [txtSecundarioState,set_txtSecundarioState]=useState('')  
  const [comboActivoState,set_comboActivoState]=useState(1)  //[0:false]  [1:true]
  const [comboPRState,set_comboPRState]=useState(0)  //[0:false]  [1:true]
  const [comboRetIvaState,set_comboRetIvaState]=useState(0)  //[0:false]  [1:true]
  const [comboRetRentaState,set_comboRetRentaState]=useState(0)  //[0:false]  [1:true]
  const [fechaEdoState,set_fechaEdoState]=useState(new Date()) //arranca con la fecha actual
  const [txtDireccionEnvioState,set_txtDireccionEnvioState]=useState('')  
  const [txtCiudadEnvioState,set_txtCiudadEnvioState]=useState('')  
  const [txtPersonaContactoState,set_txtPersonaContactoState]=useState('')  
  const [txtObservacionesState,set_txtObservacionesState]=useState('')  
  //Los combos de carrera y plande pagos, No tiene estado

const ParteFija=()=>{   
return (
  <div id="divParteFija" name="divParteFija">
    <Row style={{marginTop:'2%',marginBottom:'1%',marginLeft:'0',marginRight:'0',padding:'0'}}>
      <Col xs="5">
        <Input type="select" name="comboNacionalidad" id="comboNacionalidad" onChange={(e)=>set_comboTipoId(e.target.value)}>
          <option value="0405" selected={(comboTipoId=="0405") ? true : false}>Cédula/Ruc</option>
          <option value="06" selected={(comboTipoId=="06") ? true : false}>Pasaporte</option>
          <option value="07" disabled selected={(comboTipoId=="07") ? true : false}>Consumidor Final</option>
          <option value="08-1" selected={(comboTipoId=="08-1") ? true : false}>Id. del exterior (Persona)</option>
          <option value="08-2" selected={(comboTipoId=="08-2") ? true : false}>Id. del exterior (Sociedad)</option>
        </Input>
      </Col>
      <Col xs="7">        
        <Input  maxLength="20" placeholder="Cedula/Ruc/Pasaporte*"  name="txtCedula" id="txtCedula" value={txtCedulaState}
          onChange={(e)=> set_txtCedulaState(e.target.value)}
          onKeyUp={(e)=> buscarRepetidoEnCenso()}             
        />  
      </Col>
    </Row>    
    <Row style={{margin:'0',}}>
      <Col xs="12">
        <Input maxLength="100" placeholder="Nombre del Cliente*" name="txtNombre" id="txtNombre" value={txtNombreState}
            onChange={(e)=>set_txtNombreState(e.target.value) }                   
        />
      </Col>
    </Row>
  </div> //divParteFija
)}    
  
const  TabDatosBasicos=()=>{   
return (
  <div id="divDatosBasicos" name="divDatosBasicos">
    <div style={{width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
        <Label for="txtNombreCom">Nombre Comercial</Label>
        <Input maxLength="100" placeholder="Nombre Comercial" name="txtNombreCom" id="txtNombreCom" value={txtNombreComState} 
          onChange={(e)=>set_txtNombreComState(e.target.value)}               
        />
    </div>      
    {/* fila para el combo de la carrera */}
    <div style={{display:'flex',width:'96%',marginTop:'1%',marginLeft:'2%',marginRight:'2%'}}>
      <Label style={{paddingTop:'1%'}}>Carrera</Label>      
      <Input type="select" name="comboCarrera" id="comboCarrera" style={{marginLeft:'2%', width:'70%',marginRight:'2%',}}/>
      <Button id="btnEliminarCarrera" name="btnEliminarCarrera" style={{ marginRight:'0%', }} color="info" 
      onClick={ () => document.getElementById("comboCarrera").selectedIndex=-1 }>
      <FontAwesomeIcon color="yellow" icon={faTrashAlt}/>
    </Button>
    </div>
    {/* fila para la clase */}
    <div style={{display:'flex',width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Label style={{paddingTop:'1%'}}>Clase</Label>
      <Input maxLength="40" style={{marginLeft:'2%',width:'40%',}} placeholder="Clase" name="txtClase" id="txtClase" value={txtClaseState}  
          onChange={(e)=>set_txtClaseState(e.target.value)}          
      /> 
    </div>
    {/* fila para el combo de planes de pago */}
    <div id="divPlan" name="divPlan" style={{display:'flex',width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Label style={{paddingTop:'1%'}} >Plan $</Label>
      <Input type="select" name="comboPlan" id="comboPlan" style={{marginLeft:'2%', width:'72%',marginRight:'2%',}}/>
      <Button id="btnEliminarPlan" name="btnEliminarPlan" style={{ marginRight:'0%', }} color="info" 
      onClick={ () => document.getElementById("comboPlan").selectedIndex=-1 }>
      <FontAwesomeIcon color="yellow" icon={faTrashAlt}/>
    </Button>
    </div>
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="150" placeholder="Correo" name="txtEmail" id="txtEmail" value={txtEmailState} 
        onChange={(e)=>set_txtEmailState(e.target.value)}
      /> 
    </div>              
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="300" placeholder="Dirección" name="txtDireccion" id="txtDireccion" value={txtDireccionState} 
        onChange={(e)=>set_txtDireccionState(e.target.value)}
      />
    </div>          
    <div style={{display:'flex',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="50" style={{width:'40%',marginRight:'5%'}} placeholder="Ciudad" name="txtCiudad" id="txtCiudad" value={txtCiudadState} 
        onChange={(e)=>set_txtCiudadState(e.target.value)}
      />
      <Input maxLength="30" style={{width:'55%',}} placeholder="Tlf Fijo" name="txtTlfFijo" id="txtTlfFijo" value={txtTlfFijoState} 
        onChange={(e)=>set_txtTlfFijoState(e.target.value)}
      />
    </div>          
    <div style={{display:'flex',marginTop:'2%',marginLeft:'2%',marginRight:'2%',paddingBottom:'1%'}}>
      <Input maxLength="30" style={{width:'49%',marginRight:'2%'}} placeholder="Tlf Celular" name="txtTlfCel" id="txtTlfCel" value={txtTlfCelState} 
        onChange={(e)=>set_txtTlfCelState(e.target.value)}
      />
      <Input maxLength="30" style={{width:'49%'}} placeholder="Tlf WhatsApp" name="txtTlfWhat" id="txtTlfWhat" value={txtTlfWhatState} 
        onChange={(e)=>set_txtTlfWhatState(e.target.value)}  
      />
    </div>      
  </div>
)}
      
const TabDatosExtra=()=>{
return(
  <div id="divDatosExtra" name="divDatosExtra">
    <div style={{marginLeft:'2%',marginTop:'2%',marginBottom:'2%',width:'55%'}}>
      <Label for="txtSecundario">Secundario</Label>
      <Input maxLength="20" placeholder="Secundario" name="txtSecundario" id="txtSecundario" value={txtSecundarioState}
        onChange={(e)=>set_txtSecundarioState(e.target.value)}
      /> 
    </div>
    <center> 
      <Row style={{margin:'0%',marginBottom:'2%',}}>
        <Col xs="5">
          <Label for="comboActivo" style={{marginRight:'3%'}}>Activo</Label>
          <select name="comboActivo" id="comboActivo" disabled={(props.opcionRegistro_imc=="i") ? true : false} onChange={(e)=>set_comboActivoState(e.target.value)}>
            <option value="0" selected={(comboActivoState==0) ? true : false}>No</option>
            <option value="1" selected={(comboActivoState==1) ? true : false}>Si</option>             
          </select>
        </Col>
        <Col xs="7">
          <Label for="comboPr" style={{marginRight:'3%'}}>Parte Relacionada</Label>
          <select name="comboPr" id="comboPr" onChange={(e)=>set_comboPRState(e.target.value)}>
            <option value="0" selected={(comboPRState==0) ? true : false}>No</option>
            <option value="1" selected={(comboPRState==1) ? true : false}>Si</option>             
          </select>    
        </Col>
      </Row>
    </center> 
    <Row style = {{marginLeft:'2%',marginRight:'2%',marginBottom:'2%',paddingTop:'1%',paddingBottom:'3%',borderStyle: 'solid', borderWidth:'thin', borderColor:'gray',}}>
      <legend style = {{textAlign:'center'}}> Agente de Retención</legend>
      <Col style = {{textAlign:'center'}}>
        <Label for="comboRetIva" style={{marginRight:'3%'}}>IVA</Label>
        <select name="comboRetIva" id="comboRetIva" onChange={(e)=> set_comboRetIvaState(e.target.value)}>
          <option value="0" selected={(comboRetIvaState==0) ? true : false}>No</option>
          <option value="1" selected={(comboRetIvaState==1) ? true : false}>Si</option>
        </select>
      </Col>  
      <Col style = {{textAlign:'center'}}>
        <Label for="comboRetIslr" style={{marginRight:'3%'}}>RENTA</Label>
        <select name="comboRetIslr" id="comboRetIslr" onChange={(e)=> set_comboRetRentaState(e.target.value)}>
          <option value="0" selected={(comboRetRentaState==0) ? true : false}>No</option>
          <option value="1" selected={(comboRetRentaState==1) ? true : false}>Si</option> 
        </select>
      </Col>
    </Row>
    <Row style={{margin:"0%",}}>
      <Col style={{textAlign:'right',}}>
        <Label for="txtClienteDesde">Cliente desde</Label>
      </Col>      
      <Col>
        <DatePicker id="txtClienteDesde" name="txtClienteDesde" className="anchoDatePickerGenerico" locale='es' dateFormat="dd/MM/yyyy"
          selected={ fechaEdoState }          
          onChange= {(datePar)=>set_fechaEdoState(datePar) }
        />    
      </Col>
    </Row>
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="300" placeholder="Dirección de envio" name="txtDireccionEnvio" id="txtDireccionEnvio" value={txtDireccionEnvioState} 
        onChange={(e)=>set_txtDireccionEnvioState(e.target.value)}         
      />
    </div>           
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="50" placeholder="Ciudad de envio" name="txtCiudadEnvio" id="txtCiudadEnvio" value={txtCiudadEnvioState} 
        onChange={(e)=>set_txtCiudadEnvioState(e.target.value)}         
      /> 
    </div>              
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="40" placeholder="Persona Contacto" name="txtPersonaContacto" id="txtPersonaContacto" value={txtPersonaContactoState} 
        onChange={(e)=>set_txtPersonaContactoState(e.target.value)}         
      />
    </div>            
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="100" style={{marginBottom:'2%'}} placeholder="Observaciones" name="txtObservaciones" id="txtObservaciones" value={txtObservacionesState} 
        onChange={(e)=>set_txtObservacionesState(e.target.value)}
      /> 
    </div>          
  </div>
)}

const alimentarComboCarreraYplan=()=>{ 
  //Alimento DOS combos. el de Carreras y el de Planes
  //La carrera debe aparecer para todos los casos
  //Cuando es Nuevo, solo aparecen las carreras activas.
  //Cuando es Modificar, aparecen todas las carreras (activas e inactivas)
  //Los planes es un combo que se OCULTA cuando sea Modificar/Consultar
  //Cuando es Nuevo, se muestran solo los planes ACTIVOS
  //NUNCA se puede modificar el PLAN (obviamente porque no se ve)

  //======= Combo Carrera
  let miComboCarrera= document.getElementById("comboCarrera")
  // elimino los posibles items de una corrida anterior
  limpiarCombo(miComboCarrera)

  //Alimento con las carreras (cuando el cliente es Nuevo, solo se cargan las carreas activas. Caso contrario: todas)
  carreras.forEach( (carr) => {   
    if (props.opcionRegistro_imc=="i" && carr.ActivoCarrera=="0" ) return
    let miOption=document.createElement("option")
    miOption.value= carr.IDcarrera
    miOption.text=carr.NombreCarrera
    miComboCarrera.appendChild(miOption)        
  }
  )

  //======= Combo Planes de pago
  let miComboPlan= document.getElementById("comboPlan")
  // elimino los posibles items de una corrida anterior
  for (let i = miComboPlan.options.length; i >= 0; i--) {
    miComboPlan.remove(i);
  }  
  //Alimento con los planes activos de periodos activos (además éste combo es VISIBLE solo cuando es Incluir cliente)
  planes.forEach( (plan) => {   
    if (props.opcionRegistro_imc=="i" && (plan.ActivoPlan=="0" || plan.ActivoPeriodo=="0") ) return
    let miOption=document.createElement("option")
    miOption.value= plan.IDplan
    miOption.text=plan.NombrePlan
    miComboPlan.appendChild(miOption)        
  }  
)}

const validarPantallaComun=async()=>{
//arranco con la cedula, no se acepta vacio
let miTxt=document.getElementById("txtCedula").value.trim() 
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar la Identificación: Cédula/Ruc, Pasaporte...")
  return false
}
//Cuando sea Cedula/Ruc solo puede tener 10 o 13 numeros (solo digitos)
if (document.getElementById("comboNacionalidad").selectedIndex==0){
  //reviso que solo hayan numeros
  if (verificarSiTextoEsPurosDigitos(miTxt)==false){
    mostrarSwalBotonAceptar("error","ATENCION","La identificación solo debe contener numeros")
    return false
  }
  if (miTxt.length != 10 && miTxt.length != 13 ){
    mostrarSwalBotonAceptar("error","ATENCION","La identificación debe tener 10 o 13 digitos. \n Ahora tiene " + miTxt.length)
    return false
  }
  //al haber 13 digitos, reviso que termine en 001
  if (miTxt.length == 13 && miTxt.substring(10) != '001' ){
    mostrarSwalBotonAceptar("error","ATENCION","El Ruc debe terminar en 001")
    return false
  }
}
//alerto cuando el ruc este repetido
if (repetido==true){
  mostrarSwalBotonAceptar("error","ATENCION","La identificación del cliente está repetida")
  return false
}
//Nombre (razon social)
miTxt=document.getElementById("txtNombre").value.trim()     
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","El Nombre del cliente, no puede estar vacío")
  return false
}
//FechaDesde
miTxt=document.getElementById("txtClienteDesde").value.trim()     
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","El campo Cliente desde, no puede estar vacío")
  return false
}
return true
}

const validarPantallaParaClienteNuevo=()=>{
  //se valida solamente cuando el cliente es Nuevo (por ejemplo cuando la direccion sea obligatoria, la ciudad, correo, telefonos) 
  let txtLoco=null
  //reviso si el correo es obligatorio
  txtLoco=document.getElementById("txtEmail").value.trim()
  if (valueDC.sistema.paraClientesNuevosElCorreoEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El Correo no puede estar vacío")
    return false
  }
  //reviso si la direccion es obligatoria
  txtLoco=document.getElementById("txtDireccion").value.trim()
  if (valueDC.sistema.paraClientesNuevosLaDireccionEsObligatoria=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","La Dirección no puede estar vacía")
    return false
  }  
  //reviso si la ciudad es obligatoria
  txtLoco=document.getElementById("txtCiudad").value.trim()
  if (valueDC.sistema.paraClientesNuevosLaCiudadEsObligatoria=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","La Ciudad no puede estar vacía")
    return false
  }  
  //reviso si el telefono fijo, es obligatorio.
  txtLoco=document.getElementById("txtTlfFijo").value.trim()
  if (valueDC.sistema.paraClientesNuevosElTelefonoFijoEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El teléfono fijo no puede estar vacío")
    return false
  }  
  //reviso si el telefono celular, es obligatorio.
  txtLoco=document.getElementById("txtTlfCel").value.trim()
  if (valueDC.sistema.paraClientesNuevosElTelefonoCelularEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El teléfono celular no puede estar vacío")
    return false
  }    
  //reviso si el telefono whatsApp, es obligatorio.
  txtLoco=document.getElementById("txtTlfWhat").value.trim()
  if (valueDC.sistema.paraClientesNuevosElTelefonoWhatsAppEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El teléfono whatsApp no puede estar vacío")
    return false
  }    

return true
}

const prepararJsonParaDevolver=()=>{
  let tipoPrecio="COMUN" //pra bonanza siempre 'comun' (lo hago así para mantener la compatibilidad con otras versiones)

  jsonDevolver={}
  jsonDevolver.IDr=codigoRojoDevueltoPorSql
  jsonDevolver.IdPrincipal=document.getElementById("txtCedula").value.trim()
  jsonDevolver.IdSecundario=document.getElementById("txtSecundario").value.trim()
  jsonDevolver.RazonSocial=document.getElementById("txtNombre").value.trim()
  jsonDevolver.NombreComercial=document.getElementById("txtNombreCom").value.trim()
  jsonDevolver.PrecioUsualDef=tipoPrecio //"comun",1,2,3,4
  jsonDevolver.TipoVenta="1" //0=>contado solamente, 1=>contado y credito (Para bonanza siempre "1")
  jsonDevolver.TlfCel=document.getElementById("txtTlfCel").value.trim()
  jsonDevolver.Email=document.getElementById("txtEmail").value.trim()
  jsonDevolver.Clase=document.getElementById("txtClase").value.trim()
  jsonDevolver.Ciudad=document.getElementById("txtCiudad").value.trim()
  jsonDevolver.Activo=document.getElementById("comboActivo").value=="0" ? "No" : "Si"
  jsonDevolver.IDcarreraAlu=document.getElementById("comboCarrera").selectedIndex<0 ? "0" :  document.getElementById("comboCarrera").value.trim()
}

const guardarRegistro=async()=>{ 
codigoRojoDevueltoPorSql=null

if ( await validarPantallaComun()==false) return  
if ( props.opcionRegistro_imc=='i' && validarPantallaParaClienteNuevo()==false ) return   
 
let data=new FormData(document.getElementById("miFormularioRegistro"))
data.append('miSol','cli_guardar')
//Le paso la fecha en modo string (es la unica forma que pude resolver este problema)
data.append('fechaTMP',document.getElementById("txtClienteDesde").value)
data.append('usuario',valueDC.usuario.LoginUsu) //solo tiene utilidad/sentido al momento de NUEVO cliente
data.append('txtID',(props.opcionRegistro_imc=="i") ? 0 : props.registroIDr) //codigo rojo del registro a editar (cuando es NUEVO se va con cero)  
data.append('txtOpcion_imc',props.opcionRegistro_imc)
let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
if (dataApi==null) return

//guardo el rojo devuelto por mi api (tambien me puede llegar negativo si hay error como por ejemplo cedula duplicada)
codigoRojoDevueltoPorSql=dataApi
}        

const buscarRepetidoEnCenso=()=>{
repetido=false
let cedRuc=document.getElementById("txtCedula").value.trim().toLowerCase()
//reviso cuando es NUEVO 
if (props.opcionRegistro_imc=='i'){
  censo.forEach( (x) => {
    if (x.IdPrincipal.trim().toLowerCase()==cedRuc) repetido = true
  })
}
//reviso cuando es MODIFICAR
if (props.opcionRegistro_imc=='m'){
  censo.forEach( (x) => {
    if (x.IDr != props.registroIDr && x.IdPrincipal.trim().toLowerCase()==cedRuc) repetido = true
  })
}
//resultado  
if (repetido){
  document.getElementById("txtCedula").style.background='red'
  mostrarSwalBotonAceptar("error","ATENCION","La Identificación está repetida")
}
else{
  document.getElementById("txtCedula").style.background='white'
}

return repetido
}

const leerDesdeBDD_paso1=async()=>{
  //esta funcion se usa para todos los casos Incluir/Modificar/Consultar
  //a) cuando sea 'Modificar'o Consultar:
    //En Data.RegistroBuscado me trae los datos del man
    //si en Data.RegistroBuscado viene NULL significa que no se encontro al MAN (alomejor alguien la borró desde otra PC)
  //b) cuando sea 'Incluir':
    //En Data.RegistroBuscado obviamente viene null ya que no estamos buscando a nadie
  //c) Censo
    //Cuando sea Incluir/Modificar entonces en Data.Censo viene un array de IDr y IDprincipal para revisar repeticiones
    //Cuando sea consultar entonces Data.Censo viene vacío []. Nunca null

    let data=new FormData()
    data.append('miSol','cli_buscarClienteMasCensoOpcional')    
    data.append('IDr',props.opcionRegistro_imc=='i'? 0 : props.registroIDr) //Cuando es incluir, pués no necesito buscar ningun cliente. Pero en Consultar/modificar si.
    data.append('generarCensoSN',props.opcionRegistro_imc=='c' ? "N" : "S" ) //cuando es consultar, no hace falta el censo. Pero en incluir y modificar si es necesario el censo

    let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
    //console.log(dataApi)
    //al venir NULL, => entonces el formulario debe cerrarse automaticamente
    if (dataApi==null) set_lecturaBDDsuperado(false)
    if (dataApi==null) return   

    registroBuscado=dataApi.RegistroBuscado[0] //cuando es Incluir viene null. Cuando es Modificar o Consultar si viene null es porque el cliente buscado ya no existe (alomejor lo borraron desde otra pc)
    censo=[...dataApi.Censo] //cuando es Consultar viene vacío
    carreras=[...dataApi.Carreras]
    planes=[...dataApi.Planes]

    //Ahora reviso que realmente venga el cliente que deseo Modificar o Consultar. Alomejor alguien lo borro desde otra computadora    
    if ( (props.opcionRegistro_imc=="m" || props.opcionRegistro_imc=="c") && registroBuscado==null ){ 
      await mostrarSwalBotonAceptar("error","ATENCION","El cliente ya no existe...")            
      set_lecturaBDDsuperado(false)
      return
    }

    //Rumbo normal.... Al llegar aquí es porque todo va bien
    alimentarComboCarreraYplan()// Cargo las carreras y planes
    document.getElementById("comboCarrera").selectedIndex=-1
    document.getElementById("comboPlan").selectedIndex=-1

    if ( (props.opcionRegistro_imc=="m" || props.opcionRegistro_imc=="c") && registroBuscado!=null )  
      mostrarRegistro()        
}

const mostrarRegistro=()=>{
//tipos de identificacion: 04ruc, 05cedula,06pasaporte,07cons final,08 id del exterior

  // ======= Tipo de Identificacion, identificacion, razon social  ================
    // caso especial, cuando sea Id del exterior (08), se subdivide en 2 opciones. 08-1 Persona / 08-2 Sociedad
    if (registroBuscado.TipoIdentificacion=="08") set_comboTipoId("08-" + registroBuscado.TipoDeExtranjero)
    else set_comboTipoId(registroBuscado.TipoIdentificacion)
  set_txtCedulaState(registroBuscado.IdPrincipal)
  set_txtNombreState(registroBuscado.RazonSocial) 
  // al ser consumidor final, no se puede modificar la IDENTIFICACION, NI NOMBRE
  if (registroBuscado.TipoIdentificacion=="07"){          
    document.getElementById("comboNacionalidad").disabled = true
    document.getElementById("txtCedula").disabled = true
    document.getElementById("txtNombre").disabled = true
  }
  // ======================== Para la pestana BASICOS ========================
  set_txtNombreComState(registroBuscado.NombreComercial)
  set_txtClaseState(registroBuscado.Clase)
  set_txtEmailState(registroBuscado.Email)
  set_txtDireccionState(registroBuscado.Direccion)
  set_txtCiudadState(registroBuscado.Ciudad)
  set_txtTlfFijoState(registroBuscado.TlfFijo) 
  set_txtTlfCelState(registroBuscado.TlfCel)
  set_txtTlfWhatState(registroBuscado.TlfWhat)   
  //********************* Combo Carrera **********************/
  //Cuando es modificar/eliminar NO SE MUESTRA EL COMBO DE PLANES DE PAGO
  document.getElementById("comboCarrera").value= registroBuscado.IDcarreraAlu
  
  //======================== Pestana EXTRAS ========================
  set_txtSecundarioState(registroBuscado.IdSecundario)
  set_comboActivoState(registroBuscado.Activo)
  set_comboPRState(registroBuscado.ParteRelacionadaSN=="S" ? "1" : "0")
  set_comboRetIvaState(registroBuscado.AgenteRetIVA)
  set_comboRetRentaState(registroBuscado.AgenteRetISLR)
  set_txtDireccionEnvioState(registroBuscado.DireccionEnvio)
  set_txtCiudadEnvioState(registroBuscado.CiudadEnvio)
  set_txtPersonaContactoState(registroBuscado.PersonaContacto)
  set_txtObservacionesState(registroBuscado.Observaciones)
  //======================== FECHA ========================
  //la fecha viene asi desde MYSQL/JSON yyyy-mm-dd siempre 10 caracteres
  let miAno=registroBuscado.FechaDesde.substring(0,4)
  let miMes=registroBuscado.FechaDesde.substring(5,7)
  let miDia=registroBuscado.FechaDesde.substring(8)    
  //OJO: Al usar el metodo DATE con 3 string, el mes de enero es 0. OJO
  set_fechaEdoState(new Date(miAno,miMes-1,miDia))
}

const prepararValoresPorDefaultParaRegistroNuevo=()=>{
// ====================== valores por default, desde preferencias de valueDC

// c) Direccion y ciudad
set_txtDireccionState(valueDC.sistema.direccionPorDefectoParaClientesNuevos)
set_txtCiudadState(valueDC.sistema.ciudadPorDefectoParaClientesNuevos)
}                

//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  //caso 1: cuando se desea NUEVO registro
  if (props.opcionRegistro_imc=='i'){
    document.getElementById("tituloH1").innerText= 'NUEVO'  
    //En caso de tener Intencion de crear UN nuevo cliente desde ventas o equivalentes
    if (props.cedulaCandidata.trim().length>0) set_txtCedulaState(props.cedulaCandidata)
    prepararValoresPorDefaultParaRegistroNuevo()
  }

  //caso 2: cuando sea modificar (se oculta el div de Plan $)
  if (props.opcionRegistro_imc=='m'){
    document.getElementById("tituloH1").innerText= 'EDITAR'
    document.getElementById("divPlan").style.visibility= 'hidden'
  }     

  //caso 3: cuando se desea CONSULTAR. no muestro el boton guardar ni el div de Plan $
  if (props.opcionRegistro_imc=='c'){
    document.getElementById("tituloH1").innerText= 'CONSULTAR'
    document.getElementById("btnGuardar").style.visibility= 'hidden'
    document.getElementById("divPlan").style.visibility= 'hidden'
    document.getElementById("comboCarrera").disabled = true
    document.getElementById("btnEliminarCarrera").style.visibility= 'hidden'   
    //FALTA: no me desactiva al consultar
    //document.getElementById("divParteFija").disabled=true
  }
  
  //Para todos los casos, hay que leer en la BDD
  leerDesdeBDD_paso1() //Si todo sale bien entonces la variable de estado lecturaBDDsuperado true/false

  return () => {
    censo=null
    registroBuscado=null
    carreras=null
    planes=null
    codigoRojoDevueltoPorSql=null  
    repetido=null  
  }    
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al llamador
useEffect(()=>{  
  if (lecturaBDDsuperado==false){    
    props.ocultarModal_Cli('close',null) 
  }
  },[lecturaBDDsuperado]
)

const ComponentePrincipalBody=()=>{
return(    
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
<Form id="miFormularioRegistro">
{/* DIV para el encabezado: botones de GUARDAR Y SALIR*/}
<div id="divGuardarSalir" name="divGuardarSalir" style={{backgroundColor:'silver',paddingTop:'0', paddingBottom:"2%"}}>
  <Row style={{margin:'0'}}>
    <Col xs="4" style={{paddingTop:'2%'}}>
         <h3 id="tituloH1"></h3>
    </Col>
    <Col xs="5" style={{paddingTop:'2%',}}>
      <Button id="btnGuardar" name="btnGuardar" color="primary" solid="true" style={{width:"90%", color:'white'}} 
        onClick={async()=>{          
         await guardarRegistro() 
         if (codigoRojoDevueltoPorSql==null) return //quiere decir que NO se pudo guardar el registro
         // al recibir negativo, es que hubo algun error (alomejor cedula repetida -100 y -150 cuando no se pudo crear las CxC del man)
         if (codigoRojoDevueltoPorSql<0){
          await mostrarSwalBotonAceptar("warning","NO SE PUDO GUARDAR","Parece que está repitiendo una cédula. O no se pudo crear el plan")
          return 
         }
         //todo cool
         prepararJsonParaDevolver() //genero un JSON vacan para devolver al llamador
         props.ocultarModal_Cli('save',jsonDevolver)    
        }}>GUARDAR</Button>   
    </Col>
    <Col xs="3" style={{textAlign:'right',paddingTop:'0',paddingRight:'0'}}> 
      <Button style={{ width: '40px', height: '40px', }} id="btnSalir" name="btnSalir" color="danger"
        onClick={() => {
          props.ocultarModal_Cli('close',null)
        }}>
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
      </Button>   
    </Col>
  </Row>             
</div> {/* divGuardarSalir */}
{ ParteFija() }
{/* aqui creo un tab de 2 pestanas */}   
<ModalBody>
<Nav tabs >
  <NavItem >
    <NavLink style={{ backgroundColor:'hotpink'}}
      active= {tabActivoState == '1'}
      onClick={() => { 
        if(tabActivoState=='1') return 
        set_tabActivoState('1')
        }}>
      BASICOS
    </NavLink>
  </NavItem>
  <NavItem >
    <NavLink style={{ backgroundColor:'hotpink'}}
      active= {tabActivoState == '2'}
      onClick={() => { 
        if(tabActivoState=='2') return 
        set_tabActivoState('2')
        }}>
      EXTRA
    </NavLink>
  </NavItem>
</Nav>

<TabContent activeTab={tabActivoState} style={{ backgroundColor:'pink', borderStyle:'solid', borderColor:'silver',borderWidth:'medium'  }}> 
   <TabPane tabId="1">
    { TabDatosBasicos() }
   </TabPane>
   <TabPane tabId="2">
    { TabDatosExtra() }
   </TabPane>
</TabContent>
</ModalBody>
</Form> {/* miFormulario */}
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='cliente') return ComponentePrincipal()

} // del IncModCliente

export default IncModCliente