//Este form, permite escribir un descuento en $. Monto minimo CERO. Monto máximo será enviado por las props 
//en este mismo form, se valida que sea  >= que 0 y menor que el monto maximo
//También se puede salir con el botón de X 
//recibe un numero en texto (3.14) y devuelve un double redondeado comunmente a 2 decimales
//recibe otros parametros como: titulo, subTitulo, maximo (maximo es numero pero viene como texto '3.14')

import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
import {mostrarSwalBotonAceptar,reemplazarPuntoPorComa,reemplazarComaPorPunto,aceptarDecimales2023 } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let miMaximo=null //Monto maximo para hacer la comparación (descuento deber ser < que miMaximo)

const PedirDescuentoEnDolaresBonanza=(props)=>{  
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState] = useState('principal') //Arranco viendo obviamente el programa 'principal'

const cambiarDescuentoYsalir=async()=>{
  //Se acepta >=0 y <= que miMaximo 
  //se usa 2 decimales
  let miX=document.getElementById('txtDescuento').value //obtengo un texto
  miX=reemplazarComaPorPunto(miX) //obtengo un texto
  miX=parseFloat(miX)

  if (isNaN(miX)) {
    await mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número válido")
    return
  }

  miX=miX.toFixed(2)  //lo convierto a texto con 2 decimales solamente 
  miX=parseFloat(miX)

  //verifico que no meta negativos
  if (miX<0){
    await mostrarSwalBotonAceptar("error","ATENCION","No se acepta negativos")
    return
  }

  //verifico que no se pase de miMaximo 
  if (miX>=miMaximo){
    await mostrarSwalBotonAceptar("error","ATENCION","EL descuento es muy alto")
    return
  }
    
  props.cerrarModal_Descuento("save",miX) //le mando miX como numero, ya redondeado a 2 decimales
}


//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  miMaximo=parseFloat(props.maximo) //viene comno texto '3.14'
  //escribo el descuento viejo
  document.getElementById("txtDescuento").value= reemplazarPuntoPorComa(props.actual) //el props.actual viene como texto '3.14'

  return () => {
    //variables normales
    miMaximo=null 
  }    
  },[]
)
 
const ComponentePrincipalBody=()=>{  
return(
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
    {/* Nombre de la cuota */}
    <Row style={{margin:'0',}}>
      <Col xs="10" >
        <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{props.titulo}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ props.cerrarModal_Descuento("close",null)}} >
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
    </Row> 
    <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple',color:'pink'}}>
      <Row style={{margin:'0'}}>
        <Col xs="7">
          <Label for="txtDescuento">{props.subTitulo}</Label>
          <Input type="text" style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px' }} name="txtDescuento" id="txtDescuento" maxLength="9" 
            onKeyDown={(e)=>{
              aceptarDecimales2023(e)
              if ( e.key =="Enter" ) cambiarDescuentoYsalir()              
            }}
          />
        </Col>
        {/* Tecla enter */}
        <Col xs="5" style={{textAlign:'center',}}>    
        <Button style={{width:'120px', height:'120px',fontSize:'60px',}} id="btnEnter" name="btnEnter" color="success" 
          onClick= {()=> cambiarDescuentoYsalir() }>
          <FontAwesomeIcon color="purple" icon={faCheck} /> 
        </Button>
        </Col>         
      </Row>  
      </div> {/* divBloque1 */}
  </div> // divMaster
)}

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del PedirDescuentoEnDolaresBonanza

export default PedirDescuentoEnDolaresBonanza
