//alert(JSON.stringify(jsonNegra,null,2))
//***************************** importante *****************/
//me permite seleccionar un Plan para matricular a un Man en dicho plan
//Me apoyo en la plantalla base: PantallaSeleccionarUnPlan_SinProgramacion.
//Este form devuelve un json con las matricula nueva + las hermanas del mismo man. 
//pero el jsonDevolver, solo tiene sentido cuano es llamado desde GestionarPlanesDeUnAlumno. Para otros llamadores no tiene sentido ni imnporta
// ====== Caracteristicas del dicha Pantalla =====
//NO tiene nada de programación. En éste Form debo usar AddEventListener para que funcionen los botones
//tiene el Area de Encabezado para el TITULO, ya tiene el boton de salir (x) en el encabezado
//Oviamente tiene el cuerpo=> label para que diga PLANES ACTIVOS + el combo
//Tambien tiene en pie, con el boton ACEPTAR
//Lo unico que ella recibe por props es el titulo y un texto para el unico label
// ====== fin de Caracteristicas del dicha Pantalla =====

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input, Form} from 'reactstrap'
import buscarPhpPath,{ejecutarFetchGenericoConSwal,convertirUnTextBoxEnEntero,convertirUnTextBoxEnFloat,sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,verificarSiTextoEsPurosDigitos, aceptarSoloEnteros } from './lib_basica'
import PantallaSeleccionarUnPlan_SinProgramacion from './PantallaSeleccionarUnPlan_SinProgramacion'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//variables normales
let valueDC=null //Aqui hago una copia de los valores del context
let dataApi=null //lo que recibo de mi Api (Null,Negativo, Num Positivo)
let jsonDevolver=null //este objeto JSON lo devuelvo al llamador. Tiene TODAS las matriculas del Alumno (pero solo de planes activos con periodos activos).

const MatricularAunAlumnoEnUnPlan=(props)=>{   
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  //planesActivosState  contiene los planes (activos - los planes ya usados por el Alumno)
  const [planesActivosState,set_planesActivosState] = useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros (en el use effect si recibe NULL se devueove al componente llamador)

  const buscarPlanesActivos=async()=>{
    //Busco en mi Api, los planes Activos con perio activo
    //Luego le elimino los planes en los cuales YA está matriculado. Para que no tenga el chance de Repetir una matricula
    dataApi=null
    let data=new FormData()
    data.append('miSol','planPago_buscarPlanesDePagoConPeriodos') //me trae los planes Activos inner join con su respectivo Periodo Activo
    data.append('orderBy','NombrePlan') 
    data.append('forma','Asc')   
    data.append('selectAIT','A') //Solo me interesan los Planes y Periodos activos (ambos activos)
    //Busco SOLO los planes activos con periodos activos
    dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)    
    if (dataApi===null) {
      set_planesActivosState(null) //se cierra éste form automaticamente (a la final, el estado solo lo uso para obligar a éste form a cerrase. Para mas nada)
      return
    }
  
    //Rumbo ideal. A los planes Activos, le resto los planes ya usados
    let planesDef=dataApi.filter( activo => {
      return props.matriculasUsadas.findIndex( usado => activo.IDplan==usado.IDplan) < 0
    })
  
    //Limpiar el combo
    let miSelectComboP= document.getElementById("comboPlanes")
    for (let i = miSelectComboP.options.length; i >= 0; i--) {
      miSelectComboP.remove(i);
    }  
        
    //Alimentar el combo  , con los planes DEFINITIVOS
    planesDef.forEach( (item) => {   
      let miOption=document.createElement("option")
      miOption.value= item.IDplan
      miOption.text=item.NombrePlan
      miSelectComboP.appendChild(miOption)        
    })

    //Apunto a -1 en el combo
    document.getElementById("comboPlanes").selectedIndex=-1
  }

  const clickEnAceptar=async()=>{
    //Empiezo con la validacion
    if (document.getElementById("comboPlanes").selectedIndex<0){
      mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un plan")
      return false
    }

    //Llamo a mi poderosisima APi
    dataApi=null
    jsonDevolver=null
    let data=new FormData()
    data.append('miSol','matriculapp_matricularAlumnoEnPlan')  
    data.append('codAlu', props.IDr) 
    data.append('idPlan',document.getElementById("comboPlanes").value)   
    data.append('opCrea',valueDC.usuario.LoginUsu)  
    //Si todo sale bien, mi Api me devuelve la nueva matricula + las matriculas hermanas del man
    //Al repetir un PLAN, recibo un select vacio []
    dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)    
    if (dataApi===null) return

    //Al recibir [] osea vacío, significa que se está repitiendo una matrícula   
    if (dataApi.length===0){
      mostrarSwalBotonAceptar("error","ATENCION","Está repitiendo un plan")
      return false
    }

    //rumbo ideal, alimento el Json y cierro
    //OJO: el jsonDevolver debe tener la misma estructura de la tabela Llamadora para que coincida y asi evitar refrescar leyendo desde la BDD
    jsonDevolver=dataApi
    props.ocultarModal_Matricular("save",jsonDevolver)    
  }

  const clickEnCerrar=()=>{
    props.ocultarModal_Matricular("close",null)
  }

//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  //Tengo que agregar addEventListener al boton CERRAR y al boton ACEPTAR
  document.getElementById("btnGuardarPantallaSeleccionarPlan").addEventListener("click", clickEnAceptar, false)
  document.getElementById("btnCerrarPantallaSeleccionarPlan").addEventListener("click", clickEnCerrar, false)
  //Busco los Planes activos y los meto en el combo. Al haber error en mi dataApi entonces me devuelvo al llamador
  buscarPlanesActivos() 
 
  return () => {
    //Al desmontar el componente, debo quitar el clickListener
    //Al quitar los listener, me da error al salir. (parece que NO es necesario ponerlos ya que es automatico...)
    //document.getElementById("btnGuardarPantallaSeleccionarPlan").removeEventListener("click", clickEnAceptar, false)
    //document.getElementById("btnCerrarPantallaSeleccionarPlan").removeEventListener("click", clickEnCerrar, false)
    dataApi=null      
  }
  },[]
)
// *** cuando recibo NULL en la lista de Planes Activos, entonces me devuelvo al componente llamador
  useEffect(()=>{  
    if (planesActivosState===null){
      props.ocultarModal_Matricular("close",null)
    }  
    },[planesActivosState]  
  )

const ComponentePrincipalBody=()=>{
return ( 
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
  <PantallaSeleccionarUnPlan_SinProgramacion 
    titulo={'SELECCIONE'}
    label1Texto={'Planes Activos'}
    boton1Texto={'Aceptar'}
  />
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del const MatricularAunAlumnoEnUnPlan

export default MatricularAunAlumnoEnUnPlan
