//Solo pide los parametros para generar el libro de ventas
//luego me devuelvo al menu principal y desde ahi llamo realmente al componente del reporte

import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import { convierteObjetoFechaDeJSenTexto,textoDelCombo,limpiarCombo } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faBalanceScaleRight } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio
//variables que no son del estado
let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let jsonNegra={} //aqui guardo las opciones seleccionadas por el usuario
let miCurrentTime = new Date()
let miAno = miCurrentTime.getFullYear()
let miMes = miCurrentTime.getMonth() //OJO: al hacer esto: DICIEMBRE ES 11 
let miDia = miCurrentTime.getDate() //devuelve el dia del mes [1..31]

const PantNegraLibroVentas=(props)=>{   
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  const [fechaIni,setfechaIni]=useState(new Date(miAno,miMes,1))
  const [fechaFin,setfechaFin]=useState(new Date(miAno,miMes,miDia))

//************ simulo el componentDidMount */
useEffect( ()=>{
  //El boton de Excel, podria no estar visible en algunos casos
  if (props.botonExcelSN=="N")
    document.getElementById("btnExcel").style.visibility= 'hidden' //pero Lo botones visibles se quedan en su lugar original
    
  //prendo los radios por defecto
  document.getElementById("condicionTodas").setAttribute("checked","true")
  document.getElementById("usuariosTodos").setAttribute("checked","true")
  document.getElementById("vendedoresTodos").setAttribute("checked","true")

  alimentarCombos()
},[]
)

const alimentarCombos=()=>{
  //cargo el comboUsuarios
  let miComboUsu= document.getElementById("comboUsuarios")
  limpiarCombo(miComboUsu) //primero lo limpio 
  valueDC.usuarios.forEach( (item) => {
    if (item.ActivoUsu==1){
      let miOption=document.createElement("option")
      miOption.value= item.LoginUsu
      miOption.text=item.LoginUsu
      miComboUsu.appendChild(miOption)
    }
  })

  //cargo el comboVendedores
  let miComboVen= document.getElementById("comboVendedores")
  limpiarCombo(miComboVen) //primero lo limpio 
  valueDC.vendedores.forEach( (item) => {
    if (item.ActivoVen==1){
      let miOption=document.createElement("option")
      miOption.value= item.IDv
      miOption.text=item.NombreVen
      miComboVen.appendChild(miOption)
    }
  })
  //al combo lo pongo a que no apunte a nadie
  miComboUsu.value=0
  miComboVen.value=0
}

const salir=(botonEP)=>{
  //el botonEP, puede venir con ["E"xcel / "P"df]
  //valido que la fecha fin sea mayor/igual que fecha ini
  let fechasOK=(fechaFin>=fechaIni)
  if (!fechasOK){
    mostrarSwalBotonAceptar("error","ATENCION","La fecha final debe ser mayor que la inicial")
    return false
  }

  //guardo las opciones del usuario 
  jsonNegra.fechaIni=convierteObjetoFechaDeJSenTexto(fechaIni) 
  jsonNegra.fechaFin=convierteObjetoFechaDeJSenTexto(fechaFin) 
  //tipo de factura
  if (document.getElementById("condicionTodas").checked)
    jsonNegra.tipoFacturaTOCOCR="TO"
  if (document.getElementById("condicionContado").checked)
    jsonNegra.tipoFacturaTOCOCR="CO"
  if (document.getElementById("condicionCredito").checked)
    jsonNegra.tipoFacturaTOCOCR="CR"  
  //usuarios
  if (document.getElementById("usuariosTodos").checked)
    jsonNegra.usuarios="0" //todos
  else
    jsonNegra.usuarios=document.getElementById("comboUsuarios").value
  //vendedores
  if (document.getElementById("vendedoresTodos").checked){
    jsonNegra.vendedores="0" //todos
    jsonNegra.vendedorNombre= ''
  }
  else{
    jsonNegra.vendedores=document.getElementById("comboVendedores").value  //codigo del vendedor
    jsonNegra.vendedorNombre= textoDelCombo(document.getElementById("comboVendedores")) 
  }

  //cierro y regreso al llamador
  props.cerrarModal(true,jsonNegra,botonEP)  
}

const ComponentePrincipalBody=()=>{  
return (               
<div id="divMaster" name="divMaster" className='FondoCasiNegro'>    
  {/* Titulo + Boton Cerrar */}
  <Row style={{margin:'0',}}>
      <Col xs="10" >
        <Label style={{marginTop:'5px',color:'dimGray',fontWeight: 'bold'}}>{props.miTitulo}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ 
            props.cerrarModal(false,null,null)  //no quiero seguir, no hay jsonNegra, no hay boton
            }} >
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
  </Row> 

  {/* Rango de fechas, contado/credito, usuarios, vendedores */}
    <div id="divBloque1" name="divBloque1" style={{margin:'3%',padding:'2%',background:'black', color:'Fuchsia', borderStyle:'solid', borderColor:'pink'}}>
      {/* Fechas */}
      <Label style={{color:'pink'}}>Fechas</Label>    
      <Row style={{margin:'0',}}>
        <Col>
          <Label>Desde</Label>
          <DatePicker id="txtDesde" name="txtDesde" className="anchoDatePickerGenerico" locale='es' dateFormat="dd/MM/yyyy"
            selected={ fechaIni }          
            onChange= { (datePar)  => setfechaIni(datePar) }
          />    
        </Col>
        <Col>
          <Label>Hasta</Label>
          <DatePicker id="txtHasta" name="txtHasta" className="anchoDatePickerGenerico" locale='es' dateFormat="dd/MM/yyyy"
            selected={ fechaFin }          
            onChange= { (datePar)  => setfechaFin(datePar) }
          />
        </Col>
      </Row>
      <hr style={{color:'pink'}}/>
      {/* Tipo de Factura */}
      <Label style={{color:'pink'}}>Tipo de Factura</Label>    
      <Row style={{margin:'0',}}>
        <Col>      
          <Label><Input type="radio" id="condicionTodas" value="0" name="radioCondicion" /> Todas</Label><br/>
        </Col>
        <Col>      
          <Label><Input type="radio" id="condicionContado" value="1" name="radioCondicion" /> Contado</Label><br/>
        </Col>
        <Col>      
          <Label><Input type="radio" id="condicionCredito" value="2" name="radioCondicion" /> Crédito</Label><br/>
        </Col>      
      </Row>
      <hr style={{color:'pink'}}/>
      {/* Usuarios */}
      <Label style={{color:'pink'}}>Usuarios</Label>    
      <Row style={{marginTop:'2%',marginBottom:'1%',marginLeft:'0',marginRight:'0',padding:'0'}}>
        <Col xs="3">
          <Label><Input type="radio" id="usuariosTodos" value="0" name="radioUsuarios" 
            onClick={ () => { document.getElementById("comboUsuarios").value=0 }}        
          /> Todos</Label><br/>      
        </Col>
        <Col xs="9" style ={{display:'flex',textAlign:'right'}}>
          <Label><Input type="radio" id="usuariosSolamente" value="1" name="radioUsuarios" disabled /> Solamente</Label><br/>      
          <Input type="select" name="comboUsuarios" id="comboUsuarios" style={{marginLeft:'2%',width:'58%'}}
            onChange={ () =>{
              document.getElementById("usuariosSolamente").checked = true;
              }} 
          ></Input>
        </Col>
      </Row>
      <hr style={{color:'pink'}}/>
      {/* Vendedores */}
      <Label style={{color:'pink'}}>Vendedores</Label>    
      <Row style={{marginTop:'2%',marginBottom:'1%',marginLeft:'0',marginRight:'0',padding:'0'}}>
        <Col xs="3">
          <Label><Input type="radio" id="vendedoresTodos" value="0" name="radioVendedores" 
            onClick={ () => document.getElementById("comboVendedores").value=0 }        
          /> Todos</Label><br/>
        </Col>
        <Col xs="9" style ={{display:'flex',textAlign:'right'}}>
          <Label><Input type="radio" id="vendedoresSolamente" value="1" name="radioVendedores" disabled/> Solamente</Label><br/>      
          <Input type="select" name="comboVendedores" id="comboVendedores" style={{marginLeft:'2%',width:'58%'}}
            onChange={ () =>{
              document.getElementById("vendedoresSolamente").checked = true;
            }}         
          ></Input>
        </Col>
      </Row>

    </div> {/* divBloque1 */}

    {/* Boton Aceptar style={{marginRight:'10%'}} */}
    <Row style={{marginTop:'1%',marginBottom:'2%',marginLeft:'0',marginRight:'0',}} >
      <Col >    
        <Button size="md" id="btnExcel" name="btnExcel" color="info" solid="true" 
        onClick={()=>{ salir("E") }}>
        EXCEL</Button>
      </Col>         
      <Col style={{textAlign:'right',}}>    
        <Button size="md" id="btnListo" name="btnListo" color="info" solid="true" 
        onClick={()=>{ salir("P") }}>
        VER</Button>
      </Col>         
    </Row>    
</div> //divMaster
)}  

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} // de PantNegraLibroVentas

export default PantNegraLibroVentas
