import Swal from 'sweetalert2'
import {v4 as uuid} from 'uuid'

/***************************** OPCIONES PARA CUSTOM CLASS EN SWEETALERT2 */
/*
customClass: {
      //container: 'classSweetPos',
      popup: 'classSweetPos', //la alerta como tal
      //header: '...',
      //title: '...',
      //closeButton: '...',
      //icon: '...',
      //image: '...',
      //content: 'classSweetPos', //es la parte donde va el texto:cuerpo (No el Titulo)
      //htmlContainer: '...',
      //input: '...',
      //inputLabel: '...',
      //validationMessage: '...',
      //actions: '...',
      //confirmButton: '...',
      //denyButton: '...',
      //cancelButton: '...',
      //loader: '...',
      //footer: '....'
    }
*/

//color fucsia:227,0,82
//gris: 155,155,155
//gris oscuro:130,130,130
//cyan :0,255,255
//blanco: 255,255,255
const mibackdrop= `
rgba(210,210,210,0.7)
left top
no-repeat
`
export const generarRandom43=()=>{
  let miRandom43=null //ID universal para mandarlo a MYSQL
  let fechaHoy = new Date() //Fecha actual
  let mes = fechaHoy.getMonth()+1 //obteniendo mes
  let dia = fechaHoy.getDate() //obteniendo dia
  let ano = fechaHoy.getFullYear()-2000 //obteniendo año
  let anexar="_"+dia+mes+ano
  miRandom43=uuid()
  miRandom43 +=anexar

  return miRandom43
}

export const ordenarArrayPorCampoTexto=(arraycito,campoClave)=>{
  arraycito.sort((a,b)=>{
    const referencia1=a[campoClave].toLocaleLowerCase()
    const referencia2=b[campoClave].toLocaleLowerCase()
    if (referencia1 < referencia2) return -1
    if (referencia1 > referencia2) return 1
    if (referencia1 == referencia2) return 0
  })  
}

export const ordenarArrayPorCampoNumerico=(arraycito,campoClave)=>{
  arraycito.sort((a,b)=>{
    const referencia1=a[campoClave] 
    const referencia2=b[campoClave] 
    return referencia1 - referencia2
  })  
}

export const ordenarArrayPorCampoFecha=(arraycito,campoClave)=>{
  arraycito.sort((a,b)=>{
    const referencia1=new Date(a[campoClave])
    const referencia2=new Date(b[campoClave])
    return referencia1 - referencia2
  })  
}

export function redondearA2D(miNumero){
  //recibo un numero, posiblemente con varios decimales y lo devuelvo un numero pero con 2 decimales solamente
  //FALTA revisar: al tener una base de 78$ y hacer una retencion de 1,75% segun js me da 1,36 pero deberia dar 1,37
  miNumero=miNumero.toFixed(2) //lo convierto a texto con 2 decimales
  miNumero=parseFloat(miNumero)
  return miNumero
}

export default function buscarPhpPath(){
  //alert(document.domain) tambien devuelve localhost 
  let miHost = document.location.hostname //me devuelve (localhost para local) o (www.sistemaspaladino.com para remoto) **** OK ***
  //asumo que estoy en diseno
  let miRetornar="//" + miHost + "/bonanzawebec/src/php/" //esta linea esta OK
    
  //verifico si estoy en produccion
  //originalmente estaba ../php (con nodored)
  // "/php/" ok para hostinger siempre y cuando entre con: pruebas.paladino.digital
  // "./php/" ok para hostinger funciona con: pruebas.paladino.digital y www.paladino.digital/pruebas (pero no se ve las imagenes)
  // "../php/" ok para hostinger siempre y cuando entre con: pruebas.paladino.digital

  if (miHost.includes('.digital'))
    miRetornar ="./php/"  

  return (miRetornar)
}

export function buscarHostnameSegunJs(){
  let miHost = document.location.hostname //me devuelve (localhost para local) o (www.sistemaspaladino.com para remoto) **** OK ***
  return miHost
}

export function detectarTipoDeDocumentoSri(miCod){
  let miTipoDoc=null
  switch (miCod) {
    case "01":
      miTipoDoc="FACTURA"
      break
    case "03":
      miTipoDoc="LIQUIDACION DE COMPRAS"
      break
    case "04":
      miTipoDoc="NOTA DE CREDITO"
      break    
    case "05":
      miTipoDoc="NOTA DE DEBITO"
      break        
    case "06":
      miTipoDoc="GUIA DE REMISION"
      break        
    case "06":
      miTipoDoc="RETENCION"
      break       
  }  
return miTipoDoc  
}

export function buscarCarpeta_docs_temporal_o_sri(tipoSriSN){
  //antiguamente: buscarCarpeta_docs_temporal
  //me indica la ubicacion de la carpeta donde debo buscar el archivo.
  //docs_temporal (ahi hay pdf's de proformas, nota entrega, es como una carpeta temporal)
  let miHost=null
  let miRetornar=null

  if (tipoSriSN=="N"){
    miHost = document.location.hostname
    //asumo que estoy en diseno
    miRetornar="//" + miHost + "/bonanzawebec/docs_temporal/" //linea ok
    //verifico si estoy en produccion
    if (miHost.includes('.digital')){ miRetornar ="./docs_temporal/" }
  }
  if (tipoSriSN=="S"){
    miHost = document.location.hostname
    //asumo que estoy en diseno
    miRetornar="//" + miHost + "/bonanzawebec/docs_sri/pdf/"
    //verifico si estoy en produccion
    if (miHost.includes('.digital')){ miRetornar ="./docs_sri/pdf/" }
  }

  return miRetornar
}

export function detectarFilaMasAlta(miJson){  
  //solamente me detecta el numero mas alto en el campo Fila.
  //Recibe un JSON (generalmente el mismo que se usa para un grid)
  let miFilaDevolver=0
  miJson.forEach(item=>{
    if (item.Fila>miFilaDevolver)
      miFilaDevolver=item.Fila
  })  
  return parseInt(miFilaDevolver)
}

export function hacerRenumDeUnJson(jsonInput){
  //Hago un renum del campo Fila (así como la opcion RENUM del lenguajer BASIC)
  //Se requiere un JSON (generalmente el mismo que se usa en un GRID)
  let filaRenum=1
  let jsonOutput=jsonInput.map(item=>{
    item.Fila=filaRenum++
    return item      
  })
  
  return jsonOutput
}

export function aceptarSoloEnteros (e) {
  //ya no usar esta funcion
        //46 punto
        //44 coma

        //var miTextoAntes=e.target.value
        //var miContieneComa = miTextoAntes.includes(",")
        //var miContienePunto = miTextoAntes.includes(".")
        //if (miContieneComa || miContienePunto) 
          // e.preventDefault()

        let miKey= e.keyCode || e.which
        //alert(miKey)

        if ( miKey==44 || miKey==46 )
        e.preventDefault()
        
        if ( miKey >=48 && miKey<= 57)
            return true   
          
        e.preventDefault()
}   

export function aceptarSoloEnteros2023 (e) {
    // ==== 2023
    //hay que llamarlo dede onKeyDown (no desde onKeyPress)
    const validKeyForPayment = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Delete"
    ]
    if (!validKeyForPayment.includes(e.key)) 
      e.preventDefault()
}   

export function aceptarDecimales2023 (e) {
    // ==== 2023 NO acepta punto solo coma
    //hay que hacerlo en onKeyDown (falta que funcion con las flechas)
    const validKeyForPayment = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ",",
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Delete"
    ]
    if (!validKeyForPayment.includes(e.key)) 
      e.preventDefault()
}   

export function aceptarDecimales (e) {
        //ademas hay que revisar que solo haya UNA coma como maximo (no se acepta punto)
        //46 punto
        //44 coma
        var miTextoAntes=e.target.value
        var miContieneComa = miTextoAntes.includes(",")
        var miContienePunto = miTextoAntes.includes(".")
        let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando

        if (miContieneComa || miContienePunto) 
           if (miKey==44 || miKey==46)
                e.preventDefault()
        
        if ( (miKey >=48) && (miKey<= 57) || miKey===44 ) //Aparte de los digitos (solo el punto )
            return true   
          
        e.preventDefault()
}         

export function verificarSiTextoEsPurosDigitos(miTexto){
//Recibe un string, y revisa si cada caracter es un digito [0..9] NO SE ACEPTA . , - + 
//es util por ejemplo para revisar que un Establecimiento o punto de un proveedor, esté bien escrito  

let miDevolver=false
let valoresAceptados = /^[0-9]+$/
if (miTexto.match(valoresAceptados)) miDevolver=true

return miDevolver
}

export function verificarSiEsNumeroEnteroSolamente(miTextBox){
  //solo recibe un textBox y me indica si el un numero entero
  let miNum=parseInt(miTextBox.value.trim(),10)
  if (isNaN(miNum) ){
     return false
  }
return true
}

export function verificarSiEsNumeroEnteroMayorQueCero(miTextBox){
  //solo recibe un textBox y me indica si el un numero entero es mayor que cero
  let miNum=parseInt(miTextBox.value.trim(),10)
  if (isNaN(miNum) ){
     return false
  }
  if (miNum<=0){
    return false
  }
return true
}

export function verificarSiEsNumeroMayorQueCero(miTextBox){
  //let misDiasNum=parseInt(document.getElementById("txtPlazo").value.trim(),10)
  let misDiasNum=parseInt(miTextBox.value.trim(),10)
  if (isNaN(misDiasNum) ){
     return false
  }
return true
}

export function convertirUnTextBoxEnEntero(miTextBox){
  //Recibe un textBox (que esté vació o que contenga solo numeros enteros)
  //Al estar Vacío =>devuelve 0
  //Al tener un numero=> me devuelve el entero contenido
  let enteroDevolver=0
  let miContenido=miTextBox.value.trim() 
  if (miContenido.length>0){
    //Veo que numero tiene
    enteroDevolver=parseInt(miContenido,10)
  }

  return enteroDevolver
}

export function convertirUnTextBoxEnFloat(miTextBox){
  //Recibe un textBox (que esté vació o que contenga algun valor decimal)
  //Al estar Vacío =>devuelve 0
  //Al tener un numero=> me devuelve el decimal contenido
  let floatDevolver=0
  let miContenido=miTextBox.value.trim() 
  if (miContenido.length>0){
    //Veo que numero tiene
    floatDevolver=parseFloat(miContenido)
  }

  return floatDevolver
}

export function reemplazarPuntoPorComa(miTextoConPunto){
  //Recibe un texto por ejemplo '3.1416' y devuelve otro texto '3,1416'
  //es útil cuando viene un JSON desde la BDD pero necesito mostrarlo en formato latino
  let miTextoDevolver=miTextoConPunto.replace(".",",")
  return miTextoDevolver
}

export function reemplazarComaPorPunto(miTextoConComa){
  //Recibe un texto por ejemplo '3,1416' y devuelve otro texto '3.1416' 
  let miTextoDevolver=miTextoConComa.replace(",",".")
  return miTextoDevolver
}

export function sleepPepe(milise){
  return new Promise( listo =>{
    setTimeout( () => { 
      listo()
     },milise)
  })
}

export function determinarFormatoImpresoraGrande(miContext,estab,tipoDocumento){
  //este código fue mejorado diciembre del 2023
  //determino cual es el formato, segun el establecimiento y el tipo de documento 
  /*
  let miFormato=miContext.establecimientos.map(item=>{
    if (item.Estab==estab && 'FormatoFacturaSri'== tipoDocumento ) return item.FormatoFacturaSri        
    if (item.Estab==estab && 'FormatoGuiaSri'== tipoDocumento ) return item.FormatoGuiaSri        
    if (item.Estab==estab && 'FormatoRetSri'== tipoDocumento ) return item.FormatoRetSri        
    if (item.Estab==estab && 'FormatoNcSri'== tipoDocumento ) return item.FormatoNcSri        
    if (item.Estab==estab && 'FormatoNdSri'== tipoDocumento ) return item.FormatoNdSri
    if (item.Estab==estab && 'FormatoLiqSri'== tipoDocumento ) return item.FormatoLiqSri        
    if (item.Estab==estab && 'FormatoFacturaSimple'== tipoDocumento ) return item.FormatoFacturaSimple        
    if (item.Estab==estab && 'FormatoProforma'== tipoDocumento ) return item.FormatoProforma          
    if (item.Estab==estab && 'FormatoNotaEntregaTipica'== tipoDocumento ) return item.FormatoNotaEntregaTipica        
    if (item.Estab==estab && 'FormatoNotaEntregaSinIva'== tipoDocumento ) return item.FormatoNotaEntregaSinIva        
    if (item.Estab==estab && 'FormatoPlan'== tipoDocumento ) return item.FormatoPlan      
    })

  let miFormatoDevolver=(miFormato[0]==null) ? '' : miFormato[0]
  return miFormatoDevolver
  */

  let miFormato='' //determino el papel que debo usar (tipicamente, debe devolver A4 o A5)
  //reviso que context tenga datos. Por el bendiro StrictMode
  if (miContext){
  miContext.establecimientos.forEach(item=>{
    if (item.Estab==estab && 'FormatoFacturaSri'== tipoDocumento ) miFormato= item.FormatoFacturaSri        
    if (item.Estab==estab && 'FormatoGuiaSri'== tipoDocumento ) miFormato= item.FormatoGuiaSri        
    if (item.Estab==estab && 'FormatoRetSri'== tipoDocumento ) miFormato= item.FormatoRetSri        
    if (item.Estab==estab && 'FormatoNcSri'== tipoDocumento ) miFormato= item.FormatoNcSri        
    if (item.Estab==estab && 'FormatoNdSri'== tipoDocumento ) miFormato= item.FormatoNdSri
    if (item.Estab==estab && 'FormatoLiqSri'== tipoDocumento ) miFormato= item.FormatoLiqSri        
    if (item.Estab==estab && 'FormatoFacturaSimple'== tipoDocumento ) miFormato= item.FormatoFacturaSimple        
    if (item.Estab==estab && 'FormatoProforma'== tipoDocumento ) miFormato= item.FormatoProforma          
    if (item.Estab==estab && 'FormatoNotaEntregaTipica'== tipoDocumento ) miFormato= item.FormatoNotaEntregaTipica        
    if (item.Estab==estab && 'FormatoNotaEntregaSinIva'== tipoDocumento ) miFormato= item.FormatoNotaEntregaSinIva        
    if (item.Estab==estab && 'FormatoPlan'== tipoDocumento ) miFormato= item.FormatoPlan  
    })
  }
  return miFormato
}

//************************************* */
//************* parte para sweetalert2 */
//************************************* */
//***** EJEMPLO DE LA DOCUMENTACION************ */

//NOTA: el boton "confirmButton" lo voy a tener reservado OBLIGATORIAMENTE para el spinner 
//para que me funcione en ANDROID
export function mostrarSwalEspera(miBackColor="White",miTitulo=""){
 //prendo la ventana de espera/spinner
 //normalmente el fondo es blanco y sin titulo (mientras cargo CLIENTES, INVENTARIO,.....)
 //pero cuando no hay internet y espero 10 segundos, entonces el fondo es NEGROy el titulo "Esperando internet..."
 Swal.fire({
 allowOutsideClick:false,
 allowEscapeKey:false,
 allowEnterKey:false,
 backdrop: mibackdrop,
 background:miBackColor,
 title:miTitulo,
 customClass:{
    //solo es necesario en actions
    actions:'classLoaderGira',
  },
  didOpen: () => { Swal.showLoading() }  
   })
  //tambien se puede poner asi
  //Swal.showLoading()
}   
        
export function apagarSwal(){
  //apago la ventana de espera/spinner
  if (!(Swal.isVisible())) return
        
  Swal.close()
}   
       
export async function mostrarSwalUnSegundo(miTexto,miTiempo){
await Swal.fire({
  allowOutsideClick:false,
  allowEscapeKey:false,
  allowEnterKey:false,        
  backdrop: mibackdrop,        
  title:'OK',
  text: miTexto,  
  icon:'success',
  timer: miTiempo,
  showConfirmButton: false, //isConfirmed
  showDenyButton: false, //isDenied
  showCancelButton: false, //isDismissed    
 })
}

//swal con timer y posicion (comunmente abajo a la derecha)
export function mostrarSwalPos(miTexto,miTiempo){
  Swal.fire({
    backdrop: false, //para que la pantalla de atras no se ponga apaca
    allowOutsideClick:true,
    allowEscapeKey:true,
    allowEnterKey:true,            
    //title:'OK', NO LE PONGO TITULO PARA QUE NO OCUPE ESPACIO
    //text: miTexto,  //(prefiero sin texto para que no ocupe mucho espacio)
    icon:'success',
    timer: miTiempo,
    showConfirmButton: false, //isConfirmed
    showDenyButton: false, //isDenied
    showCancelButton: false, //isDismissed    
    position:'bottom-end',
    //background: 'pink', //el color es mejor manejarlo en la clase: classSweetPos. Para poder hacer test con el media query 
    customClass: {
      popup: 'classSweetPos', //la alerta como tal
    },    
   })
}

export async function mostrarSwalConfirmacionEliminarAnular(miTitulo,miTexto){
 const miRespuesta= await Swal.fire({
   allowOutsideClick:false,
   allowEscapeKey:false,
   allowEnterKey:false,        
   backdrop: mibackdrop,   
   icon: 'question',
   title: miTitulo,
   text: miTexto,  
   showConfirmButton: false,
   showDenyButton: true, //isDenied 
   showCancelButton: true, //isDismissed
   denyButtonText:'Si', 
   cancelButtonText:'No'
   })
  return (miRespuesta)
}

//NOTA: el boton confirmButton, por favor no usarlo. 
//Esta reservado estrictamente para la funcion mostrarSwalEspera()
//Colores: 
//(negro=> no hay internet) 
//(rosado=>no hay base de datos/usuario/contrasena)
//(silver=>No se pudo ejecutar la solicitud )

export function mostrarSwalSimple(miIcono,miTitulo,miTexto){   
  Swal.fire('Any fool can use a computer')
}

export async function mostrarSwalBotonAceptarBucle(miIcono,miTitulo,miArrayDeMensajes,i,limite){ 
  //recibe un array de textos para mostrarlos de uno en uno
    await Swal.fire({
      allowOutsideClick:false,
      allowEscapeKey:false,
      allowEnterKey:false,        
      backdrop: mibackdrop,
      title:miTitulo,
      text: miArrayDeMensajes[i],  
      icon:miIcono, //'info','warning', 'success', 'error', 'question'
      showConfirmButton: true, //isConfirmed. originalmente false
      showDenyButton: false, //isDenied. originalmente true
      showCancelButton: false, //isDismissed. originalmente false    
      confirmButtonText: 'ACEPTAR'
    })
    .then (async ()=>{
      i++
      if (i<limite) 
        await mostrarSwalBotonAceptarBucle(miIcono,miTitulo,miArrayDeMensajes,i,limite)
    })    
}

//(sin usar array de mensajes) OK
export async function mostrarSwalBotonAceptar(miIcono,miTitulo,miTexto){ 
  const miRespuesta= await Swal.fire({
      allowOutsideClick:false,
      allowEscapeKey:false,
      allowEnterKey:false,        
      backdrop: mibackdrop,
      title:miTitulo,
      text: miTexto,  
      icon:miIcono, //'info','warning', 'success', 'error', 'question'
      showConfirmButton: true, //isConfirmed. originalmente false
      showDenyButton: false, //isDenied. originalmente true
      showCancelButton: false, //isDismissed. originalmente false    
      //denyButtonText: 'ACEPTAR',
      confirmButtonText: 'ACEPTAR'
  })
  return miRespuesta
}

export async function mostrarSwalReintentar(miEstilo="inter", miTitulo='NO HAY CONEXION'){
  let miColor=null
  switch (miEstilo) {
    case 'inter':
      miColor='black'
      break
    case 'bdd':
      miColor='pink'
      break
    case 'soli':
      miColor='silver'
      break      
    case 'otro':
    default:
      miColor='yellow'
      break      
  }
  const miRespuesta= await Swal.fire({
    allowOutsideClick:false,
    allowEscapeKey:false,
    allowEnterKey:false,        
    background: miColor,
    backdrop: mibackdrop,   
    icon: 'error',
    title: miTitulo,
    text: 'Desea reintentar ?',  
    showConfirmButton: false, //isConfirmed
    showDenyButton: true, //isDenied (REINTENTAR) (.isDenied)
    showCancelButton: true, //isDismissed   (SALIR) (.isDismissed)
    denyButtonText: 'Reintentar',
    cancelButtonText:'Salir',
    })
   return (miRespuesta)
}

export async function mostrarNoHayInternetConSwalReintentar(){
  //esta pantalla, es especificamente cuando estamos seguros que no hay internet (y se muestra los botones REINTENTAR y SALIR)
  const miRespuesta= await Swal.fire({
    allowOutsideClick:false,
    allowEscapeKey:false,
    allowEnterKey:false,        
    background: "Black",
    backdrop: mibackdrop,   
    icon: 'error',
    title: "NO HAY INTERNET",
    text: 'Desea reintentar ?',  
    showConfirmButton: false, //isConfirmed
    showDenyButton: true, //isDenied
    showCancelButton: true, //isDismissed    
    denyButtonText: 'Reintentar',
    cancelButtonText:'Salir',
    })
   return (miRespuesta)
}

export async function mostrarTresOpcionesConSwal(miTitulo,miCuerpo,miTextoBoton1,miTextoBoton2,miTextoBoton3){
  //Le mano un titulo,un texto cuerpo,y el texto para 3 botones
  //por ejemplo: titulo="MODIFICAR PLAN"
  //texto: VAMOS A MODIFICAR UN PLAN. QUE DESEA MODIFICAR ? EL ENCABEZADO O EL DETALLE 
  //Boton1: ENCABEZADO. //isConfirmed 
  //Boton2: DETALLE.  //isDenied
  //Boton3: SALIR/CANCELAR/REGRESAR.  //isDismissed    
  //luego el llamador verá que le conviene
  const miRespuesta= await Swal.fire({   
    allowOutsideClick:false,
    allowEscapeKey:false,
    allowEnterKey:false,        
    background: "white",
    backdrop: mibackdrop,   
    icon: 'info',
    title: miTitulo,
    text: miCuerpo,  
    showConfirmButton: true, //isConfirmed 
    showDenyButton: true, //isDenied
    showCancelButton: true, //isDismissed    
    confirmButtonText:miTextoBoton1,
    denyButtonText: miTextoBoton2,
    cancelButtonText: miTextoBoton3
    })
   return (miRespuesta)
}

export async function hayInternet(){
  return true
  /*
  //reviso si hay internet. Devuelve true (al no haber, hace varios intentos separados de 2 segundo c/u. Luego de eso me muestra un swal con la opcion REINTENTAR o SALIR)
  //al no haber internet o al no desear REINTENTAR, devuelve FALSE.
  let hayInternetWhile=null //para detectar internet dentro del WHILE
  let internetDevolver=null //Valor a devolver al llamador

  if (navigator.onLine) 
    internetDevolver=true
  else{
    //voy hacer un ciclo de maximo 6 intentos, separados de 2 segundos
    //la pantalla va a ser negra. Luego de los intentos pregunto si desea REINTENTAR
    let veces=null //maximo 6 veces
    let reintentar=true 
    while (reintentar){      
      veces=1
      hayInternetWhile=false
      mostrarSwalEspera("Black","Esperando internet...")
      while (veces<=2 && hayInternetWhile==false){    
        await sleepPepe(2000)
        hayInternetWhile = navigator.onLine
        internetDevolver=hayInternetWhile
        reintentar= !hayInternetWhile
        veces++
      } //del while interno
      apagarSwal()
      if (internetDevolver==false){
        //despues de varios intentos, pregunto si desea REINTENTAR
        let miRespSwal = await mostrarSwalReintentar("inter","No hay internet...")
        //al haber pulsado REINTERAR, sigo en el while externo
        if (miRespSwal.isDenied) 
          reintentar=true
        else
          reintentar=false
      }
    } //del while externo
  } //del else

  return internetDevolver
  */
}

export async function gestionarCatch (e){ 
    //importante: aqui convierto la e en texto (por precaucion) ya que posiblemente viene como un OBJETO
    //la e puede venir como un objeto cuando no hay internet o cuando no se consigue algun archivo .php
    e=e.toString()
  
    let miRespSwal=null
    let e_minus = e.toLowerCase()
  
    if (e_minus.includes("base de datos no responde")){
      miRespSwal = await mostrarSwalReintentar('bdd',e)
    }
    else if (e_minus.includes("no se pudo ejecutar la solicitud")){
      miRespSwal = await mostrarSwalReintentar('soli',e)
    }
    else if (e_minus.includes("no se pudo enviar el correo")){
      miRespSwal = await mostrarSwalReintentar('soli',e)
    }
    //el "no se encuentra el xml adjunto" y el "no se encuentra el pdf adjunto" lo uso para el envio del correo al cliente final
    else if (e_minus.includes("no se encuentra el xml adjunto")){
      miRespSwal = await mostrarSwalReintentar('soli',e)
    }
    else if (e_minus.includes("no se encuentra el pdf adjunto")){
      miRespSwal = await mostrarSwalReintentar('soli',e)
    }
    else if (e_minus.includes("no se encuentra el xml firmado")){
      //"no se encuentra el xml firmado", lo uso cuando deseo enviar/validar con el sri
      miRespSwal = await mostrarSwalReintentar('soli',e)
    }
    else { //no hay internet , o no se consigue algun archivo .php y/o otros casos no previstos
      //cuando no hay internet, e=> viene como un OBJETO con el valor: "TypeError: NetworkError when attempting to fetch resource"
      miRespSwal = await mostrarSwalReintentar()
    }    
  
    return miRespSwal
}  

// ===================================================================
// ==================== funciones de fechas ==========================
// ===================================================================
export function convierteObjetoFechaDeJSenTexto(fechaEntrada){
  //recibe un objeto de tipo Date() generada en JS y lo devuelve en modo texto.
  //lo recibe asi: "2021-12-01T05:00:00.000Z" (tipo objeto)
  //lo devuelve asi: "2021-12-01" (tipo texto)
  let fechaTexto=JSON.stringify(fechaEntrada)
  //le quito las comillas al texto (no se porque, pero al convertir de DATE a texto le deja las comillas dobles) mas o menos asi: ""2021-12-01T05:00:00.000Z""
  fechaTexto=fechaTexto.replace(/['"]+/g,'') //busca comillas dobles o simples y las reemplaza por vacio
  fechaTexto=fechaTexto.substring(0,10)
  return fechaTexto
}

export function devolverFechaHoyTextoYYYYMMDD(){
  //******** Nueva
  let hoyFecha=new Date()
  let hoyTexto = hoyFecha.getFullYear() + "-" + String(hoyFecha.getMonth() + 1).padStart(2, '0') + "-" + String(hoyFecha.getDate()).padStart(2, '0') 
  return(hoyTexto)
}

export function devolverObjetoFechaApartirDeUnTexto(fechaTexto){
  // recibe un texto de fecha YYYY-mm-dd (obligatorio que la entrada sea asi 10 caracteres)
  // y devuelve un OBJETO DATE  (normalmente para asociarlo a un DatePicker)
  let miAno=fechaTexto.substring(0,4)
  let miMes=fechaTexto.substring(5,7)
  let miDia=fechaTexto.substring(8)
  //OJO: Al usar el metodo DATE con 3 string, el mes de enero es 0. OJO
  let objFecha=new Date(miAno,miMes-1,miDia)
  return(objFecha)
  }

export function formatearFechaHoraDevuelveDDMMYY(en_amd,dh){
  //recibe una fecha/hora en formato mysql yyyy-mm-dd HH:mm:ss y la convierte en formato dd-mm-yyyy HH:mm
  //en_amd debe llegar como texto yyyy-mm-dd HH:mm:ss
  //dh llega como boolean
  //el parametro dh, es para saber si desea tambien la hora

  let cadena = en_amd.trim()
  let dia=cadena.substring(8,10)
  let mes=cadena.substring(5,7)
  let ano=cadena.substring(0,4)
  let miHora=null
  let miDevolver=dia + "-" + mes + "-" + ano
  if (dh){
    miHora=cadena.substring(10,16).trim()
    miDevolver += " " + miHora
  }

  return miDevolver
}

export function devolverMesTextoDesdeUnNumero(numMes){
  //me devuelve el mes: ENERO, FEBRERO.... a partir de un numero (1: es ENERO)
  let miDevolver
  switch (numMes) {
    case 1:
      miDevolver='Enero'
      break
    case 2:
      miDevolver='Febrero'
      break      
    case 3:
      miDevolver='Marzo'
      break
    case 4:
      miDevolver='Abril'
      break            
    case 5:
      miDevolver='Mayo'
      break
    case 6:
      miDevolver='Junio'
      break      
    case 7:
      miDevolver='Julio'
      break
    case 8:
      miDevolver='Agosto'
      break      
    case 9:
      miDevolver='Septiembre'
      break
    case 10:
      miDevolver='Octubre'
      break      
    case 11:
      miDevolver='Noviembre'
      break
    case 12:
      miDevolver='Diciembre'
      break                  
  }  
return miDevolver
}
// ===================================================================
// ==============fin de funciones de fechas ==========================
// ===================================================================

// ===================================================================
// ============== COMBOS  ==========================
// ===================================================================
export function textoDelCombo(miCombo){
  //me devuelve, el texto seleccionado del combo             
  let miValue=miCombo.value 
  let miSelected=null
  for (let i=0; i<miCombo.length; i++){
    if (miCombo[i].value==miValue){
      miSelected=miCombo[i].text
    }
  }
  return miSelected
}
export function limpiarCombo(miCombo){
  //le quito cada uno de los item del combo
  for (let i = (miCombo.options.length-1); i >= 0; i--) {
    miCombo.remove(i)
  }  
}
// =================== FIN DE COMBOS

// ============================================================================
// ====== FECTH CON SWAL DE USO REPETITIVO ===================
// ============================================================================
export async function resolverComprobanteRojoSriConSwal(
  prefijoDoc,miFormato,miEstab,miPunto,numDoc,valueDC,destinoNombre,destinoEmail){
  //se llama UNA sol vez, ya que en el mismo php se hacen varios intentos
  let pepeJson=null
  let seguir=true 
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','gestionar_DocumentoSri_6pasos')   
    data.append('prefijoDoc',prefijoDoc) 
    data.append('miFormato',miFormato) 
    data.append('miEstab',miEstab) 
    data.append('miPunto',miPunto)  
    data.append('numDoc',numDoc)
    data.append('miContext',JSON.stringify(valueDC))  
    data.append('destinoNombre',destinoNombre)  
    data.append('destinoEmail',destinoEmail)   
    data.append('resolverSN','S') //Obviamente quiero resolver
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      pepeJson=await response.json()   
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      /*
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      */
      //rumbo normal, muestro un resumen de los 6 pasos*********
      apagarSwal()
      seguir=false      
    } //del try
    catch (e) {    
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar,  
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          seguir=true
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
    }  //del catch    
  }//del while  
return pepeJson //esta variable de retorno, solo me sirva para tildar y cambiar los colores en el grid de la tabela
}

export async function generarPresupuestoPDFconSwal(formato,estab,punto,numPre,valueDC){  
  //esta funcion se encarga de generar el PDF del presupuesto 
  let pdfCreadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','presupuesto_gestionarGenerarPDF')   
    data.append('Estab',estab) 
    data.append('Punto',punto)  
    data.append('NumPre',numPre)
    data.append('Formato',formato) //aqui va el nombre del formato (comunmente: 'A4v')
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo crear el pdf de la proforma" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      pdfCreadoCorrectamenteSN='S'
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return pdfCreadoCorrectamenteSN
}

export async function generarNotaEntregaPDFconSwal(formato,estab,punto,numNE,valueDC){
  //esta funcion se encarga de generar el PDF, es llamada cuando se desea eviar un correo o imprimir  
  let pdfCreadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','nota_gestionarGenerarPDF')
    data.append('Estab',estab) 
    data.append('Punto',punto)  
    data.append('NumNE',numNE)
    data.append('Formato',formato) //aqui va el nombre del formato (comunmente: 'A4v')
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo crear el pdf de la Nota de Entrega" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      pdfCreadoCorrectamenteSN='S'
      //mostrarSwalBotonAceptar("success","OK","PDF creado correctamente")
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while
  
  return pdfCreadoCorrectamenteSN  
}

/*
export async function generarReciboIngresoPDFconSwal(estab,punto,numRecibo,formato,valueDC){
    //esta funcion se encarga de generar el PDF de un recibo (ingreso) de caja, es llamada cuando se desea eviar un correo o imprimir  
    let pdfCreadoCorrectamenteSN='N'
    let intentos=1
    let seguir=true  
    mostrarSwalEspera()
    while (seguir){
      let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
      let data=new FormData()
      data.append('miSol','reciboingreso_gestionarGenerarPDF')
      data.append('Estab',estab)  
      data.append('Punto',punto) 
      data.append('NumRecibo',numRecibo)
      data.append('NombreFormato',formato) //aqui va el nombre del formato (comunmente: 'A4v')
    
      try{
        let response = await fetch(miPhpFile, { method: 'POST',body:data })
        let pepeJson=await response.json()     
       //analizo la cabecera del JSON de la respuesta
        let hayError=false
        let miE=null
        if (!pepeJson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
        } 
        if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
        }     
        if (hayError){
          throw miE      
        } 
        //rumbo normal*********
        apagarSwal()
        seguir=false
        pdfCreadoCorrectamenteSN='S'
      } //del try
      catch (e) {    
        intentos++
        if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
          await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
        }
        else{  
          //llamo al gestor para que me aparezca la pantalla de REINTENTAR
          apagarSwal()
          let miRespSwal = await gestionarCatch(e)
          //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
          if (miRespSwal.isDenied) {
            mostrarSwalEspera()
            intentos=1
          }
          else{
            //ha pulsado salir en el swal
            seguir=false
          }
        }//del else    
      }  //del catch    
    }//del while
  
  return pdfCreadoCorrectamenteSN  
}
*/

export async function generarCompraPDFconSwal(idR,tipoDocFNL,estab,punto,numFac,valueDC){ 
  //esta funcion se encarga de generar el PDF de alguna compra
  let pdfCreadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','compra_gestionarGenerarPDF')   
    data.append('IDr',idR) //codigo rojo del proveedor
    data.append('TipoDocFNL',tipoDocFNL) //Factura/Nota de venta/Liquidacion (Una sola letra)
    data.append('Estab',estab) 
    data.append('Punto',punto)  
    data.append('NumFac',numFac) //secuencial del documento
    
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo crear el pdf de la compra" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      pdfCreadoCorrectamenteSN='S'
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return pdfCreadoCorrectamenteSN
}

export async function enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal(
destinoNombre,destinoEmail,atachSN,NombreArchivoAdjunto,miContext,miAsunto,miMensaje
){
//envia al cliente la proforma, nota,plan, recibo... (tambien podria ir solamente un  mensaje sin adjunto)
let correoEnviadoCorrectamenteSN='N'
let intentos=1
let seguir=true //seguir en bucle ?
mostrarSwalEspera()
while (seguir){ 
  let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()

  data.append('miSol','correoelectronico_enviarCorreo') 
  data.append('destinoNombre',destinoNombre)
  data.append('destinoEmail',destinoEmail)  
  data.append('atachSN',atachSN) //'S' solamente cuando va un archivo adjunto (comunmente un PDF). 'N' cuando solo va un mensaje simple
    data.append('atachTipo',"NOSRI") //"NOSRI", "SRI"
    data.append('NombreArchivoAdjunto',NombreArchivoAdjunto)     
  //aqui mando las credenciales del correo electronico del emisor (licencia)
  data.append('miContext', JSON.stringify(miContext) ) 
  data.append('asunto',miAsunto)
  data.append('mensaje',miMensaje) 
  //NOTA: nunca enviar texto mezclado con HTML, da error en produccion (#403).  
  try{
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeJson=await response.json() 
    
    //analizo la cabecera del JSON de la respuesta
    let hayError=false
    let miE=null
    
    //hay que evaluar dos casos. a) Cuando se envia Un Archivo adjunto. y b) cuando es solo mensaje (sin adjunto)
    //Me interesa que pepeJson.Otro1Encontrado venga con TRUE
    if (atachSN=="S"){
     if (!pepeJson.CorreoEnviado && pepeJson.Otro1Encontrado){
        hayError=true
        miE="No se pudo enviar el Correo " + pepeJson.CorreoEnviadoError //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
    }
    if (atachSN=="N"){
      if (!pepeJson.CorreoEnviado){
         hayError=true
         miE="No se pudo enviar el Correo " + pepeJson.CorreoEnviadoError //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
       }     
     }

    if (hayError){
      throw miE      
    } 
    //rumbo normal, ya hubo una respuesta *********
    apagarSwal()
    seguir=false
    if (pepeJson.CorreoEnviado){
      //doy el mensaje que todo salio OK
      correoEnviadoCorrectamenteSN='S'
      mostrarSwalBotonAceptar("success","OK","Correo enviado correctamente") //OJO: aqui dejarlo SIN AWAIT para que el llamador ya pueda ir tildando en la BDD
    }
    if (atachSN=="S" && !pepeJson.Otro1Encontrado){
      await mostrarSwalBotonAceptar("error","ERROR","No se encuentra el archivo adjunto")
    }    

  } //del try
  catch (e) {    
    intentos++
    if (intentos<=miContext.sistema.numeroDeReintentosPhp){      
        await sleepPepe(miContext.sistema.milisegundosParaNuevoReintentoPhp)
    }
    else{        
      //llamo al gestor para que me aparezca la pantalla de REINTENTAR
      apagarSwal()
      let miRespSwal = await gestionarCatch(e)
      //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
      if (miRespSwal.isDenied) {
        mostrarSwalEspera()
        intentos=1        
      }
      else{
        //ha pulsado salir en el swal, no hago nada
        seguir=false
      }
    }//del else    
  }  //del catch    
}// del while

return correoEnviadoCorrectamenteSN
}

export async function enviarCorreoSriPdfMasXmlConSwal(
  destinoNombre,destinoEmail,miContext,claveAcceso,prefijoDoc,estab,punto,numDoc,tildarSN){
  //se encarga de enviar un PDF + su correspondiente XML al usuario final
  let correoEnviadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true //seguir en bucle ?
  mostrarSwalEspera()
  while (seguir){ 
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
  
    data.append('miSol','correoelectronico_enviarCorreo') 
    data.append('destinoNombre',destinoNombre)
    data.append('destinoEmail',destinoEmail)
    data.append('claveAcceso',claveAcceso)
    data.append('atachSN','S') //obviamente S para el caso del SRI. se deben enviar siempre dos archivos: PDF + XML descargado
      data.append('atachTipo',"SRI") //"SRI" obligatoriamente para esta funcion
      data.append('tildarSN',tildarSN) //S,N para saber si deseo tildar en la BDD el campo EnviadoAlReceptor.  
      data.append('prefijoDoc',prefijoDoc) //FA,NC,ND,GR,RET,LIQ
      data.append('estab',estab)
      data.append('punto',punto)
      data.append('numDoc',numDoc)
    data.append('miContext', JSON.stringify(miContext) )
    //el asunto y mensaje, lo hago en el archivo ClassEnvioCorreo.php
    //NOTA: nunca enviar texto mezclado con HTML, da error en produccion (#403).  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
      
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      
      //Me interesa que XmlEncontrado && PdfEncontrado vengan con TRUE
      if (!pepeJson.CorreoEnviado && pepeJson.XmlEncontrado && pepeJson.PdfEncontrado){
         hayError=true
         miE="No se pudo enviar el Correo " + pepeJson.CorreoEnviadoError //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }      
      if (!pepeJson.CorreoEnviado && !pepeJson.XmlEncontrado){
         hayError=true
         miE="No se encuentra el xml adjunto" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (!pepeJson.CorreoEnviado && !pepeJson.PdfEncontrado){
         hayError=true
         miE="no se encuentra el pdf adjunto" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }      
      if (hayError){
        throw miE      
      } 
      //rumbo normal, ya hubo una respuesta *********
      apagarSwal()
      seguir=false
  
      if (pepeJson.CorreoEnviado){
        //doy el mensaje que todo salio OK
        correoEnviadoCorrectamenteSN='S'
        mostrarSwalBotonAceptar("success","OK","Correo enviado correctamente") //dejarlo SIN AWAIT, para que el llamador vaya tildando en la bdd
      } 
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=miContext.sistema.numeroDeReintentosPhp){      
          await sleepPepe(miContext.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1        
        }
        else{
          //ha pulsado salir en el swal, no hago nada
          seguir=false
        }
      }//del else    
    }  //del catch    
  }// del while
  
  return correoEnviadoCorrectamenteSN
}

export async function tildarCampoEnviadoPorCorreoNoSriSinSwal(tipoDoc,valor01,estabDoc,puntoDoc,numDoc,valueDC){
    //Esta funcion esta OK (no eliminarla)
    //esta funcion se encarga de tildar el campo ENVIADO en la BDD y no es necesario esperar la respuesta.
    //Me sirve para documentos NO SRI. Proformas, Notas E, Plan, Recibos de caja
    //NO ES NECESARIO CAPTURAR LOS MENSAJES DE ERROR  
    let tildadoCorrectamenteSN='N'
    let intentos=1
    let seguir=true  
    while (seguir){
      let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
      let data=new FormData()
      data.append('miSol','docNoSri_tildarCampoEnviado')
      data.append('TipoDoc',tipoDoc)  
      data.append('Valor01',valor01)  
      data.append('EstabDoc',estabDoc)  
      data.append('PuntoDoc',puntoDoc)   
      data.append('NumDoc',numDoc)    
      try{
        let response = await fetch(miPhpFile, { method: 'POST',body:data })
        let pepeJson=await response.json() 
    
        //analizo la cabecera del JSON de la respuesta
        let hayError=false
        let miE=null
        
        if (!pepeJson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
        } 
        if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
        }     
        if (hayError){
          throw miE      
        } 
              
        //rumbo normal*********
        seguir=false     
        tildadoCorrectamenteSN="S"
      } //del try
      catch (e) {    
        intentos++
        if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
          await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
        }
        else{  
          seguir=false //para que se salga del bucle
        }
      }  //del catch    
    }//del while
  
    return tildadoCorrectamenteSN
}
    
//************************* consulto los datos basicos del firmante de la firma electronica ******
export async function consultarDatosBasicosDeLaFirmaElectronicaConSwal(miContext){
  //se encarga de consultar los datos de la firma, firmante, cedula, ruc, entidad....
  let intentos=1
  let seguir=true //seguir en bucle ?
  let pepeJson=null //es lo que devuelvo al llamador
  mostrarSwalEspera()
  while (seguir){ 
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData() 
    data.append('miSol','sri_consultarDatosBasicosDeLaFirmaElectronica') 
    data.append('miContext', JSON.stringify(miContext)) 
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      pepeJson=await response.json() //NOTA: al no haber internet, entonces no se puede convertir a JSON y se va automaticmente al CATCH y me muestra la pantalla NEGRA            
      
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      
      if (!pepeJson.AgrModConExito){
         hayError=true
         miE="No se pudo ejecutar la solicitud " + pepeJson.MensajeErrorAgrMod; //no cambiar este mensaje para que coincida con otras librerias 
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal, ya hubo una respuesta *********
      apagarSwal()
      seguir=false
  
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=miContext.sistema.numeroDeReintentosPhp){      
          await sleepPepe(miContext.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1        
        }
        else{
          //ha pulsado salir en el swal, no hago nada
          seguir=false
        }
      }//del else    
    }  //del catch    
  }// del while
  
  return pepeJson
}

/*
export async function generarDocumentoXmlConSwal(prefijoDoc,estab,punto,numDoc,miContext){
   //se encarga de generar cualquier documento en formato XML
   let generadoCorrectamenteSN='N'
   let intentos=1
   let seguir=true
   mostrarSwalEspera()
   while (seguir){
     let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
     let data=new FormData()
     data.append('miSol','generarXMLdocumento') 
     data.append('Prefijo',prefijoDoc) 
     data.append('Estab',estab) 
     data.append('Punto',punto)  
     data.append('NumDoc',numDoc)
     try{
       let response = await fetch(miPhpFile, { method: 'POST',body:data })
       let pepeJson=await response.json()  
       console.log(pepeJson)
       //analizo la cabecera del JSON de la respuesta
       let hayError=false
       let miE=null
       if (!pepeJson.ConexionBUC){
         hayError=true
         miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
       } 
       if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
         hayError=true
         miE="No se pudo ejecutar la solicitud " + pepeJson.MensajeErrorAgrMod //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
       }     
       if (hayError){
         throw miE      
       } 
       //rumbo normal*********
       apagarSwal()
       seguir=false
       generadoCorrectamenteSN='S'
       await mostrarSwalBotonAceptar("success","OK","XML creado correctamente")
     } //del try
     catch (e) {    
      console.log('catch')
      console.log(e)
       intentos++
       if (intentos<=miContext.sistema.numeroDeReintentosPhp){      
         await sleepPepe(miContext.sistema.milisegundosParaNuevoReintentoPhp)     
       }
       else{  
         //llamo al gestor para que me aparezca la pantalla de REINTENTAR
         apagarSwal()
         let miRespSwal = await gestionarCatch(e)
         //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
         if (miRespSwal.isDenied) {
           mostrarSwalEspera()
           intentos=1
         }
         else{
           //ha pulsado salir en el swal, no hago nada
           seguir=false
         }
       }//del else    
     }//del catch    
   }//del while   
  return generadoCorrectamenteSN
}
*/

export async function firmarDocumentoXmlConSwal(prefijoDoc,miEstab,miPunto,numDoc,miContext){
  //se encarga de firmar cualquier documento XML
  let firmadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true //seguir en bucle ?
  if (miContext.licencia.FirmaElectronicaUsar=="0"){
    await mostrarSwalBotonAceptar("error","ERROR","No está habilitada la opción de Firma Electrónica")
    return
  }
  mostrarSwalEspera()
  while (seguir){ 
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
  
    data.append('miSol','sri_firmarComprobante') 
    data.append('prefijoDoc',prefijoDoc) //FA,NC,ND,RET,GR,LIQ
    data.append('miEstab',miEstab)
    data.append('miPunto',miPunto)
    data.append('numDoc',numDoc)
    data.append('miContext', JSON.stringify(miContext))  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
      
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      
      if (!pepeJson.AgrModConExito){
         hayError=true
         miE="No se pudo ejecutar la solicitud " + pepeJson.MensajeErrorAgrMod; //no cambiar este mensaje para que coincida con otras librerias 
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal, ya hubo una respuesta *********
      apagarSwal()
      seguir=false
  
      if (pepeJson.AgrModConExito){
        //doy el mensaje que todo salio OK
        firmadoCorrectamenteSN='S'
        await mostrarSwalBotonAceptar("success","OK","XML firmado correctamente")
      } 
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=miContext.sistema.numeroDeReintentosPhp){      
          await sleepPepe(miContext.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1        
        }
        else{
          //ha pulsado salir en el swal, no hago nada
          seguir=false
        }
      }//del else    
    }  //del catch    
  }// del while
  
  return firmadoCorrectamenteSN
  }

//****************PARA RECEPCION/VALIDAR DEL SRI (paso 1) ********************/
export async function recepcionValidarSriConSwal(digitoAmbiente12,archivoFirmado,valueDC){
  //hace la recepcion/validacion por parte del SRI y me devuelve un JSON con varios campos.
  //el archivo se recibe por ejemplo: FA_FIR_001-999-753. Luego en Contr_Generico le antepongo el PATH
  let intentos=1
  let seguir=true //seguir en bucle ?
  let pepeJson=null; //es lo que devuelvo al llamador
  if (valueDC.licencia.FirmaElectronicaUsar=="0"){
    await mostrarSwalBotonAceptar("error","ERROR","No está habilitada la opción de Firma Electrónica")
    return
  }
  mostrarSwalEspera()
  while (seguir){ 
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','sri_recibirComprobante')
    data.append('digitoAmbiente12',digitoAmbiente12)
    data.append('archivoFirmado',archivoFirmado)
    data.append('miContext', JSON.stringify(valueDC))    
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      pepeJson=await response.json()  //NOTA: al no haber internet, entonces no se puede convertir a JSON y se va automaticmente al CATCH y me muestra la pantalla NEGRA      

      //analizo la cabecera del JSON de la respuesta. 
      let hayError=false
      let miE=null       
      if (!pepeJson.ArchivoEncontrado){
        //al no conseguir el archivo xml firmado, se lanza un mensaje
        hayError=true
        miE="no se encuentra el xml firmado"  //no cambiar este mensaje para que coincida con otras librerias:  
      }     
      if (pepeJson.ArchivoEncontrado && !pepeJson.ConexionSRI){
        hayError=true
        miE="" //vacio para obligar la pantalla negra
      }               
      if (hayError){
        throw miE      
      } 
      //rumbo normal, ya hubo una respuesta *********
      apagarSwal()
      seguir=false   
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosSri){      
          await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoSri)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1        
        }
        else{
          //ha pulsado salir en el swal, no hago nada
          seguir=false
        }
      }//del else    
    }  //del catch    
  }// del while

  return pepeJson
}

//*************PARA CONSULTAR/AUTORIZAR EL SRI (paso 2) ************************/
export async function consultarAutorizacionSriConSwal(claveAcceso,valueDC){
  //hace la consulta al SRI y me devuelve un JSON con varios campos.
  let intentos=1
  let seguir=true //seguir en bucle ?
  let pepeJson=null //es lo que devuelvo al llamador
  mostrarSwalEspera()
  while (seguir){ 
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','sri_consultarComprobante')
    data.append('claveAcceso',claveAcceso)
    data.append('miContext', JSON.stringify(valueDC))    
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      pepeJson=await response.json()  //NOTA: al no haber internet, entonces no se puede convertir a JSON y se va automaticmente al CATCH y me muestra la pantalla NEGRA            

      //analizo la cabecera del JSON de la respuesta. 
      let hayError=false
      let miE=null 
      if (!pepeJson.ConexionSRI){
        hayError=true
        miE="" //vacio para obligar la pantalla negra
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal, ya hubo una respuesta *********
      apagarSwal()
      seguir=false   
  
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosSri){      
          await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoSri)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1        
        }
        else{
          //ha pulsado salir en el swal, no hago nada
          seguir=false
        }
      }//del else    
    }  //del catch    
  }// del while

  return pepeJson
}

//************PARA PRODUCTOS/INVENTARIO****************** */
export async function buscarUnProductoPorCodRojoConSwal(codRojo,valueDC){
  //La idea es, buscar UN producto en la BDD y devolverlo al llamador.
  //se devuelve un JSON. (es una copia de lo que me devuelve PHP)
  let intentos=1
  let seguir=true
  let miDevolver //JSON para devolver al llamador
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','inv_buscarxid') //busca solamente UN producto. Por su codigo rojo
    data.append('IDr',codRojo)
      
    try{  
      let res= await fetch(miPhpFile,{method:'POST', body:data } )
      let regBuscado= await res.json() //obtengo el producto buscado 
  
      //analizo la cabecera del JSON de la respuesta, 
      let hayError=false
      let miE=null
      miDevolver=regBuscado //obtengo una copia del todo el objeto para devolver al llamador
      if (!regBuscado.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (regBuscado.ConexionBUC && !regBuscado.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud " + regBuscado.MensajeErrorAgrMod //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      }   
      //Rumbo ideal
      apagarSwal()  
      seguir=false
    } //del try
    catch (e) { 
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{
        //apagarSwal()
        //Solo cuando quiero que aparezca el alert de REINTENTAR (ej: cuando no hay conexion con la BDD )
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR  
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar
        if (miRespSwal.isDenied) {
            mostrarSwalEspera()
            intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
          }
      } //del else       
    } // del catch
  } // del while

  return miDevolver
}

export async function generarDocumentoPDF_SriConSwal(tipoDoc,formato,estab,punto,numDoc,valueDC){
  //El tipoDoc [FA/NC/ND/GR/RET/LIQ]
  //esta funcion se encarga de generar el PDF del sri  (cualquier tipo de documento)
  //cuando todo está bien, entonces en DATA viene el mismo numero de documento solicitado
  let miDevolver=null //en esta variable devuelvo: null/numero negativo/numero positivo/array JSON ....
  let intentos=1
  let seguir=true  
  let miSol=null //nombre de la rutina/solicitud, segun el documento
  switch (tipoDoc) {
    case "FA":
      miSol="facturaSri_gestionarGenerarPDF"
      break
    case "NC":
      miSol="notacreditoSri_gestionarGenerarPDF"
      break
    case "ND":
      miSol="notadebitoSri_gestionarGenerarPDF"
      break    
    case "RET":
      miSol="retencionSri_gestionarGenerarPDF"
      break        
    case "LIQ":
      miSol="liquidacionSri_gestionarGenerarPDF"
      break            
  }    
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol',miSol)   
    data.append('Estab',estab) 
    data.append('Punto',punto)     
    data.append('NumDoc',numDoc)
    data.append('Formato',formato) //aqui va el nombre del formato (comunmente: 'A4v')
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
   
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=> Generar PDF. " + pepeJson.MensajeErrorAgrMod //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      miDevolver=pepeJson.Data
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while
  
  return miDevolver
}
   
/*
export async function generarFacturaDeVentaSimple_ConSwal(formato,estab,punto,numFac,valueDC){
    //esta funcion se encarga de generar el PDF de una factura simple de venta
    let pdfCreadoCorrectamenteSN='N'
    let intentos=1
    let seguir=true  

    mostrarSwalEspera()  
    while (seguir){
      let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
      let data=new FormData()
      data.append('miSol','facturaDeVentaSimple_generarPDF')   
      data.append('Estab',Estab) 
      data.append('Punto',punto)     
      data.append('NumFac',numFac)     
      data.append('Formato',formato) //aqui va el nombre del formato (comunmente: 'A5v')
      try{
        let response = await fetch(miPhpFile, { method: 'POST',body:data })
        let pepeJson=await response.json() 
     
        //analizo la cabecera del JSON de la respuesta
        let hayError=false
        let miE=null
        if (!pepeJson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
        } 
        if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud=> Pdf simple. " + pepeJson.MensajeErrorAgrMod //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
        }     
        if (hayError){
          throw miE      
        } 
        //rumbo normal*********
        apagarSwal()
        seguir=false
        pdfCreadoCorrectamenteSN='S'
      } //del try
      catch (e) {    
        intentos++
        if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
          await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
        }
        else{  
          //llamo al gestor para que me aparezca la pantalla de REINTENTAR
          apagarSwal()
          let miRespSwal = await gestionarCatch(e)
          //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
          if (miRespSwal.isDenied) {
            mostrarSwalEspera()
            intentos=1
          }
          else{
            //ha pulsado salir en el swal
            seguir=false
          }
        }//del else    
      }  //del catch    
    }//del while
    
      return pdfCreadoCorrectamenteSN
}
*/

//***************************************************************************
//************GENERAR REPORTES generalmente con la pantalla negra************
//****************************************************************************    
export async function generarReporteLibroVentasPDFconSwal(valueDC,jsonNegra){
  //esta funcion se encarga de generar el PDF del libro de ventas (obviamente de facturas)
  let pdfCreadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','libroDeVentas_gestionarGenerarPDF')   
    data.append('miLogin',valueDC.usuario.LoginUsu) //solo me sirve para guardar el archivo con este nombre: LIBROventasusuario.pdf
    data.append('miMatriz','') //por ahora vacio. (en el futuro se podria usar para sacar un consolidado corporativo)
    data.append('miEstab',valueDC.usuario.EstabUsu) //a que establecimiento pertenecen las facturas  (es el mismo estab del usuario logeado)
    data.append('miFechaIni',jsonNegra.fechaIni);
    data.append('miFechaFin',jsonNegra.fechaFin);
    data.append('miCondicion',jsonNegra.tipoFacturaTOCOCR);
    data.append('miUsuarios',jsonNegra.usuarios) //usuario/s para el filtro ('0' significa todos)
    data.append('miVendedores',jsonNegra.vendedores) //vendedor/es para el filtro ('0' significa todos)
    data.append('miVendedorNombre',jsonNegra.vendedorNombre) //Solo lo uso para hacer un subtitulo (no afecta el filtro)

    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo crear el pdf del libro de ventas" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      pdfCreadoCorrectamenteSN='S'
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return pdfCreadoCorrectamenteSN
}

export async function generarReporteFacturasAnuladasPDFconSwal(valueDC,jsonNegra){
  //esta funcion se encarga de generar el PDF de facturas Anuladas
  let pdfCreadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','facturasAnuladas_gestionarGenerarPDF')   
    data.append('miLogin',valueDC.usuario.LoginUsu) //solo me sirve para guardar el archivo con este nombre: FACTURASanuladasusuario.pdf
    data.append('miMatriz','') //por ahora vacio. (en el futuro se podria usar para sacar un consolidado corporativo)
    data.append('miEstab',valueDC.usuario.EstabUsu) //a que establecimiento pertenecen las facturas  (es el mismo estab del usuario logeado)
    data.append('miFechaIni',jsonNegra.fechaIni);
    data.append('miFechaFin',jsonNegra.fechaFin);

    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo crear el pdf del libro de ventas" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      pdfCreadoCorrectamenteSN='S'
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return pdfCreadoCorrectamenteSN
}

export async function generarReporteLibroVentasNotasEntregaPDFconSwal(valueDC,jsonNegra){
  //esta funcion se encarga de generar el PDF del libro de ventas pero de NOTAS DE ENTREGA
  let pdfCreadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','libroDeVentasNotasEntrega_gestionarGenerarPDF')   
    data.append('Login',valueDC.usuario.LoginUsu) //solo me sirve para guardar el archivo con este nombre: LISTAnotas_usuario.pdf
    data.append('Matriz','') //por ahora vacio. (en el futuro se podria usar para sacar un consolidado corporativo)
    data.append('Estab',valueDC.usuario.EstabUsu) //a que establecimiento pertenecen las notas  (es el mismo estab del usuario logeado)
    data.append('FechaIni',jsonNegra.fechaIni);
    data.append('FechaFin',jsonNegra.fechaFin);
    data.append('Usuarios',jsonNegra.usuarios) //usuario/s para el filtro ('0' significa todos)
    data.append('Vendedores',jsonNegra.vendedores) //vendedor/es para el filtro ('0' significa todos)
    data.append('VendedorNombre',jsonNegra.vendedorNombre) //Solo lo uso para hacer un subtitulo (no afecta el filtro)

    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo crear el pdf de la lista de notas" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      pdfCreadoCorrectamenteSN='S'
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return pdfCreadoCorrectamenteSN
}

export async function registrarPagoDeCxCconSwal(estabDoc,puntoDoc,numDoc,tipoDoc,valueDC,montoRecibo,deseoAC,miJsonDP){
  //esta funcion registra un pago de una factura a credito / o de un plan  
  //puede ser un Abono o la cancelacion completa. 
  let numeroDevuelto=-333 //numero que devuelve sql. Al ser negativo hubo algun error
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','cxc_abonarCancelar')  //sirve para FActura y PLan 
    data.append('EstabDoc',estabDoc) //EstabDoc,PuntoDoc,NumDoc son los datos del documento que deseo abonar/cancelar 
    data.append('PuntoDoc',puntoDoc)  
    data.append('NumDoc',numDoc)
    data.append('TipoDoc',tipoDoc) //"FA"ctura o "PL"an (2 letras en mayuscula)
    data.append('EstabRec',valueDC.usuario.EstabUsu) //EstabRec,PuntoRec, son los datos con que se hara el recibo (deben ser los datos del usuario logeado)
    data.append('PuntoRec',valueDC.usuario.PuntoUsu)  
    data.append('MontoRecibo',montoRecibo) //monto que se abona/cancela
    data.append('DeseoAC',deseoAC) //deseo Abonar o Cancelar? (1 letra en mayuscula)
    data.append('OpCrea',valueDC.usuario.LoginUsu) //operador que crea el recibo
    data.append('MiJsonDP',JSON.stringify(miJsonDP)) //JSON con el detalle del pago (se lo envio en formato texto)
    data.append('ZonaHoraria',valueDC.licencia.ZonaHoraria) //Para poder generar la fecha del recibo (generalmente Guayaquil)
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo registrar el recibo" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      numeroDevuelto=pepeJson.Data //atrapo el numero de recibo generado por sql
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return numeroDevuelto
}

/*
export async function eliminarPagoDeCxCconSwal(estabRec,puntoRec,numRecibo,valueDC){
  //esta funcion elimina de la BDD un recibo de ventas (para FACTURAS o PLANES). 
  //Nota: si la factura era al contado, no se puede eliminar. Sql Devuelve -110
  let numeroDevuelto=-333 //numero que devuelve sql. Al ser negativo hubo algun error
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','cxc_eliminarRecibo')  //sirve para FActura y PLan 
    data.append('EstabRec',estabRec) 
    data.append('PuntoRec',puntoRec)  
    data.append('NumRecibo',numRecibo)  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo eliminar el recibo" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      numeroDevuelto=pepeJson.Data //atrapo el numero enviado desde sql
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return numeroDevuelto
}
*/

// *********************************************************
// ***** temas relacionados con compras ****************** 
// *********************************************************
export async function procesarCompraEnBDD(valueDC,caPiePhp,subtotalFacturaPhp,vectorDV,datosFormaPago){
  // Cuando la compra es a credito, entonces el objeto datosFormaPago se va con NULL
  
  let numeroDevuelto=-3 //numero que devuelve sql. Al ser negativo hubo algun error  
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','procesarcompraEnBDD') 
    data.append('miContext',JSON.stringify(valueDC)) //le mando el context completo
    data.append('caPiePhp',JSON.stringify(caPiePhp)) //Cabecera y Pie de la factura     
    data.append('subtotalFacturaPhp',JSON.stringify(subtotalFacturaPhp)) //Subtotales del documento, son varios casilleros
    data.append('miJsonDV',JSON.stringify(vectorDV)) //Aqui se va el detalle de la compra (el grid)
    data.append('miJsonDP',JSON.stringify(datosFormaPago)) //Aqui se va el detalle de la Forma de Pago        
  try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo registrar la compra..." //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      numeroDevuelto=pepeJson.Data //atrapo el numero enviado desde sql
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return numeroDevuelto  
}

export async function registrarPagoDeCxPconSwal(IDr,TipoDocFNL,Estab,Punto,NumFac,DeseoAC,MontoRecibo,MiJsonDP,valueDC){
  //esta funcion registra un pago de una compra a credito
  //puede ser un Abono o la cancelacion completa. 
  let numeroDevuelto=-333 //numero de recibo de caja que devuelve sql. Al ser negativo hubo algun error
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','cxp_abonarCancelar')   
    data.append('IDr',IDr)  
    data.append('TipoDocFNL',TipoDocFNL)  
    data.append('Estab',Estab)  
    data.append('Punto',Punto)  
    data.append('NumFac',NumFac)
    data.append('DeseoAC',DeseoAC) //deseo "A"bonar o "C"ancelar? (1 letra en mayuscula)
    data.append('MontoRecibo',MontoRecibo) //monto que se abona/cancela
    data.append('MiJsonDP',JSON.stringify(MiJsonDP)) //JSON con el detalle del pago (se lo envio en formato texto)
    data.append('miContext',JSON.stringify(valueDC)) //le mando el context completo    

    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo registrar el recibo" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      numeroDevuelto=pepeJson.Data //atrapo el numero de recibo generado por sql
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return numeroDevuelto
}

export async function eliminarPagoDeCxPconSwal(IDr,estabRec,puntoRec,numRecibo,valueDC){
  //esta funcion elimina de la BDD un recibo de egreso (de alguna compra) (para FACTURAS o NOTA DE VENTA o LIQUIDACION). 
  //Nota: si la compra era al contado, no se puede eliminar. Sql Devuelve -110
  let numeroDevuelto=-333 //numero que devuelve sql. Al ser negativo hubo algun error
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','cxp_eliminarRecibo')  //sirve para Factura Nota de venta o Liquidacion
    data.append('IDr',IDr) 
    data.append('EstabRec',estabRec) 
    data.append('PuntoRec',puntoRec)  
    data.append('NumRecibo',numRecibo)  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo eliminar el recibo" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      numeroDevuelto=pepeJson.Data //atrapo el numero enviado desde sql
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return numeroDevuelto
}

export async function generarReciboEgresoPDFconSwal(estab,punto,numRecibo,formato,valueDC){
  //esta funcion se encarga de generar el PDF de un recibo (egreso) de caja, es llamada cuando se desea enviar un correo o imprimir  
  let pdfCreadoCorrectamenteSN='N'
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','reciboegreso_gestionarGenerarPDF')
    data.append('Estab',estab)  
    data.append('Punto',punto) 
    data.append('NumRecibo',numRecibo)
    data.append('NombreFormato',formato) //aqui va el nombre del formato (comunmente: 'A4v')
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json()     
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      pdfCreadoCorrectamenteSN='S'
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

return pdfCreadoCorrectamenteSN  
}

//************************************************* */
//************************ CLONAR PRESUPUESTO ****** */
//************************************************* */
//tengo varias funciones para este proposito: la idea es traerme un chorizo con todos los productos/cant/precios... de un Presupuesto especifico para clonar o modificar
export async function buscarProformaDetalleParaClonarModificar(estab,punto,numPre,valueDC){
  //esta funcion me trae un JSON con toda la informacion de la proforma (me apoyo en la consulta de PHP: buscarPresupuestoImprimir)
  let contenidoJsonDevolver=null //en un array de objetos
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','buscarPresupuestoImprimir')
    data.append('miEstab',estab) 
    data.append('miPunto',punto)  
    data.append('miNumPre',numPre)
    data.append('orderBy','FilaD')
    data.append('forma','asc')
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo leer la Proforma" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********      
      apagarSwal()
      contenidoJsonDevolver= pepeJson
      seguir=false
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while
  
  return contenidoJsonDevolver  //cuando hay error devuelve null (pero al no conseguir el documento devuelve Data Vacio)
}

//****************************************************** */
//************************ RETENCIONES ELABORADAS ****** */
//****************************************************** */
// ** Buscar cabecera para hacer una retencion
export async function buscarCabeceraEnRetencionesElaboradasConSwal(IDr,TipoDocFNL,estab,punto,num,valueDC){
  //esta funcion me trae un JSON con la cabecera del documento que pretendo hacer la retencion 
  let contenidoJsonDevolver=null //debe devolver un array de una sola fila
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','retela_buscarCabecera') //retenciones elaboradas_buscar cabecera
    data.append('IDr',IDr) 
    data.append('TipoDocFNL',TipoDocFNL) 
    data.append('Estab',estab) 
    data.append('Punto',punto)  
    data.append('Num',num)
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo leer el encabezado" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********      
      apagarSwal()
      contenidoJsonDevolver= pepeJson.Data
      seguir=false
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while
  
  return contenidoJsonDevolver   
}

//***** buscar TODOS los codigos de renta */
export async function buscarCodigosRentaRetencionConSwal(orderBy,forma,valueDC){
  //esta funcion me trae un JSON con TODOS los registros de la tabla: retenciones_codigos_renta (incluye activos + inactivos)
  let contenidoJsonDevolver=null //debe devolver un array de una o varias fila
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','ret_buscarTodosCodigosRenta')  
    data.append('orderBy',orderBy)  
    data.append('forma',forma)
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo leer los codigos de Renta" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********      
      apagarSwal()
      contenidoJsonDevolver= pepeJson.Data
      seguir=false
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while
  
  return contenidoJsonDevolver   
}

//***** buscar TODOS los codigos de iva */
export async function buscarCodigosIvaRetencionConSwal(orderBy,forma,valueDC){
  //esta funcion me trae un JSON con TODOS los registros de la tabla: retenciones_codigos_iva (incluye activos + inactivos)
  let contenidoJsonDevolver=null //debe devolver un array de una o varias fila
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','ret_buscarTodosCodigosIva')  
    data.append('orderBy',orderBy)  
    data.append('forma',forma)
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo leer los codigos de Iva" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********      
      apagarSwal()
      contenidoJsonDevolver= pepeJson.Data
      seguir=false
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while
  
  return contenidoJsonDevolver   
}

export async function procesarRetencionEnComprasEnBDDconSwal(
  miJsonRenta,miJsonIva,valueDC,tipoDocFNL,IDr,estabDG,puntoDG,numDG,fechaDGamd,observaciones
  ){   
  let numeroDevuelto=-3 //numero que devuelve sql. Al ser negativo hubo algun error  
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()  
  while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','procesarRetencionEnComprasEnBDD') 
    data.append('miJsonRenta',JSON.stringify(miJsonRenta)) //detalle de la retencion en Renta
    data.append('miJsonIva',JSON.stringify(miJsonIva)) //detalle de la retencion en Iva
    data.append('miContext',JSON.stringify(valueDC)) //le mando el context completo
    data.append('tipoDocFNL',tipoDocFNL)  
    data.append('IDr',IDr)  
    data.append('estabDG',estabDG)  
    data.append('puntoDG',puntoDG)  
    data.append('numDG',numDG)  
    data.append('fechaDG',fechaDGamd) //con el formato aaaa-mm-dd
    data.append('observaciones',observaciones)

  try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo registrar la retención..." //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      seguir=false
      numeroDevuelto=pepeJson.Data //atrapo el numero enviado desde sql
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while

  return numeroDevuelto  
}

// ** Buscar una lista con las retenciones elaboradas (solo titulos, no trae el detalle) a una compra (comunmente deberia dar UNA fila, pero podrían ser varias)
export async function listarRetencionesAsociadasAunaCompraConSwal(IDr,TipoDocFNL,estab,punto,num,valueDC){
  //esta funcion me trae un JSON con la cabecera de las retenciones hechas a una determinada compra 
  let contenidoJsonDevolver=null //debe devolver un array de una sola fila
  let intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile=buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','retela_listarRetencionesAsociadasAunaCompra')  
    data.append('IDr',IDr) //IDr rojo del proveedor
    data.append('TipoDocFNL',TipoDocFNL) 
    data.append('Estab',estab) //Datos del documento Generador
    data.append('Punto',punto)  
    data.append('Num',num)
  
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
     //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud=>" + " No se pudo leer el encabezado" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********      
      apagarSwal()
      contenidoJsonDevolver= pepeJson.Data
      seguir=false
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }//del while
  
  return contenidoJsonDevolver   
}
export async function ejecutarFetchGenericoConSwal( reintentosMaximo,milisegundosEspera,data ){
  //Me sirve para leer clientes,inventario,cualquier solicitud......De uso genérico vacán
  //Obviamente también para correr un StoreProcedure
  // ===== Entrada
    //3 variables, la ultima es un FormData()
  // ===== Salida
    //Normalmente, devuelve un JSON ya con los datos que viene desde php en mi respuesta DATA (por ejemplo una lista de clientes, lista de planes, ... osea un array de registros)
    //Alomejor []. Osea vacío cuando el (select * from tabla where ..... ) no hay registros coincidentes
    //En algunos casos devuelve solamente un entero (por ejemplo el codigo rojo cuando ejecuto un SP y me devuelve un AUTONUMERICO).
    //Numero negativo cuando a propósito se ha generado un error (por ejemplo clave duplicada, -100). Pero no viene como error sino como respuesta normal pero en negativo
    //null. hubo un error en la conexión, o en la misma BDD. Para ahorrar problemas entonces devuelvo al llamador NULL
    //lo que voy a devolver lo inicializo en null. Luego el llamador verá como lo maneja

  let intentos=1
  let seguir=true //seguir en blucle ?
  let miDevolver=null //en esta variable devuelvo: null/numero negativo/numero positivo/array JSON ....

 mostrarSwalEspera() //1
 //console.log('intento antes de entrar al while => ', intentos)
  while (seguir){ 
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data }) //dentro de data se va también la solicitud que le hago a PHP

    //console.log('intento dentro del while => ', intentos)
    //console.log(response)
    //console.log(response.status)
    //console.log('=================')
 
      let pepeJson=await response.json()      
    
    //console.log(pepeJson)      
      
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud " + pepeJson.MensajeErrorAgrMod //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      miDevolver=pepeJson.Data //Este es el unico sitio donde se cambia el valor de la varaible miDevolver
      apagarSwal() //2
      seguir=false
    } //del try
    catch (e) {    
      //console.log('estoy en el catch. linea 2933 => ', intentos)
      intentos++
      if (intentos<=reintentosMaximo){      
          await sleepPepe(milisegundosEspera)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal() //3
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera() //4
          intentos=1        
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }//del catch    
  }// del while

  //console.log('mi devolver==> ' , miDevolver)
  return miDevolver
}

// ============================================================================
// ====== FIN DE FECTH CON SWAL DE USO REPETITIVO/GENERICO ===================
// ============================================================================
