import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
import buscarPhpPath, { sleepPepe,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//variables normales que no son del estado
let valueDC=null //Aqui hago una copia de los valores del context
let intentos=0
let textoHash=null //aqui guardo la respuesta encriptada

const EncriptarTextoEnPHP =(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const encriptarYmostrar=async()=>{
  let textoPlano=(document.getElementById("txtClave").value).trim()
  //textoPlano=textoPlano.toLowerCase() 

  //hago la encriptacion
  intentos=1
  let seguir=true //seguir en blucle ?
  mostrarSwalEspera()
  while (seguir){ 
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','encriptar_enphp')
    data.append('textoPlano',textoPlano)
    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson=await response.json() 
  
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      /*
      if (!pepeJson.Estatus){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      */
      if (pepeJson.Estatus==false){
        hayError=true
        miE="No se pudo ejecutar la solicitud " + pepeJson.Error //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********
      apagarSwal()
      textoHash=pepeJson.TextoHash
      seguir=false
      alert(textoHash)
    } //del try
    catch (e) {    
     intentos++
      if (intentos<=3){      
        await sleepPepe(6000)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
         intentos=1        
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
        }
      }//del else    
    }  //del catch    
  }// del while
}
 
//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{ 
  document.getElementById("txtClave").focus()    

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //por ahora, nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{ 
return(
<form>
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Boton Cerrar*/}
  <Row style={{marginTop:"2px"}}>
    <Col xs="10" >
      <Label style={{marginLeft:'2%',fontWeight: 'bold'}}>{'Indíque un texto para encriptar en PHP'}</Label>
    </Col>
    <Col xs="2">
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{props.cerrarModal()  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 
  <div style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink'}}>
    <Row >
      <Col xs="7" >
        <Input style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px' }} name="txtClave" id="txtClave"      
        onKeyDown = {(e) => { 
          if ( e.key =="Enter"  ){ 
            //e.preventDefault()   //obligatorio ponerlo
           encriptarYmostrar()                                 
          }
        }}/>      
      </Col>
      <Col xs="5" style={{textAlign:'center',}}>    
        <Button style={{width:'120px', height:'120px',fontSize:'60px',}} id="btnEnter" name="btnEnter" color="success" 
          onClick= {()=> {
           encriptarYmostrar() 
          }}>
          <FontAwesomeIcon color="purple" icon={faCheck} /> 
        </Button>
      </Col>         
    </Row>  
    </div>
  </div> {/* //div master */}
</form>               
)}

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} // de falta copiar a Corona

export default EncriptarTextoEnPHP
