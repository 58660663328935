//alert(JSON.stringify(jsonNegra,null,2))
//para mostrar en JS la fecha actual, con let f=new Date() me devuelve: Tue Nov 02 2021 20:32:22 GMT-0500 (hora de Ecuador)
//NOTA IMPORTANTE: todos los Row, deben tener ajuro margin:'0' listo ok
import React, {useState,useEffect} from 'react'
import {Row,Col,Button,Input,Dropdown,DropdownItem, DropdownMenu,DropdownToggle,Modal} from 'reactstrap'
import buscarPhpPath, {devolverFechaHoyTextoYYYYMMDD,buscarHostnameSegunJs,consultarDatosBasicosDeLaFirmaElectronicaConSwal,mostrarSwalBotonAceptar,generarReporteLibroVentasPDFconSwal,generarReporteFacturasAnuladasPDFconSwal,generarReporteLibroVentasNotasEntregaPDFconSwal,hayInternet, procesarCompraEnBDD,sleepPepe } from './lib_basica'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPercent,faFilePowerpoint, faBook, faObjectGroup,faBox, faTh, faFileInvoiceDollar,faObjectUngroup, faMap,faBoxes,faCartPlus,faFileSignature, faNewspaper,faFileInvoice ,faPowerOff, faRunning,faUsers,faUserFriends,faUserAlt,faUser,faUserPlus,faUserAltSlash,faLightbulb,faCartArrowDown, faShoppingCart, faCubes,faBookReader,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faTools, faStepBackward, faDollar } from '@fortawesome/free-solid-svg-icons' 
//componentes a llamar desde el menu principal
import TabelaClientes from './TabelaClientes'
import TabelaClientesMorosos  from './TabelaClientesMorosos'
import TabelaPlanesDePago from './TabelaPlanesDePago'
import TabelaFacturasLista from './TabelaFacturasLista'
import TabelaInteres from './TabelaInteres'
import MostrarDatosBasicosFirmaElectronica from './MostrarDatosBasicosFirmaElectronica'
import EncriptarTextoEnJS from './EncriptarTextoEnJS'
import EncriptarTextoEnPHP from './EncriptarTextoEnPHP'
import VerContext from './VerContext'
import VerPdfOk from './VerPdfOk'
//*****pantallas negra para reportes***
import PantNegraLibroVentas from './PantNegraLibroVentas'
import PantNegraPedirFechaIniFechaFin from './PantNegraPedirFechaIniFechaFin'
//del context
import {AppContextConsumer} from './ContextBase'
import { type } from '@testing-library/user-event/dist/type'
import PedirFechaDeCorte from './PedirFechaDeCorte'
//Variables normales
let valueDC=null //Aqui hago una copia de los valores del context  
let pepeJsonConsultarFirma=null //aqui guardo los datos de la firma
let pdfCreadoCorrectamenteSN=null //para generar algun pdf
let diaTransito=null // para pasar de una pantalla a otra (fecha)
let mesTransito=null // para pasar de una pantalla a otra (fecha)
let anoTransito=null // para pasar de una pantalla a otra (fecha)

const MenuPrincipalBonanza=(props)=>{
  const [nombreComponenteParaVer,setNombreComponenteParaVer]=useState('menu') //Nombre del componente que deseo ver. Arranca obviamente con el menu principal
  const [modalTabelaInteres,set_modalTabelaInteres]=useState(false)  
  const [modalDatosFirma,set_modalDatosFirma]=useState(false)   
  const [modalEncriptarEnJS,set_modalEncriptarEnJS]=useState(false)     
  const [modalEncriptarEnPHP,set_modalEncriptarEnPHP]=useState(false)       
  const [modalVerContext,set_modalVerContext]=useState(false)         
  const [modalPedirFechaCorte,set_modalPedirFechaCorte]=useState(false)         
  const [modalLibroVentasNegra,set_modalLibroVentasNegra]=useState(false)         
  const [modalLibroVentasPDF,set_modalLibroVentasPDF]=useState(false)           
  const [modalFacturasAnuladasNegra,set_modalFacturasAnuladasNegra]=useState(false) 
  const [modalFacturasAnuladasPDF,set_modalFacturasAnuladasPDF]=useState(false)                       
  const [dropUtilidades,set_dropUtilidades]=useState(false)   

const gestionarConsultarDatosBasicosDeLaFirmaElectronica=async()=>{
  //primero reviso que tenga activada la opcion de usar la firma
  if(valueDC.licencia.FirmaElectronicaUsar=="0" || !valueDC.licencia.FirmaElectronicaUsar ){
    await mostrarSwalBotonAceptar("error","ERROR","No está habilitada la opción de Firma Electrónica")
    return
  }

  pepeJsonConsultarFirma=await consultarDatosBasicosDeLaFirmaElectronicaConSwal(valueDC) 
  //primero hay que revisar que pepeJsonConsultarFirma.AgrModConExito este true. 
  if (pepeJsonConsultarFirma.AgrModConExito==false) return
  
  await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
  set_modalDatosFirma(true)
}

const despedirseSonido=()=>{
  //hay que revisar si tiene preferencias de sonido
  if (valueDC.licencia.SonidosVoz=="0") return

  speechSynthesis.speak(new SpeechSynthesisUtterance(valueDC.licencia.DespedidaHablada))
}

const buscarHostnameSegunPhp=async()=>{
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','host_buscarHostnameSegunPhp')
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeText = await response.text() 

    alert(pepeText)
  }
  catch{
  }
}

//detectar el nombre de la BDD en produccion (la idea es que sea el mismo del subdominio)
const detectarNombreDeBDD=async()=>{
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','host_detectarNombreDeBDD')
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeText = await response.text() 

    alert(pepeText)
  }
  catch{
  }
}

const buscarPathDeLaAplicacion=async()=>{
  //document.location.hostname //me devuelve (localhost para local) o (www.sistemaspaladino.com para remoto) **** OK ***
  alert("document.location.hostname=> " + document.location.hostname)
  alert("document.domain=> " + document.domain) //devuelve localhost
  alert("document.URL=> " + document.URL)   //devuelve http://localhost:3000/
}

const verAmbiente=async()=>{
  if (valueDC.licencia.AmbienteActualPRUPRO=="PRU")
    alert("Prueba")
  if (valueDC.licencia.AmbienteActualPRUPRO=="PRO")
    alert("Produccion")      
}

const activarMenu=() => {
  setNombreComponenteParaVer('menu') 
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //falta probar en produccion
  //reviso que se esté usando la ultima version del front. (al ser diferenyte entonces forzo un reload)
              //alert(1.00=="1.00")// true
            //alert(1.00=="1.0") // true
            //alert(1=="1.00") //true
  if (valueDC.licencia.FrontVersion != props.frontVersion){
    //alert('diferente')
    console.log('recargando....')
    window.location.reload()
  }
},[]
)

const ComponentePrincipalBody=()=> {   
return (
<div id="divMaster" name="divMaster" className="classContainerMenuPrincipal">
  <div id="divSubMaster" name="divSubMaster"> 
    {/******* Boton superior de OPCIONES (utilidades) ************/}
    <Row style={{margin:'0',paddingLeft:'10px'}} >     
      <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }} id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidades }  size="md"
       toggle= { ()=> {
        set_dropUtilidades(!dropUtilidades) 
      }}>

      <DropdownToggle caret color="primary">
        Opciones
      </DropdownToggle>
      <DropdownMenu style={{backgroundColor:'cyan',}} >
        <DropdownItem onClick={ () => set_modalLibroVentasNegra(true) }>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Libro de ventas
        </DropdownItem>         
        <DropdownItem onClick={ () => { set_modalFacturasAnuladasNegra(true) }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Facturas anuladas</DropdownItem>      
        <DropdownItem divider />                     

        <DropdownItem onClick={ () => alert( buscarHostnameSegunJs() )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> HostName segun JS</DropdownItem>        
        <DropdownItem onClick={ async() => { 
          if (await hayInternet()==false) return
          buscarHostnameSegunPhp() 
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> HostName segun PHP</DropdownItem>         
        <DropdownItem onClick={ () => buscarPathDeLaAplicacion() }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> PATH de la aplicacion</DropdownItem>                
        <DropdownItem onClick={ async() => {
          if (await hayInternet()==false) return
          detectarNombreDeBDD() 
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Nombre de la BDD en hosting</DropdownItem>         
        <DropdownItem onClick={ async() => {
            await gestionarConsultarDatosBasicosDeLaFirmaElectronica() 
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Consultar datos de la firma electrónica</DropdownItem>
        <DropdownItem onClick={ () => set_modalEncriptarEnJS(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Encriptar en JS</DropdownItem>        
        <DropdownItem onClick={ () => set_modalEncriptarEnPHP(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Encriptar en PHP</DropdownItem>        
        <DropdownItem onClick={ () => set_modalVerContext(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Ver Context</DropdownItem>        
        <DropdownItem onClick={ () => verAmbiente() }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Ver Ambiente</DropdownItem>
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>
      </Dropdown>
    </Row>

    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnClientes" name="btnClientes" className="BotonMenuPpal"
            onClick = { async() => {
              if (await hayInternet()==false) return
              setNombreComponenteParaVer('clientes') 
            }} >  
          <span style ={{ fontSize:"40px"}}>
          <FontAwesomeIcon color="paleGreen" icon={faUsers}/> 
          </span>
          <span><br/>CLIENTES</span>
        </Button>
      </Col>
      <Col style={{textAlign:'right'}}>
        <Button id="btnPlanes" name="btnPlanes" className="BotonMenuPpal"
          onClick = { async() => { 
            if (await hayInternet()==false) return
            //verifico si el usuario es limitado. (entonces me devuelvo)
            if (valueDC.usuario.CategoriaUsu=="L") return
            //rumbo normal
            setNombreComponenteParaVer('planes') 
            }}>  
          <span style ={{ fontSize:"40px"}}>
          <FontAwesomeIcon color="paleGreen" icon={faLightbulb} />
          </span>
          <span><br/>PLANES</span>
        </Button>
      </Col>      
    </Row>
    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnMorosos" name="btnMorosos"  className="BotonMenuPpal"
          onClick = { async() => { 
            set_modalPedirFechaCorte(true)
          }}>  
          <span style ={{ fontSize:"40px"}}>
            <FontAwesomeIcon color="paleGreen" icon={faDollar}/> 
          </span>
          <span><br/>MOROSOS</span>
        </Button>
      </Col>
      <Col style={{textAlign:'right'}}>

      </Col>      
    </Row>
    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnInteres" name="btnInteres"  className="BotonMenuPpal"
          onClick = { async() => { 
            set_modalTabelaInteres(true)
          }}>  
          <span style ={{ fontSize:"40px"}}>
            <FontAwesomeIcon color="paleGreen" icon={faPercent}/> 
          </span>
          <span><br/>INTERESES</span>
        </Button>
      </Col>
      <Col style={{textAlign:'right'}}>
        <Button id="btnFacturas" name="btnFacturas" className="BotonMenuPpal"
          onClick = { async() => {
            if (await hayInternet()==false) return
            setNombreComponenteParaVer('lista de facturas') 
            } } >  
          <span style ={{ fontSize:"40px"}}>
          <FontAwesomeIcon color="paleGreen" icon={faMap} />
          </span>
          <span><br/>FACTURAS</span>
        </Button>
      </Col>      
    </Row>    
    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnLogin" name="btnLogin" className="BotonMenuPpalSalir"
          onClick = { () => { 
            //borro la sessionStore
            sessionStorage.removeItem("usuario")
            //mando un sonido que diga hasta luego o algo parecido.
            despedirseSonido()
            props.regresarAlLogin()
            } } >  
          <span style ={{ fontSize:"40px"}}>
          <FontAwesomeIcon color="paleGreen" icon={faPowerOff} /> 
          </span>
          <span><br/>Salir</span>          
        </Button>
      </Col>      
      <Col style={{textAlign:'right'}}>
        <Button id="btnConfig" name="btnConfig" className="BotonMenuPpal"  
          onClick = { async() => {  
            //alert(1.00=="1.00")// true
            //alert(1.00=="1.0") // true
            //alert(1=="1.00") //true

            //nada
          }}>  
          <span style ={{ fontSize:"40px"}}>
            <FontAwesomeIcon color="paleGreen" icon={faTools}/> 
          </span>
          <span><br/>AJUSTES</span>
        </Button>      
      </Col>      
    </Row>

  </div>  {/* divSubMaster */}

  {/************ MODAL PARA VER LOS DATOS DE LA FIRMA ELECTRONICA *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalDatosFirma } >
    <MostrarDatosBasicosFirmaElectronica
      cerrarModal={()=> {
        set_modalDatosFirma(false)
      }}
      pepeJson={ pepeJsonConsultarFirma }      
    />
  </Modal>   

  {/* *********** MODAL PARA VER LA TABELA DE INTERES ************ */}
  <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalTabelaInteres } >
    <TabelaInteres
      cerrarModal={()=> {
        set_modalTabelaInteres(false)
      }}  
    />
  </Modal>     

  {/************ MODAL PARA ENCRIPTAR EN JS *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalEncriptarEnJS } >
    <EncriptarTextoEnJS
      cerrarModal={()=> {
        set_modalEncriptarEnJS(false)
      }}
    />
  </Modal>   

  {/************ MODAL PARA ENCRIPTAR EN PHP *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalEncriptarEnPHP } >
    <EncriptarTextoEnPHP
      cerrarModal={()=> {
        set_modalEncriptarEnPHP(false)
      }}
    />
  </Modal>

  {/************ MODAL PARA VER EL CONTEXT *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalVerContext } >
    <VerContext
      cerrarModal={()=> {
        set_modalVerContext(false)
    }}
    />
  </Modal>   

  {/************ MODAL PARA PEDIR FECHA DE CORTE *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalPedirFechaCorte } >
    <PedirFechaDeCorte
      ocultarModal_Fecha={(accion,fechaObjeto)=> {
        set_modalPedirFechaCorte(false)
        //accion puede ser: close/save
        if (accion=='close') return
        //la fecha la recibo como Objeto
        diaTransito=fechaObjeto.getDate()
        mesTransito=fechaObjeto.getMonth()+1
        anoTransito=fechaObjeto.getFullYear()
        setNombreComponenteParaVer('morosos') 
      }}
      Titulo={'Estudiantes Morosos'} //va sin titulo
      TituloTab={'Fecha de corte'}
    />
  </Modal>    

  {/************ MODAL PARA PEDIR PARAMETROS PARA LIBRO DE VENTAS DE FACTURAS *************/}
  <Modal style={{ backgroundColor:'black',}} size={'md'}  isOpen={ modalLibroVentasNegra } >
    <PantNegraLibroVentas
      miTitulo={"Libro de ventas"}
      botonExcelSN={"N"}
      cerrarModal={async(seguir,jsonNegra,botonEP)=> {
        set_modalLibroVentasNegra(false)
        if (!seguir) return
        //alert(JSON.stringify(jsonNegra))

        if (botonEP=="P"){
          //debo generar el PDF y luego lo muestro  
          pdfCreadoCorrectamenteSN=await generarReporteLibroVentasPDFconSwal(valueDC,jsonNegra)
          if (pdfCreadoCorrectamenteSN=="S"){
            set_modalLibroVentasPDF(true)
          }
        }
        if (botonEP=="E"){
          //debo generar el EXCEL  y luego supongo se descarga al pc del cliente
          //alert('excel')
        }            
    }}
    />
  </Modal>     

  {/************ MODAL PARA PEDIR PARAMETROS PARA FACTURAS ANULADAS *************/}
  <Modal style={{ backgroundColor:'black',}} size={'md'}  isOpen={ modalFacturasAnuladasNegra } >
    <PantNegraPedirFechaIniFechaFin
      miTitulo={"Facturas Anuladas"}
      botonExcelSN={"N"}
      cerrarModal={async(seguir,jsonNegra,botonEP)=> {
        set_modalFacturasAnuladasNegra(false)
        if (!seguir) return
        //alert(JSON.stringify(jsonNegra))

        if (botonEP=="P"){
          //debo generar el PDF y luego lo muestro  
          pdfCreadoCorrectamenteSN=await generarReporteFacturasAnuladasPDFconSwal(valueDC,jsonNegra)
          if (pdfCreadoCorrectamenteSN=="S"){
            set_modalFacturasAnuladasPDF(true)
          }                   
        }
        if (botonEP=="E"){
          //debo generar el EXCEL  y luego supongo se descarga al pc del cliente
          alert('excel')
        }            
      }}
    />
  </Modal>    

  {/* *********** MODAL PARA VER EL LIBRO DE VENTAS ************ */}
  <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalLibroVentasPDF } >
    <VerPdfOk
      documentoTitulo={"Libro de ventas"}
      documentoArchivo={"LIBROventas_" + valueDC.usuario.LoginUsu }
      corchetesSN={"N"} //NO quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"N"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ ()=> set_modalLibroVentasPDF(false) } 
    />
  </Modal>   

  {/* *********** MODAL PARA VER LAS FACTURAS ANULADAS ************ */}
  <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalFacturasAnuladasPDF } >
    <VerPdfOk
      documentoTitulo={"Facturas Anuladas"}
      documentoArchivo={"FACTURASanuladas_" + valueDC.usuario.LoginUsu }
      corchetesSN={"N"} //NO quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"N"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ ()=> set_modalFacturasAnuladasPDF(false) } 
    />
  </Modal>     
</div> //divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
  if (nombreComponenteParaVer==='menu') return ComponentePrincipal()
  if (nombreComponenteParaVer==='clientes') return (<TabelaClientes activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='planes')  return (<TabelaPlanesDePago activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='lista de facturas') return (<TabelaFacturasLista activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='morosos') return (
  <TabelaClientesMorosos 
    dia={diaTransito}
    mes={mesTransito}
    ano={anoTransito}
    activarMenu={ activarMenu } 
  />)
    
  } // const MenuPrincipalBonanza

export default MenuPrincipalBonanza